.car-slider-container {
    width: 100%;   
    margin-top: 5rem;
}

.car-slider-container > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.car-slider-container > .header > div > h2 {
    font-weight: 600;
    font-size: 1.3rem;;
    margin-bottom: 0.5rem;
    letter-spacing: 1px;
    line-height: 2.3rem;
}

.slider-car-container {
    width: 14rem !important;
    height: 12rem;
}

.slider-car-container > a > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.slider-car-container > a > h3 {
    font-weight: 400;
    font-size: 1rem;
    margin-top: 1rem;
    color: #454550;
    text-overflow: ellipsis; 
    white-space: nowrap; 
    overflow: hidden;
}

.slider-car-container > a {
    text-decoration: none;
}

.slider-car>.slick-list>.slick-track>.slick-slide{
    margin: 1rem 0.5rem 1rem 0.5rem;
}

.slider-car>.slick-list>.slick-track>.slick-slide:first-child{
    margin: 1rem 0.5rem 1rem 2rem;
}

.slider-car-next {
    top: -2.8rem;
    right: 0.8rem;
    color: #454550;
}

.slider-car-prev {
    top: -2.8rem;
    right: 3.5rem;
    color: #E5E5E5;
    transform: rotate(180deg);
}

.slider-car-arrow {
    width: 2rem !important;
    height: 2rem;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    
    border: 1px solid rgb(229, 229, 229);
    border-radius: 50%;
    cursor: pointer;

    font-size: 0.85rem;
}

.slider-car-arrow > img {
    width: 0.7rem;
}

@media only screen and (min-width: 768px) {
    
}

@media only screen and (min-width: 992px) {
    .slider-car-container {
        width: 16rem !important;
        height: 12rem;
    }
    
    .slider-car>.slick-list>.slick-track>.slick-slide{
        margin: 1rem 0.8rem 1rem 0.8rem;
    }
    
    .slider-car>.slick-list>.slick-track>.slick-slide:first-child{
        margin: 1rem 0.8rem 1rem 2rem;
    }
}

@media only screen and (min-width: 1092px) {
    .slider-car-container {
        width: 19rem !important;
        height: 16rem;
    }
}
.used-car-banner > div > div{
    padding: 0;
}

.used-car-banner > div > div > img{
    width: 100%;
}

.used-car-banner > div > div > .desktop{
    display: none;
}

.used-car-title > h1{
    font-size: 1.3rem;
    line-height: 1.8rem;
    font-weight: 600;
    margin: 2rem 0 2.5rem 0;
}

.used-car-card > div > a{
    text-decoration: none;
}

.used-car-card > div > a > img{
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.used-car-card > div > a > div > h2{
    color: #454550;
    font-size: 0.85rem;
    line-height: 1.8rem;
    font-weight: 400;
    margin: 1rem 0 2rem 0;
    
    height: 1.9em;
    overflow: hidden; 
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

@media only screen and (min-width: 768px) {
    
}

@media only screen and (min-width: 992px) {
    .used-car-banner > div > div > img.desktop{
        display: block;
    }
    .used-car-banner > div > div > img.mobile{
        display: none;
    }
}

@media only screen and (min-width: 1200px) {
}
ol.decimal-list li {
  font-size: 18px;
  font-weight: bold;
}

ol.decimal-char li {
  font-size: 16px;
  font-weight: bold;
}

p {
  font-size: 14px;
  margin-bottom: 0 !important;
  font-weight: normal !important;
}

ul {
  font-size: 10px !important;
}
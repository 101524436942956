.magazine-title h1{
    font-size: 1.3rem;
    line-height: 1.8rem;
    font-weight: 600;
    margin: 7rem 0 2.5rem 0;
}

.magazine-card{
    margin-bottom: 2rem;
}

.magazine-card-img{
    width: 100%;
    height: 20rem;
}

.magazine-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
    
.magazine-card > a > div > div {
    padding: 1rem 0;
}

.magazine-card h2 {
    color: #C1C1C1;
    font-size: 0.85em;
    line-height: 1.8rem;
    height: 3.8em;
    overflow: hidden; 
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-weight: 400;
}

.magazine-card a{
    text-decoration: none;
    margin-bottom: 2.5rem;
}
.events-title > h1{
    font-size: 1.3rem;
    line-height: 1.8rem;
    font-weight: 600;
    margin: 7rem 0 2.5rem 0;
}

.events-card > a > div > img {
    width: 100%;
    object-fit: cover;
    object-position: center;
}
    
.events-card > a > div > div {
    padding: 1.4rem 1.2rem;
    border: 1px solid #E5E5E5;
}

.events-card > a > div > div >  p {
    color: #C1C1C1;
    font-size: 1rem;
    line-height: 1rem;
    font-weight: 400;
    margin: 0;
}

.events-card > a > div > div > h2 {
    color: #454550;
    font-size: 0.85em;
    line-height: 1.8rem;
    height: 3.8em;
    overflow: hidden; 
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-weight: 400;
    padding: 0.8rem 0 2rem 0;
}

.events-card > a{
    text-decoration: none;
    margin-bottom: 2.5rem;
}
.my-profile-container{
    padding-bottom: 5rem !important;
    padding-top: 6rem !important;
}

.my-profile-back {
    margin-top: 7rem !important;
}

.my-profile-back > a {
    text-decoration: none;
    font-size: 0.85rem;
    color: #454550;
}

.my-profile-back > a > p > i {
    padding-right: 0.5rem;
    margin-bottom: 0;
}

.my-profile-back > a > p {
    margin-bottom: 0;
}

.my-profile-title > h1{
    font-size: 1.3rem;
    line-height: 1.8rem;
    font-weight: 600;
    margin: 0.2rem 0 1.5rem 0;
}

.my-profile-title > p{
    margin-bottom: 4rem;
}

.my-profile-card > div > a > img{
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.my-profile-form{
    padding: 0 1rem;
    padding: 0;
    background-color: #FFF;
    z-index: 0;
    position: relative;
}

.my-profile {
    display: flex;
}

.accordion-button::after {
    margin-top: -1rem !important;
}

.my-profile-text {
    display: flex;
}

.my-profile-text > p {
    color: #454550;
    margin: -1rem 0 0 0;
    font-size: 1rem;
    text-decoration: underline;
}

.my-profile-button {
    display: block;
    width: 100%;
    margin: 2rem 0 1rem 0;
}

.my-profile-button > p , .my-profile-another > p{
    font-size: 1rem;
    line-height: 1.8rem;
    font-weight: 600;
}

.accordion-button {
    flex-direction: column;
    align-items: start !important;
}

#accordion-my-profile{
    padding-bottom: 0.4rem !important;
}

.accordion-button > p:nth-child(2) {
    font-weight: 400;
}

.my-profile-divider{
    margin: 2.5rem 0 3.5rem 0rem !important;
    border-bottom: 1px solid #E5E5E5;
}

.circle {
    display: flex;
    width: 5rem;
    height: 5rem;
    background-color: #F7F7F7;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
}

.circle-image {
    position: absolute;
    bottom: 2.1rem;
    margin-left: 3.5rem;
}

.circle-image:hover {
    cursor: pointer;
}

.my-profile-photo{
    padding-bottom: 1.5rem;
}

.member-benefit-my-profile{
    font-size: 0.85rem;
    color: #454550;
}

.member-benefit-my-profile > li::marker{
    color: #000;
}

.member-card-my-profile{
    width: 100%;
    margin: 2rem 0 1.5rem 0;
}

.member-benefit-my-profile > li:last-child{
    padding-bottom: 1rem;
}

.member-benefit-my-profile > li:first-child{
    padding-top: 1rem;
}

.member-type-accordion{
    padding: 0.5rem 0 0 0;
    color: #454550;
}

.my-profile-my-garage{
    text-decoration: none;
}

.button-image-form-profile {
    border: 1px solid transparent !important;
    border-radius: 0 !important;
    background-color: transparent !important;
    width: 100%;
    height: 100%;
    padding: 0.05rem !important;
}

.button-image-form-profile {
    font-size: 1.2rem !important;
    line-height: 1.8rem !important;
    font-weight: 600 !important;
    color: #000 !important;
}

.button-image-form-profile:focus {
    box-shadow: none !important;
}

.button-image-form-profile * {
    border-radius: 50% !important;
}

@media only screen and (min-width: 768px) {
    
}

@media only screen and (min-width: 992px) {
    .my-profile-button {
        display: block;
        width: 100%;
        margin: 4rem 0 4rem 0;
    }

    .my-profile-title > .member-card{
        margin-bottom: 2rem;
    }

    .form-field-title{
        font-size: 0.8rem;
    }

    .my-profile-button {
        margin: 2.5rem 0 3rem 0;
        padding: 0.8rem 2.2rem 0.8rem 2.2rem !important;
    }

    .my-profile-form {
        padding: 0 0.5rem !important;
        margin-top: 1rem !important;
    }

    .my-profile-button:hover {
        border: 1px solid rgba(0, 0, 0, .5);
    }

    .my-profile-title > p{
        margin-bottom: 2rem;
    }

    .my-profile-photo{
        padding-left: 0 !important;
        margin-bottom: 0.5rem !important;
    }

    .my-profile-title > h1{
        margin-bottom: 2rem;
    }

    #accordion-my-profile > button {
        padding: 1.2rem 0 0 1.2rem !important;
    }

    #accordion-my-profile > button > p:nth-child(2) {
        margin-bottom: 0 !important;
    }

    .member-type-card{
        margin-top: 1.5rem !important;
    }
}

@media only screen and (min-width: 1200px) {
}
.forgot-password-container{
    margin-top: 9rem;
}

.forgot-password-back > p > a {
    text-decoration: none;
    font-size: 0.85rem;
    color: #454550;
}

.forgot-password-back > p >  a > i {
    padding-right: 0.5rem;
    margin-bottom: 0;
}

.forgot-password-back > p {
    margin-bottom: 0;
    display: flex;
}

.forgot-password-title > h1{
    font-size: 1.3rem;
    line-height: 1.8rem;
    font-weight: 600;
    margin: 0.3rem 0 0.3rem 0;
}

.forgot-password-title > p{
    margin-bottom: 4rem;
}

.forgot-password-form{
    padding: 0 1rem;
    padding: 0;
    margin-top: 1rem !important;
    background-color: #FFF;
    z-index: 10;
    position: relative;
}

.forgot-password {
    display: flex;
}

.forgot-password-text {
    display: flex;
}

.forgot-password-text > p {
    color: #454550;
    margin: -1rem 0 0 0;
    font-size: 1rem;
    text-decoration: underline;
}

.forgot-password-button {
    display: block;
    width: 100%;
    margin: 6rem 0 2rem 0;
}

.forgot-password-button > p , .forgot-password-another > p{
    font-size: 1rem;
    line-height: 1.8rem;
    font-weight: 600;
}

@media only screen and (min-width: 768px) {
    
}

@media only screen and (min-width: 992px) {
    .forgot-password-form{
        padding: 0;
    }

    .forgot-password-title > p {
        margin-bottom: 2rem;
    }

    .forgot-password-button {
        margin: 1rem 0 2rem 0;
    }
}

@media only screen and (min-width: 1200px) {
}
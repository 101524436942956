.slider-service-center-container{
    width: 100%;
    height: 23rem;
}

.slider-service-center-container > img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}


.service-center-image{
    padding: 2rem 0 0 0 !important;
}

.service-center-image > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.slider-service-center-arrow {
    width: 2.5rem !important;
    height: 2.5rem;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(229, 229, 229);
    border-radius: 50%;
    cursor: pointer;
    font-size: 0.85rem;
}

.slider-service-center-prev {
    top: -0.8rem;
    right: 3.5rem;
    color: #E5E5E5;
    transform: rotate(180deg);
}

.slider-service-center-next {
    top: -0.8rem;
    right: 0rem;
    color: #454550;
}

.slider-service-center-arrow > img {
    width: 0.7rem;
}

.service-center-image .slick-dots {
    top: -0.3rem;
    margin: 0;
    list-style: none;
    text-align: center;
    width: 68% !important;
    display: flex;
    justify-content: flex-end;
}

.slider-dot-ul {
    width: 100%;
}

.service-center-image .slider-dot{
    background-color: #C1C1C1;
    width: 0.5rem;
    height: 0.5rem;
    display: flex;
    justify-content: flex-end;
}

.service-center-image ul{
    padding-left: 0 !important;
    padding-top: 0.6rem;
    display: flex;
    justify-content: flex-start;
    overflow: hidden;
}

.slick-active > .slider-dot{
    background-color: #000000 !important;
}

.slider-dot-ul > li{
    position: relative;
    display: inline-block;
    width: 0.5rem !important;
    height: 0.5rem !important;
    margin: 0 1rem !important;
    padding: 0;
    cursor: pointer;
}

.slider-dot-ul > li:first-child{
    margin: 0 1rem 0 0!important;
}

@media only screen and (min-width: 992px) {
    .service-center-image ul{
        justify-content: flex-end;
    }

    .slider-service-center-prev {
        top: 0.2rem;
        right: 4.5rem;
    }
    
    .slider-service-center-next {
        top: 0.2rem;
        right: 0.8rem;
    }

    .service-center-image .slick-dots {
        top: 1.6rem;
    }
    
    .slider-service-center-container{
        width: 100%;
        height: 23rem;
    }

    .slider-service-center-container > img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}


@media only screen and (min-width: 1092px) {
    .slider-service-center-prev {
        top: 0.2rem;
        right: 4.5rem;
    }
    
    .slider-service-center-next {
        top: 0.2rem;
        right: 0.8rem;
    }
}

@media only screen and (min-width: 1214px) {
    .slider-service-center-prev {
        top: 0.2rem;
        right: 4.5rem;
    }
    
    .slider-service-center-next {
        top: 0.2rem;
        right: 0.8rem;
    }

    .service-center-image .slick-dots {
        top: 0.6rem;
    }
}
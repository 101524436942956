  .btn {
    padding: 8px 20px;
    border-radius: 4px;
    outline: none;
    border: none;
    font-size: 18px;
    color: #fff;
    cursor: pointer;
    background-color: var(--primary);
  }
  
  .btn:hover {
    padding: 6px 18px;
    transition: all 0.3s ease-out;
    background-color: transparent;
    color: #fff;
    border-radius: 4px;
    border: 2px solid var(--primary);
    transition: all 0.3s ease-out;
  }

  .car-card-skeleton{
    margin-bottom: 4rem !important;
  }

  .car-card-skeleton-img{
    height: 16.5vw;
  }

  .car-card-skeleton-title{
    margin-top: 1rem !important;
  }
  
  .car-card-img{
    padding: 0;
    margin: 0;
    width: 100%;
    height: 14rem;
  }

  .car-card-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  @media only screen and (min-width: 600px) {
    .car-card-img{
      padding: 0;
      margin: 0;
      width: 100%;
      height: 30rem;
    }
  }

  @media only screen and (min-width: 992px) {
    .car-card-img{
      padding: 0;
      margin: 0;
      width: 100%;
      height: 22.5vw;
    }
  }
.news-back {
    margin-top: 7rem !important;
}

.news-back a, .news-back a:hover {
    text-decoration: none;
    font-size: 0.85rem;
    color: #454550;
}

.news-back p {
    margin-bottom: 0;
}

.news-back *, .news-back{
    display: flex;
    align-items: center;
}

.news-detail-title p {
    font-size: 1.3rem;
    line-height: 2.2rem;
    font-weight: 600;
    margin: 0.3rem 0 1.5rem 0;
}

.events-detail-share{
    display: block;
}

.news-detail-media-social{
    display: none;
    padding-left: 0.2rem;
}

.news-detail-media-social p{
    color: #454550;
    font-size: 0.85rem;
    font-weight: 400;
    padding: 0.3rem 0.1rem 0 0;
}

.news-detail-media-social img{
    width: 1.5rem;
    height: 1.5rem;
    margin: 0.1rem 0 0 0.5rem;
}

.news-detail-image {
    width: 100%;
    height: 18rem;
}

.news-detail-image img {
    width: 100%;
    height: 100%;
    margin-bottom: 1rem;

    object-fit: cover;
    object-position: center;
}

.news-detail-image .carousel-indicators, .news-detail-image .carousel-control-next, 
.news-detail-image .carousel-control-prev{
    display: none;
}

.news-detail-specification {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 1.5rem !important;
}

.news-detail-specification, .news-detail-share {
    margin-top: 0rem !important;
}

.news-detail-specification > p {
    display: none;
}

.news-detail-specification > p, .news-detail-description {
    margin: 0;
    font-size: 0.85rem;
    color: #454550;
}

.news-detail-description *,.news-detail-description{
    text-align: justify;
}

.news-detail-specification > p:nth-child(2) {
    margin-top: -0.2rem;
}

.news-detail-share {
    text-align: right;
}

.news-detail-share img{
    width: 2.5rem;
    padding: 0.6rem;
    background-color: #000;
}

.news-detail-description{
    padding: 2rem 0 3.5rem 0;
}

.news-detail-card-container{
    background-color:#F7F7F7;
    padding: 4.5rem 0 2.5rem 0;
}

.car-detail-news-collection{
    background-color: #F7F7F7;
    padding-top: 4rem !important;
}

@media only screen and (min-width: 768px) {
    
}

@media only screen and (min-width: 992px) {
    .events-detail-description{
        padding: 0 1rem 1.5rem 6.5rem !important;
    }

    .events-detail-share{
        display: none;
    }

    .news-detail-media-social{
        padding-top: 0.2rem;
        display: flex;
        justify-content: flex-end;
    }

    .news-detail-share {
        display: none;
    }

    .news-detail-specification {
        justify-content: start;
        padding: 2rem 0 0 0;
    }

    .news-detail-image {
        height: 23rem;
    }
}

@media only screen and (min-width: 1200px) {
}
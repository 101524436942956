.wheels-container{
    padding-top: 6rem !important;
    width: 100%;
    height: 100%;
}

.wheels-description {
    margin-top: 4rem !important;
    text-align: center;
    line-height: 1.8rem;
}

.wheels-images {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}

.wheels-images img {
    display: flex;
    width: 9rem;
    padding: 0 0.5rem;
}
.register-container {
    margin-top: 9rem !important;
}

.register-back a, .register-back a:hover {
    text-decoration: none;
    font-size: 0.85rem;
    color: #454550;
}

.register-back p {
    margin-bottom: 0;
}

.register-back *, .register-back {
    display: flex;
}

.register-form *{
    z-index: -2;
}

.register-form img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: 100%;
}

.register-title > h1{
    font-size: 1.3rem;
    line-height: 1.8rem;
    font-weight: 600;
    margin: 0.3rem 0 0.3rem 0;
}

.register-title > p{
    margin-bottom: 4rem;
}

.register-card > div > a > img{
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.register-form{
    padding: 0 1rem;
    padding: 0;
    margin-top: 7rem !important;
    position: relative;
}

.register {
    display: flex;
}

.register-text {
    display: flex;
}

.register-text > p {
    color: #454550;
    margin: -1rem 0 0 0;
    font-size: 1rem;
    text-decoration: underline;
}

.register-button {
    display: block;
    width: 100%;
    margin: 3rem 0 2rem 0;
}

.register-button p , .register-another p{
    font-size: 1rem;
    line-height: 1.8rem;
    font-weight: 600;
}

@media only screen and (min-width: 768px) {
    
}

@media only screen and (min-width: 992px) {
    .register-button {
        margin: 2.5rem 0 3rem 0;
    }

    .register-form {
        padding: 0 0.5rem !important;
        margin-top: 1rem !important;
    }

    .register-butto:hover {
        border: 1px solid rgba(0, 0, 0, .5);
    }

    .register-title > p{
        margin-bottom: 2rem;
    }
}

@media only screen and (min-width: 1200px) {
}
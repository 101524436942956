.form-text-area-title, .form-text-area{
    border-radius: 0px !important;
    font-size: 0.65rem;
    font-weight: 600;
    font-size: 0.8rem;
}

.form-text-area{
    padding: 1rem 0 1rem 1rem !important;
    margin: 0.2rem 0 2rem 0;
    color: #454550 !important;
}

.form-text-area:focus{
    outline: none !important;
    box-shadow: none !important;
    border: 1px solid #454550 !important;
}

.form-text-area::placeholder{
    color: #C1C1C1 !important;
}

@media only screen and (min-width: 768px) {
    
}

@media only screen and (min-width: 992px) {
    .form-text-area{
        font-size: 0.85rem !important;
    }
    
}

@media only screen and (min-width: 1200px) {
}
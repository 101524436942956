.membership-container{
    padding-bottom: 2rem !important;
}

.membership>h1{
    font-size: 1.3rem;
    line-height: 1.8rem;
    font-weight: 600;
    margin: 9.5rem 0 2.5rem 0;
}

.membership-level > h2{
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 1.2rem;
}

.member-card{
    width: 100%;
}

.accordion{
    border-width: 0 !important;

    --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem transparent !important;
}

.accordion-body{
    padding: 0 1rem 0 0.5rem !important;
}

.accordion-button:not(.collapsed){
    background-color: transparent !important;
    color: #000 !important;
}

.accordion-button:not(.collapsed)::after{
    background-image: var(--bs-accordion-btn-icon) !important;
}

.accordion-button:focus{
    border: 0;
}

.accordion-button:after{
    margin-right: 1rem;
}

/* .accordion-button,  */
.accordion-button.collapsed{
    /* display: block !important;
    cursor: context-menu !important; */
}

.accordion-button > p {
    font-size: 0.85rem;
    font-weight: 600;
    text-align: left;
    padding-left: 1.2rem;
    margin: 0;
}

.member-benefit{
    margin: 0;
    padding: 0 0 0.1rem 1.8rem;
}

.member-benefit>li {
    font-size: 0.85rem;
    font-weight: 400;
    margin-bottom: 1.2rem;

    content:"·";
    vertical-align:middle;
}

.member-benefit>li::marker{
    font-size: 1.2rem;
}

.member-benefit>li>p{
    margin-bottom: -0.3rem;
}

.accordion-item, .accordion-button{
    background-color: #E5E5E5 !important;
    border: 0px !important;
}

.accordion-button{
    padding: 1.3rem 0 1rem 0 !important;
}

.accordion-item{
    /* border: 1px solid #E5E5E5 !important; */
    margin: 2rem 0;
}

.membership-faq > h1, .membership-faq-title > button > p, .membership-faq-description{
    font-size: 0.9rem;
}

.membership-faq > h1, .membership-faq-title > button > p{
    font-size: 0.9rem;
    line-height: 1.8rem;
    font-weight: 600;
}

.membership-faq-title > button{
    background-color: transparent !important;
}

.membership-faq > h1{
    margin: 2rem 0 2rem 0;
    text-align: center;
}

.membership-faq-title-container{
    margin: 1rem 0;
    /* border-bottom: 1px solid #E5E5E5 !important; */
    background-color: #F7F7F7 !important;
}

.membership-faq-title-flush{
    border: 0px !important;
}

.membership-faq-title > button > p, .membership-faq-description{
    color:#454550;
}

.membership-faq-title > button > p{
    padding-left: 0.5rem;
}

.membership-faq-container {
    background-color: #F7F7F7 !important;
    padding: 0 1rem 7rem 1rem !important;
}

@media only screen and (min-width: 768px) {
    
}

@media only screen and (min-width: 992px) {
    .accordion-button:after{
        margin-right: 1rem;
    }
    
    .accordion-item{
        margin-top: 0rem;
    }

    .accordion-button > p {
        text-align: center;
        padding-left: 0;
    }

    .member-type > button::after {
        display: none;
    }

    .member-type > button {
        justify-content: center;
        cursor: context-menu !important;
    }

    .member-type-card > .collapse:not(.show) {
        display: block !important;
    }
    
    .member-card-row{
        margin-bottom: 4rem;
    }

    .member-card-accordion, .accordion-item, #member-type-card{
        margin: 0 !important;
    }

    .member-card-accordion > *, .member-card-accordion {
        height: 100%;
    }

    .member-type-title{
        width: 100%;
    }
}

@media only screen and (min-width: 1200px) {
}
.tda-navbar-desktop{
    position: fixed !important;
    top: 0 !important;
    width: 100vw;
    z-index: 10;
    background-color: rgba(54, 54, 54, 0.615);
    transition: 0.5s;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

.bullet-notification{
    width: 0.3rem;
    height: 0.3rem;
    background-color: red;
    right: 0;
    top: 0.35rem;
}

.my-notification-clear-all a{
    cursor: pointer;
}

.search-icon, .icon-notification{
    cursor: pointer;
}

.tda-navbar-desktop-login-complete{
    display: flex;
    align-items: center;
}

.tda-navbar-desktop-login-complete img {
    margin: 0.1rem 0 0 1.2rem;
    width: 0.9rem;
    height: 1rem;
}

.tda-navbar-desktop-login-complete img.icon-white {
    filter: brightness(0) invert(1);
}

.tda-navbar-desktop-login-complete img.icon-black {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%) brightness(0%);
}

.tda-navbar-desktop-login-complete-photo{
    border-radius: 50%;
    background-color: #f7f7f7;
    padding: 0.4rem 0.6rem;
    margin: 0 1rem;
    font-size: 0.55rem;
}

.tda-navbar-desktop-login-complete .dropdown-menu{
    top: 2.5rem !important;
    left: unset !important;
    right: -1rem !important;
    border-radius: 0 !important;
}

.tda-navbar-desktop-container{
    padding: 0 5rem 0 3rem !important;
}

.tda-navbar-desktop-flex{
    width: 100%;
    justify-content: center !important;
    align-items: center !important;
    padding: 0 8% 0 10% !important;
}

.tda-navbar-desktop-item-collection{
    justify-content: space-between !important;
    align-items: center !important;
}

.tda-navbar-desktop-item, .tda-navbar-desktop-item > a, .tda-navbar-desktop-login-register > a > p, .tda-navbar-desktop-flex > a{
    font-size: .9rem !important;
    color: #fff;
    margin: 0;
}

.tda-navbar-desktop-logo{
    width: 8rem;
}

.tda-navbar-desktop-login-register{
    display: flex;
    align-items: center;
}

.tda-navbar-desktop-login-register > img{
    margin-right: 1rem;
    padding-top: 0.1rem;
    width: 0.79rem;
}

#vertical-line-search{
    border-right: 1px solid #C1C1C1;
    height: 0.9rem;
}

#modal-search{
    width: 100vw !important;
}

#modal-search .modal-content{
    background-color: transparent;
}

#modal-search .modal-header{
    background-color: #fff;
    padding: 1.1rem 5rem 1.1rem 2rem;
    width: 100%;
}

#modal-search .modal-title, #modal-notification{
    display: flex;
    align-items: center;
}

.tda-search-close{
    width: 1rem;
    height: 1rem;
    margin-right: 1.5rem;
    cursor: pointer;
}

.tda-search, #modal-search .modal-title, #modal-search .modal-title{
    width: 100%;
}

#modal-search .modal-body{
    width: 100%;
    height: 100%;
    padding: 0 !important;
}

.modal-body{
    width: 100%;
    height: 100%;
}

.search-body-result{
    width: 100%;
    height: 100%;
    padding: 4rem 0 !important;
    background-color: #fff;
}

#search-not-yet-body{
    width: 100%;
    height: 100%;
}

#modal-notification .modal-body{
    padding-bottom: 6rem !important;
}

.tda-search > label {
    display: none;
}

input[name="query"]{
    margin: 0 !important;
    width: 100%;
}

.tda-navbar-desktop-login-register > a > p{
    padding-left: 1rem;
}

.tda-navbar-desktop-login-register > a{
    text-decoration: none;
}

.icon-black{
    -webkit-filter: invert(100%);
    filter: invert(100%);
}

.text-black a, .text-black p, .text-black{
    color: #000 !important;
}

.hover-underline-animation {
    position: relative;
    color: #fff;
}

.hover-underline-animation:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #fff;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.hover-underline-animation-black:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #000;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.hover-underline-animation-black:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.hover-underline-animation-black {
    position: relative;
    color: #000;
}

.hover-underline-animation-black:hover{
    color: #000 !important;
}

.hover-underline-animation:hover{
    color: #fff !important;
}

.hover-underline-animation-black:hover{
    color: #000 !important;
}

.hover-underline-animation:hover{
    color: #fff !important;
}

.navbar-nav .show .hover-underline-animation-black >  a, .hover-underline-animation-black:hover,
.hover-underline-animation-black:focus, .hover-underline-animation-black > a:hover,
.hover-underline-animation-black > a:focus, .hover-underline-animation-black > a{
    color: #000 !important;
}

.navbar-nav .show .hover-underline-animation >  a, .hover-underline-animation:hover,
.hover-underline-animation:focus, .hover-underline-animation > a:hover,
.hover-underline-animation > a:focus, .hover-underline-animation > a{
    color: #fff !important;
}

.tda-navbar-cars-dropdown > .dropdown-menu{
    border-radius: 0;
    left: -7.5rem !important;
    top: 1.9rem !important;
    height: 14.9rem;
}

.dropdown-item:hover button {
    background-color: transparent !important;
}

.dropdown-menu button{
    text-decoration: none;
}

.dropdown-item, .dropdown-menu > .dropend > button{
    padding: .5rem 1rem !important;
    font-size: 0.65rem;
}

.dropdown-menu{
    padding: 1rem 1rem 2rem 1rem !important;
}

.dropend > *, .dropend{
    width: 100%;
}

.dropdown-menu > .dropend > button {
    text-align: left;
    padding: .5rem 0 .5rem 1rem !important;
}

.dropdown-menu > .dropend > button:focus {
    box-shadow: none;
}

.dropdown-menu > .dropend > button {
    background-color: transparent;
    border: 0 !important;
    color: #000 !important;
    padding: .5rem 0 .5rem 0 !important;
    margin-left: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.dropdown-menu > .dropend > button::after {
    color: transparent !important;
    background: url("data:image/svg+xml;utf8,<svg style='transform: rotate(-90deg)' viewBox='0 0 140 140' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='white'/></g></svg>") no-repeat;
    width: 0.9rem;
    background-repeat: no-repeat;
    height: 100%;
    filter: brightness(0);
}

.dropdown-menu > .dropend > button {
    display: flex;
    justify-content: space-between;
}

.dropend > div {
    margin-left: 0.95rem !important;
    margin-top: -1.05rem !important;
    border-radius: 0 !important;
}

.dropend .dropdown-toggle::after {
    margin-left: 0.8rem !important;
    display: none;
}

.dropdown-toggle.nav-link::after{
    background: url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='white'/></g></svg>") no-repeat !important;
}

.dropdown-brand-collection{
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 50%;
    padding: 0 0 0 2rem;
    margin-bottom: 1rem;
}

.dropdown-brand-item a, .dropdown-brand-item a:hover{
    text-decoration: none;
    color: #000;
}

.dropdown-brand-item{
    height: 4rem;
    width: 5rem !important;
    min-height: 4rem !important;
    max-height: 4rem !important;
    padding: 0 !important;
    margin: 0 0.5rem;
}


.overflow-y-scroll .dropdown-menu{
    overflow-y: scroll;
}

.dropdown-brand-item img, .dropdown-merchandise-brand-item img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

.dropdown-brand-item p, .dropdown-merchandise-brand-item p {
    font-size: 0.85rem;
    margin-bottom: 0;
    text-align: center;
}

div[aria-labelledby="dropdown-button-drop-end tda-navbar-dropdown-end dropdown-cars-brand0"], div[aria-labelledby="dropdown-button-drop-end tda-navbar-dropdown-end dropdown-cars-brand1"],
div[aria-labelledby="dropdown-button-drop-end tda-navbar-dropdown-end dropdown-cars-brand2"], div[aria-labelledby="dropdown-button-drop-end tda-navbar-dropdown-end dropdown-cars-brand3"],
div[aria-labelledby="dropdown-button-drop-end tda-navbar-dropdown-end dropdown-cars-brand4"]  {
    width: 18rem;
    height: 14.85rem !important;
    margin-top: -1.05rem !important;
}

div[aria-labelledby="dropdown-button-drop-end tda-navbar-dropdown-end dropdown-cars-brand5"], div[aria-labelledby="dropdown-button-drop-end tda-navbar-dropdown-end dropdown-cars-brand6"] {
    width: 24rem;
    height: 14.85rem !important;
    margin-top: -1.05rem !important;
}
div[aria-labelledby="dropdown-button-drop-end tda-navbar-dropdown-end dropdown-cars-brand7"], div[aria-labelledby="dropdown-button-drop-end tda-navbar-dropdown-end dropdown-cars-brand8"] {
    width: 29rem;
    height: 14.85rem !important;
    margin-top: -1.05rem !important;
}
div[aria-labelledby="dropdown-button-drop-end tda-navbar-dropdown-end dropdown-cars-brand9"], div[aria-labelledby="dropdown-button-drop-end tda-navbar-dropdown-end dropdown-cars-brand10"] {
    width: 35rem;
    height: 14.85rem !important;
    margin-top: -1.05rem !important;
}
div[aria-labelledby="dropdown-button-drop-end tda-navbar-dropdown-end dropdown-cars-brand11"], div[aria-labelledby="dropdown-button-drop-end tda-navbar-dropdown-end dropdown-cars-brand12"] {
    width: 41rem;
    height: 14.85rem !important;
    margin-top: -1.05rem !important;
}
div[aria-labelledby="dropdown-button-drop-end tda-navbar-dropdown-end dropdown-cars-brand13"], div[aria-labelledby="dropdown-button-drop-end tda-navbar-dropdown-end dropdown-cars-brand14"] {
    width: 47rem;
    height: 14.85rem !important;
    margin-top: -1.05rem !important;
}
div[aria-labelledby="dropdown-button-drop-end tda-navbar-dropdown-end dropdown-cars-brand15"], div[aria-labelledby="dropdown-button-drop-end tda-navbar-dropdown-end dropdown-cars-brand16"] {
    width: 53rem;
    height: 14.85rem !important;
    margin-top: -1.05rem !important;
}

.dropdown-merchandise-brand-collection{
    margin-top: -5.75rem !important;
    padding-top: 5.75rem !important;
}

.dropdown-menu > .dropend > #dropdown-windows-film-brand::after{
    margin-left: 8rem !important;
}

#tda-navbar-scroll {
    transition: 0.5s;
    background-color: rgba(255,255,255, 1);
    z-index: 11;
    padding-bottom: 0;
}

.tda-navbar-desktop-item > div[aria-labelledby="dropdown-merchandise-brand"] {
    width: 19.5rem !important;
    height: 18.15rem;
    margin-left: -3rem;
    border-radius: 0;
}

div[aria-labelledby="dropdown-button-drop-end tda-navbar-dropdown-end dropdown-products-0"]{
    width: 25rem;
    height: 18.15rem;
}
div[aria-labelledby="dropdown-button-drop-end tda-navbar-dropdown-end dropdown-products-1"]{
    width: 25rem;
    height: 18.15rem;
    top: -1.95rem !important;
}
div[aria-labelledby="dropdown-button-drop-end tda-navbar-dropdown-end dropdown-products-2"]{
    width: 25rem;
    height: 18.15rem;
    top: -3.95rem !important;
}
div[aria-labelledby="dropdown-button-drop-end tda-navbar-dropdown-end dropdown-products-3"]{
    width: 25rem;
    height: 18.15rem;
    top: -5.9rem !important;
}
div[aria-labelledby="dropdown-button-drop-end tda-navbar-dropdown-end dropdown-products-4"]{
    width: 25rem;
    height: 18.15rem;
    top: -7.9rem !important;
}
div[aria-labelledby="dropdown-button-drop-end tda-navbar-dropdown-end dropdown-products-5"]{
    width: 25rem;
    height: 18.15rem;
    top: -9.85rem !important;
}
div[aria-labelledby="dropdown-button-drop-end tda-navbar-dropdown-end dropdown-products-6"]{
    width: 25rem;
    height: 18.15rem;
    top: -11.85rem !important;
}
div[aria-labelledby="dropdown-button-drop-end tda-navbar-dropdown-end dropdown-products-7"]{
    width: 25rem;
    height: 18.15rem;
    top: -13.8rem !important;
}
div[aria-labelledby="dropdown-button-drop-end tda-navbar-dropdown-end dropdown-products-8"]{
    width: 25rem;
    height: 18.15rem;
    top: -15.8rem !important;
}

@media only screen and (min-width: 992px) {
    .tda-navbar-desktop-item .dropdown-menu{
        border-radius: 0 !important;
    }

    .tda-navbar-desktop-item, .tda-navbar-desktop-item > a, .tda-navbar-desktop-login-register > a > p, .tda-navbar-desktop-flex > a{
        font-size: 0.55rem !important;
        color: #fff;
        margin: 0;
    }

    .tda-navbar-desktop-logo {
        padding-top: 0.5rem;
        width: 4.5rem;
    }

    .tda-navbar-desktop-flex{
        padding: 0 8% 0 10% !important
    }

    .tda-navbar-desktop{
        margin-top: -0.8rem !important;
    }
}

@media only screen and (min-width: 1092px) {

    .tda-navbar-desktop-item, .tda-navbar-desktop-item > a, .tda-navbar-desktop-login-register > a > p, .tda-navbar-desktop-flex > a{
        font-size: .96vw !important;
        color: #fff;
        margin: 0;
    }

    .tda-navbar-desktop-flex>a, .tda-navbar-desktop-item{
        margin: 0 0.4rem;
    }

    .tda-navbar-desktop-logo {
        padding-top: 0.5rem;
        width: 5rem;
    }
}


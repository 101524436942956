  .button-rectangle {
    outline: none;
    border: none;
    color: #000;
    cursor: pointer;
    border: 1px solid rgb(0, 0, 0, 0.6);
    background-color: transparent;

    letter-spacing: 2px;
    
    font-weight: 600;
    padding: 0.8rem 1rem 0.8rem 1rem;
  }
  
  .button-rectangle:hover {
    transition: all 0.3s ease-out;
    background-color: transparent;
    color: #000;
    border: 1.5px solid rgb(0, 0, 0, 0.8);
    letter-spacing: 2px;
    transition: all 0.3s ease-out;
  }
  
  .button-rectangle div{
    display: flex;
  }

  .button-rectangle img{
    width: 7.5%;
    align-self: center;
    margin: 0 0.8rem;
  }
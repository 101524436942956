.car-detail-desktop{
    display: none;
}
.car-detail-back {
    margin-top: 7rem !important;
    display: flex;
}

.carousel-control-next-icon, .carousel-control-prev-icon{
    color: #fff;
}

.car-detail-back > p {
    margin-bottom: 0.2rem;
}

.car-detail-back > p > a {
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: 0.85rem;
    color: #454550;
    margin: 0.2rem 0 0 0;
}

.car-detail-back > p > a > img {
    width: 0.35rem;
    margin-right: 0.6rem;
}

.car-detail-title {
    font-size: 1.3rem;;
    font-weight: 600;
    margin: 0.5rem 0 0rem 0;
}

.tda-see-all-images{
    cursor: pointer;
}

#car-detail-carousel{
    width: 32rem;
}

.car-detail-image-container{
    padding: 0 !important;
}

.car-detail-image-row{
    margin-top: 0.25rem !important;
}

.car-detail-image img{
    width: 100%;
    height: 19rem;
    object-fit: cover;
    object-position: center;
}

.car-detail-image img:hover{
    cursor: pointer;
}

.car-detail-price-row{
    margin: 2rem 0 1.1rem 0 !important;
}

.car-detail-price > p, .car-detail-description *, .car-detail-description > div, .car-detail-description {
    color: #454550;
    margin: 0;
    font-size: 0.85rem;
}

.car-detail-price > p:nth-child(2) {
    font-size: 1.1rem;
    font-weight: 600;
}

.car-detail-form{
    background-color: #E5E5E5;
    padding: 0 0.8rem;
}

.car-detail-title > h1, .car-detail-title > h2{
    font-size: 1.3rem;;
    line-height: 1.8rem;
    font-weight: 600;
    margin: 2rem 0 2.5rem 0;

    text-align: center;
}

.car-detail-specification-row{
    margin-top: 4rem !important;
    border: 1px solid #E5E5E5;
    padding: 0 1.2rem;
    margin-bottom: 5rem;
}

.car-detail-specification{
    display: flex;
    justify-content: space-between;
    padding: 1rem 0 1rem 0 !important;
    
    border-bottom: 1px solid #E5E5E5;
}

.car-detail-title > div{
    margin: 0;
    font-size: 0.9rem;
    color: #454550;
}

.car-detail-descriptions-row{
    margin-top: 4rem !important;
    border: 1px solid #E5E5E5;
    padding: 0 1.2rem 2rem 1.2rem;
    margin-bottom: 5rem;
}

.car-detail-descriptions{
    padding: 0 !important;
}

.car-detail-media-second{
    width: 100%;
}

.car-detail-media-social{
    display: none;
    padding-left: 0.2rem;
}

.car-detail-media-social > p{
    color: #454550;
    font-size: 0.85rem;
    font-weight: 400;
    padding: 0.3rem 0.1rem 0 0;
}

.car-detail-media-social > img{
    width: 1.5rem;
    height: 1.5rem;
    margin: 0.1rem 0 0 0.5rem;
}

.car-detail-image img {
    width: 100%;
    height: 18rem;
    margin-bottom: 1rem;

    object-fit: cover;
    object-position: center;
}


.car-detail-specification > p:nth-child(2) {
    margin-top: -0.2rem;
}

.car-detail-share {
    text-align: right;
    padding: 0 !important;
}

.car-detail-share > img{
    width: 2.5rem;
    padding: 0.6rem;
    background-color: #000;
}

.car-detail-description{
    padding: 2rem 0 3.5rem 0;
}

.car-detail-card-container{
    background-color:#F7F7F7;
    padding: 4.5rem 0 2.5rem 0;
}

.car-detail-accordion-col img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.car-detail-image-collection img,
.leasing-image-container img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.car-detail-image-container{
    display: none !important;
}

.slider-detail-car img, .slider-detail-car{
    height: 80vh;
}

.slider-car-detail-navigation img{
    height: 4rem;
}

#modal-see-all-image .slick-dots{
    bottom: -10vh;
}

#modal-see-all-image .modal-content{
    height: 120vw;
}

#modal-see-all-image{
    background-color: transparent;
    display: flex;
    align-items: center;
}

.slider-detail-car img, .slider-detail-car{
    width: 100%;
    height: auto;
}

.slick-dots li{
    width: fit-content !important;
    height: fit-content !important;
    opacity: 0.5;
}

.slick-dots ul{
    height: 80vh;
    overflow: hidden;
    justify-content: center;
}

.slick-dots li.slick-active{
    opacity: 1;
}

.slider-car-detail-page-arrow {
    width: 2.5rem !important;
    height: 2.5rem;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(229, 229, 229);
    border-radius: 50%;
    cursor: pointer;
    font-size: 0.85rem;
}

.slider-car-detail-page-prev {
    top: 85vw;
    left: 0;
    color: #E5E5E5;
    transform: rotate(180deg);
}

.slider-car-detail-page-next {
    top: 85vw;
    right: 0;
    color: #454550;
}

.slider-car-detail-page-arrow > img {
    width: 0.7rem;
}

.container-slider-arrow-right, .container-slider-arrow-left{
    height: 100%;
}

.container-slider-arrow-left{
    left: 10%;
    z-index: 99999;
}

.container-slider-arrow-right{
    right: 10%;
    z-index: 99999;
}

.car-detail-share-select img{
    width: 9vw;
    margin: 0 0.5rem;
}

#modal-share .modal-content{
    height: 20vh;
}

#modal-share{
    display: flex;
    align-items: center;
}

@media only screen and (min-width: 768px) {
    .slider-car-detail-page-prev {
        top: 20vh;
    }
    
    .slider-car-detail-page-next {
        top: 20vh;
    }

    #modal-see-all-image .modal-content{
        height: 85vw;
    }
}

@media only screen and (min-width: 992px) {
    .slick-dots ul{
        height: 80vh;
    }

    .slider-car-detail-page-prev {
        top: 35vh;
        left: 0;
        color: #E5E5E5;
        transform: rotate(180deg);
    }
    
    .slider-car-detail-page-next {
        top: 35vh;
        right: 0;
        color: #454550;
    }

    #modal-see-all-image .modal-content{
        height: 100vh;
    }

    .slider-detail-car img, .slider-detail-car{
        height: 80vh;
        width: auto;
    }

    #modal-see-all-image{
        height: 100%;
    }

    .car-detail-image-container{
        display: flex !important;
    }

    .leasing-image-container{
        width: 100%;
        height: 15rem;
        margin-bottom: 1rem;
    }

    .car-detail-mobile{
        display: none;
    }

    .car-detail-desktop{
        display: block;
    }

    .car-detail-media-social{
        padding-top: 0.2rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .car-detail-media-social-icon-collection{
        height: 100%;
        padding-top: 0.2rem;
        display: flex;
    }

    .car-detail-media-social > p{
        color: #454550;
        font-size: 0.75rem;
        font-weight: 400;
        padding: 0rem 0rem 0 0;
    }

    .car-detail-media-social img {
        width: 1.5rem;
        height: 1.5rem;
        margin: 0 0 0 0.5rem;
    }

    .car-detail-button-whatsapp{
        display: flex;
        border: 1px solid;
        margin: 0 1.5rem 1rem 0.5rem;
        justify-content: center;
        align-items: center;
    }

    .car-detail-button-whatsapp a{
        padding: 0.3rem 1rem;
        display: flex;
    }

    .car-detail-button-whatsapp a, .car-detail-button-whatsapp a:hover{
        text-decoration: none;
        color: #000;
        width: 100%;
    }

    .car-detail-button-whatsapp p{
        font-size: 0.75rem;
        margin-bottom: 0 !important;
        margin-left: 0.5rem;
        margin-top: 0.2rem;
    }

    .car-detail-button-whatsapp img{
        width: 1.5rem;
        height: 1.5rem;
    }

    .car-detail-share {
        display: none;
    }

    .car-detail-specification {
        padding: 2rem 0 0 0;
    }

    .car-detail-field > label {
        font-size: 0.85rem;
        color: #454550;
        font-weight: 600;
    }

    .car-detail-accordion-col{
        padding: 0 !important;
        margin: 1.5rem 0;
    }

    .car-detail-accordion-col .accordion-button{
        padding: 1rem 0 0.7rem 0 !important;
    }

    .car-detail-accordion-col *{
        background-color: transparent !important;
    }

    .car-detail-accordion-col{
        border: 1px solid;
    }

    .car-detail-accordion-card > h2 > .accordion-button.collapsed {
        box-shadow: none !important;
    }

    .car-detail-accordion-card > h2 > .accordion-button > p {
        text-align: left !important;
        padding-left: 2rem;
        font-size: 0.85rem;
        font-weight: 600;
    }

    .car-detail-accordion-card-p{
        font-size: 0.85rem;
        font-weight: 400;
        color: #454550;
        text-align: center;
    }

    .car-detail-accordion-card-table *{
        font-size: 0.85rem;
        font-weight: 400;
        color: #454550;
    }

    .car-detail-accordion-body {
        padding: 0 1.5rem !important;
    }

    .car-detail-accordion-card-table-background-grey{
        background-color: #F7F7F7 !important;
        margin: 0.2rem 0;
        padding: 0.5rem 0;
    }

    .car-detail-accordion-card-table td {
        padding: 0 !important;
    }

    .car-detail-accordion-card-table-td-padding{
        padding: 0.5rem !important;
    }

    .car-detail-desktop-right{
        padding-left: 2rem !important;
    }
    
    .car-detail-image img {
        width: 100%;
        height: 37vw;
        margin-bottom: 0.5rem;

        object-fit: cover;
        object-position: center;
    }

    .car-detail-back{
        padding: 0 !important;
    }

    .car-detail-title {
        font-size: 1.5rem;
        color: #454550;
    }

    .car-detail-title{
        padding: 0 !important;
    }

    .car-detail-image-collection{
        height: 8.6vw;
    }

    .car-detail-row > div{
        padding: 0 .4rem !important;
    }
    
    .car-detail-youtube{
        padding: 0 !important;
    }

    .car-detail-form-title > h2{
        font-size: 1.1rem;
        line-height: 1.8rem;
        font-weight: 600;
        margin: 2rem 0 2rem 0;
        text-align: center;
    }

    .car-detail-price{
        padding: 0 !important;
        margin-bottom: 1.5rem !important;
    }

    .car-detail-price > p{
        margin: 0 !important;
    }

    #iframe-detail-car{
        width: 100%;
        margin-top: 0.8rem;
        height: 15rem;
    }

    .car-detail-specification-row{
        margin: 2rem -5px 0 -5px !important;
    }

    .car-detail-see-all {
        padding: 0 !important;
        font-weight: 600;
        color: #000;
        position: absolute;
        width: 91%;
        height: 100%;
        top: 0;
        margin: 0 !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .car-detail-see-all:hover{
        cursor: pointer;
    }

    .car-detail-see-all p{
        margin-bottom: 0 !important;
        background-color: #fff;
        padding: 0.2rem 0.3rem;
        font-weight: 600;
        font-size: 0.5rem;
    }

    .car-detail-image-collection-last{
        position: relative;
    }
}
@media only screen and (max-height: 444px) {
    #modal-see-all-image{
        width: 100vw !important;
    }

    #modal-see-all-image .modal-content{
        height: 100vh !important;
    }

    .slider-detail-car img{
        width: 38vw !important;
    }

    .slider-car-detail-navigation img{
        height: 15vh !important;
    }

    .slider-car-detail-page-prev {
        top: 10vw;
    }
    
    .slider-car-detail-page-next {
        top: 10vw;
    }
}
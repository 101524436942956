.login-title > h1{
    font-size: 1.3rem;
    line-height: 1.8rem;
    font-weight: 600;
    margin: 2rem 0 2rem 0;
}

.login-card > div > a > img{
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.login-form{
    padding: 0 1rem;
    padding: 0;
    margin-top: 4rem !important;
    background-color: #FFF;
    z-index: 0;
    position: relative;
}

.forgot-password {
    display: flex;
}

.login-text {
    display: flex;
    margin-top: 2rem !important;
}

.login-text > a > p, .login-text > p > a {
    color: #454550;
    margin: -1rem 0 0 0;
    font-size: 1rem;
    text-decoration: underline;
}

.login-text > a {
    margin-top: -2.3rem;
    text-decoration: none;
}

.login-button {
    display: block;
    width: 100%;
    margin: 3rem 0 2rem 0;
}

.login-button > p , .login-another > p{
    font-size: 1rem;
    line-height: 1.8rem;
    font-weight: 600;
}

.login-another-container, .login-another{
    padding: 0 !important;
}

.login-another-container{
    margin: 4rem 0 3.5rem 0;
}

.login-another-container-button{
    margin-bottom: 2rem;
}

.login-another > p {
    border-bottom: 1px solid #E5E5E5; 
    text-align: center;
    
    line-height: 0.1em;
    margin: 10px 0 20px; 
}

.login-another > p > span {
    padding:0 1.5rem; 
    background-color: #fff;
}

.login-another-button {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0.7rem 1.5rem 0.7rem 1.5rem !important;
    margin: 0 0 2rem 0;
}

.login-another-button > img {
    width: 2rem !important;
    margin: 0 !important;
}

.login-another-button > p {
    width: 100%;
    text-align: left;
    padding-left: 21.5%;
    align-self: center;
    color: #454550;
    font-weight: 400 !important;
}

.login-another-facebook{
    display: flex;
    justify-content: center;
}

@media only screen and (min-width: 768px) {
    
}

@media only screen and (min-width: 992px) {
    .login-another > p {
        margin: inherit;
    }

    .login-button {
        margin: 2rem 0 3rem 0;
        padding: 0.5rem 4.2rem 0.5rem 4.2rem !important;
    }

    .login-butto:hover {
        border: 1px solid rgba(0, 0, 0, .5);
    }

    .login-another-button {
        width: 98%;
    }
}

@media only screen and (min-width: 1200px) {
}
#modal-our-history-text .slick-list{
    width: 100%;
    overflow-y: scroll;
    max-height: 45vw;
    margin-bottom: 2rem;
}

.slider-our-history-container p{
    font-weight: 200;
}

#modal-our-history-row{
    position: relative;
}

#modal-our-history > .modal-content{
    background-color: transparent;
}

#modal-our-history-img .slick-slide * {
    width: 100%;
    height: 100%;
}

#modal-our-history-year{
    position: absolute;
    bottom: 0.8rem;
    padding: 0 10rem;
}

#modal-our-history-year .slick-slide > div > .modal-our-history-year-item > .dot{
    opacity: 0;
}

#modal-our-history-year .slick-current > div > .modal-our-history-year-item > .dot{
    opacity: 1;
}

#modal-our-history-year .slick-slide > div > .modal-our-history-year-item > .text-2{
    color: rgba(255, 255, 255, 0.9);
}

#modal-our-history-year .slick-current > div > .modal-our-history-year-item > .text-2{
    color: #fff;
}

#modal-our-history-line{
    position: absolute;
    bottom: 4rem;
}

#modal-our-history-line > img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.modal-our-history-year-item {
    display: flex !important;
    flex-direction: column;
    align-items: center;
}

.modal-our-history-year-item > .dot {
    height: 0.5rem;
    width: 0.5rem;
    background-color: #fff;
    margin-bottom: 1rem;
}

.service-center-image{
    padding: 2rem 0 0 0 !important;
}

.service-center-image > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.slider-our-history-arrow {
    width: 2.5rem !important;
    height: 2.5rem;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid rgb(229, 229, 229);
    border-radius: 50%;
    cursor: pointer;
    font-size: 0.85rem;
}

.slider-our-history-prev {
    top: 2.5rem;
    left: -6rem;
    color: #E5E5E5;
    transform: rotate(180deg);
}

.slider-our-history-next {
    top: 2.5rem;
    right: -4.2rem;
    color: #454550;
}

.slider-our-history-arrow > img {
    width: 0.7rem;
}

.service-center-image .slick-dots {
    top: -0.3rem;
    margin: 0;
    list-style: none;
    text-align: center;
    width: 73% !important;
    display: flex;
    justify-content: flex-end;
}

.slider-dot-ul {
    width: 100%;
}

.service-center-image .slider-dot{
    background-color: #C1C1C1;
    width: 0.5rem;
    height: 0.5rem;
    display: flex;
    justify-content: flex-end;
}

.service-center-image ul{
    padding-left: 0 !important;
    padding-top: 0.6rem;
    display: flex;
    justify-content: flex-start;
    overflow: hidden;
}

.slick-active > .slider-dot{
    background-color: #000000 !important;
}

.slider-dot-ul > li{
    position: relative;
    display: inline-block;
    width: 0.5rem !important;
    height: 0.5rem !important;
    margin: 0 1rem !important;
    padding: 0;
    cursor: pointer;
}

.slider-dot-ul > li:first-child{
    margin: 0 1rem 0 0!important;
}

@media only screen and (min-width: 992px) {
    .slider-our-history-container, .slider-our-history-container > img{
        height: 12rem;
    }

    .slider-our-history-container, .slider-our-history-container > img{
        width: 100%;
        height: 25rem;
    }

    #modal-our-history-row{
        position: relative;
        display: flex;
    }

    #modal-our-history-img   * {
        width: 100%;
        height: 100%;
    }

    .service-center-image ul{
        justify-content: flex-end;
    }

    .slider-our-history-prev {
        top: 1.2rem;
        left: -5.2rem;
    }
    
    .slider-our-history-next {
        top: 1.2rem;
        right: -5.2rem;
    }

    .service-center-image .slick-dots {
        top: 0.7rem !important;
    }
}
.special-request-banner > div > div{
    padding: 0;
}

.special-request-banner > div > div > img{
    width: 100%;
}

.special-request-banner > div > div > .desktop{
    display: none;
}

.special-request-title > h1, .special-request-title > h2{
    font-size: 1.2rem;
    line-height: 1.8rem;
    font-weight: 600;
    margin: 2rem 0 0 0;

    text-align: center;
}

.special-request-card > div > a > img{
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.special-request-form{
    padding: 0 1rem;
    border: 1px solid #E5E5E5;
    margin-top: -4rem !important;
    background-color: #FFF;
    z-index: 0;
    position: relative;
    margin-bottom: 6rem;
}

.special-request-button {
    display: block;
    width: 60%;
    margin: 7rem 0 4rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.special-request-button > p {
    font-size: 0.85rem;
    font-weight: 600;
}

@media only screen and (min-width: 768px) {
    
}

@media only screen and (min-width: 992px) {
    .special-request-banner > div > div > img.desktop{
        display: block;
    }
    .special-request-banner > div > div > img.mobile{
        display: none;
    }

    .special-request-form{
        padding: 0 2rem;
    }

    .special-request-button{
        margin: 1rem 0 2.5rem 0;
    }
}

@media only screen and (min-width: 1200px) {
}
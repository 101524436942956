.form-field-title, .form-field{
    color: #454550 !important;
    border-radius: 0px !important;
    font-weight: 600;
    font-size: 0.8rem;
}

.tda-form-control{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.tda-form-control > label{
    height: 100%;
    display: flex;
    align-items: center;
}

.tda-form-control.date > .form-field-title{
    height: auto;
}

.form-field{
    padding: 1rem 0 1rem 1rem !important;
    margin: 0.2rem 0 1.7rem 0;
}

.form-field:focus{
    outline: none !important;
    box-shadow: none !important;
    border: 1px solid #454550 !important;
}

.form-field::placeholder{
    color: #C1C1C1 !important;
}

@media only screen and (min-width: 768px) {
    
}

@media only screen and (min-width: 992px) {
    .form-field::placeholder{
        font-size: 0.85rem !important;
    }
}

@media only screen and (min-width: 1200px) {
}
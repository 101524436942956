.service-center-banner > div > div{
    padding: 0;
}

.service-center-banner > div > div > img{
    width: 100%;
}

.service-center-banner > div > div > .desktop{
    display: none;
}

.service-center-background-bottom {
    padding: 0 !important;
}

.service-center-background-bottom{
    background-color: #F7F7F7;
}

.service-center-background-content{
    padding: 0 0 2rem 0;
}

.service-center-title > h1, .service-center-maps > h2,
.service-center-address > h2{
    font-size: 1.5rem;
    line-height: 1.8rem;
    font-weight: 600;
    margin: 2rem 0 0.5rem 0;
}

.service-center-address > h2{
    color: #454550;
}


.service-center-title > h1{
    text-align: center;
    font-size: 1.3rem;
    margin-bottom: 2.5rem;
}

.service-center-card > div > a > img{
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.service-center-form{
    padding: 0 1rem 1.5rem 1rem;
    border: 1px solid #E5E5E5;
    margin-top: -4rem !important;
    margin-bottom: 2.5rem;
    background-color: #FFF;
    z-index: 0;
    position: relative;
}

.service-center-button {
    display: block;
    width: 100%;
    margin: 7rem 0 0.5rem 0;
}

.service-center-button p {
    font-size: 0.85rem;
    line-height: 1.8rem;
    font-weight: 600;
}

.service-center-contact-us {
    text-align: center;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
}

.service-center-address h3, .service-center-description p,
.service-center-address p, .home-telp{
    color: #454550;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 0;
}

.home-telp-header{
    font-weight: 600;
}

.service-center-address p {
    font-size: 1rem;
    text-align: justify;
}

.service-center-address > h3{
    margin-top: 1.2rem;
    letter-spacing: 2px;
    font-weight: 600;
}

.service-center-description > p{
    padding: 2.5rem 0 1.5rem 0;
    text-align: justify;
}

.service-center-image{
    padding: 2rem 1.8rem;
}

.service-center-image > img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.service-center-maps > div > img {
    width: 100%;
}

.service-center-button-whatsapp{
    display: flex;
    width: 16rem;
    border: 1px solid;
    margin: 0.5rem 0 7rem 0;
}

.service-center-button-whatsapp a{
    display: flex;
    padding: 0.5rem;
}

.service-center-button-whatsapp a, .service-center-button-whatsapp a:hover {
    text-decoration: none;
    color: #000;
    width: 100%;
}

.service-center-button-whatsapp img {
    width: 25px;
    height: 25px;
}

.service-center-button-whatsapp p {
    color: #454550;
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: 2px;
    padding: 0.2rem 0 0 1rem;
    margin: 0 0 0.8rem 0;
}

#iframe-service-center{
    height: 18.5rem;
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
}

@media only screen and (min-width: 768px) {
    
}

@media only screen and (min-width: 992px) {
    .service-center-banner > div > div > img.desktop{
        display: block;
    }
    .service-center-banner > div > div > img.mobile{
        display: none;
    }

    .service-center-background-content{
        background-color: #F7F7F7;
        padding-bottom: 3rem !important;
    }

    .service-center-background-bottom{
        background-color: #FFF;
    }

    .service-center-form{
        padding: 0 2rem 1.5rem 2rem;
    }

    .service-center-button{
        margin: 2rem 0 0.5rem 0;
    }

    .service-center-description > p {
        font-size: 1.1rem;
        text-align: justify;
    }
    
    .service-center-maps > h2 {
        margin-bottom: 3rem;
    }

    .service-center-address{
        padding-top: 5rem;
    }

    #iframe-service-center{
        height: 22.5rem;
    }

    .service-center-address > h2{
        padding-top: 2rem;
    }
}

@media only screen and (min-width: 1092px) {
    .service-center-button-whatsapp p {
        font-size: 0.85rem;
        margin-bottom: 1rem;
    }
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.brand-banner{
    width: 100vw;
    height: 30vh;
}

.brand-banner > img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.slider-banner-dot-container {
    display: none !important;
}

.button-brand-page{
    width: 100%;
    margin-top: 4rem;
}

.button-brand-page-footer-1{
    width: 100%;
    margin-top: 4rem;
}

.button-brand-page-footer-2{
    width: 100%;
}

.text-1{
    font-size: 0.9rem;
    text-align: justify;
    overflow: hidden;
}

.text-2{
    font-size: 1rem;
    overflow: hidden;
}

.text-head-1{
    font-weight: 600;
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    overflow: hidden;
}

.section{
    margin: 5rem 0 3rem 0;
}

.section2{
    margin: 0 0 6rem 0;
}

.section-slider-brand{
    margin: 0 0 2rem 0;
}

.slider-brand-page{
    margin: 0 0 2rem 0;
}

.slider-brand-page-container{
    height: 16rem;
}

.slider-brand-page-container > img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

hr{
    border-color: rgba(229, 229, 229,0.3);
    margin: 3.5rem 0 4.5rem 0 !important;
}

.slider-banner-page-arrow {
    width: 2.5rem !important;
    height: 2.5rem;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(229, 229, 229);
    border-radius: 50%;
    cursor: pointer;
    font-size: 0.85rem;
}

.slider-banner-page-prev {
    bottom: -4rem;
    left: 7rem;
    color: #E5E5E5;
    transform: rotate(180deg);
}

.slider-banner-page-next {
    bottom: -4rem;
    right: 7rem;
    color: #454550;
}

.slider-banner-page-arrow > img {
    width: 0.7rem;
}

.button-rectangle-1{
    margin-bottom: 2rem;
}

.button-rectangle-2{
    padding: 0.8rem 3rem 0.8rem 3rem;
}

.brand-page-main{
    padding-bottom: 5rem !important;
}

@media only screen and (min-width: 768px) {
    
}

@media only screen and (min-width: 992px) {
    .brand-banner{
        width: 100vw;
        height: 55vh;
    }

    .brand-banner > img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    .button-brand-page, .button-brand-page-footer{
        width: auto;
        margin-top: 4rem;
    }

    .text-1{
        font-size: 0.9rem;
    }

    .text-2{
        font-size: 1rem;
    }

    .text-head-1{
        font-weight: 600;
        font-size: 1.5rem;
        margin-bottom: 1.5rem;
    }

    .section{
        margin: 5rem 0 3rem 0;
    }

    .section2{
        margin: 0 0 6rem 0;
    }

    .section-slider-brand{
        margin: 0 0 2rem 0;
    }

    .slider-brand-page{
        margin: 0 0 2rem 0;
    }

    .slider-brand-page-container{
        width: 18rem;
        height: 28rem;
    }

    .slider-brand-page-container > img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    hr{
        border-color: rgba(229, 229, 229,0.3);
        margin: 3.5rem 0 4.5rem 0 !important;
    }

    .slider-banner-page-arrow {
        width: 2.5rem !important;
        height: 2.5rem;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid rgb(229, 229, 229);
        border-radius: 50%;
        cursor: pointer;
        font-size: 0.85rem;
    }

    .slider-banner-page-prev {
        top: 45%;
        left: -5rem;
        color: #E5E5E5;
        transform: rotate(180deg);
    }
    
    .slider-banner-page-next {
        top: 45%;
        right: -5rem;
        color: #454550;
    }
    
    .slider-banner-page-arrow > img {
        width: 0.7rem;
    }

    .button-rectangle-1{
        margin-right: 1.2rem;
    }

    .button-rectangle-2{
        padding: 0.8rem 3rem 0.8rem 3rem;
        margin-left: 1.2rem;
    }

    .slider-banner-dot > img{
        width: 3rem;
        height: 2.5rem;
        object-fit: cover;
        object-position: center;
    }

    .slider-banner-dot-container {
        margin-top: 2rem;
        display: block !important;
        position: absolute;
        bottom: -2rem;
    }

    .slider-banner-dot-container > li {
        width: 3rem;
        margin: 0 !important;
        opacity: 0.5;
    }

    .slider-banner-dot-container > li.slick-active {
        opacity: 1;
    }

    .button-brand-page-footer-1{
        width: 30%;
    }
    
    .button-brand-page-footer-2{
        width: 30%;
    }
}

@media only screen and (min-width: 1200px) {
}
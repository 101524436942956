
/* Home Description */
.container-fluid{
    padding: 0 !important;
}
.home-description-detail-footer {
    background-color: #000;
}

.font-weight-bold{
    font-weight: 1000;
}

.container-fluid-brand-card{
    background-color: #F7F7F7;
    padding-bottom: 2.5rem !important;
}

.home-description-container{
    width: 100%;
    padding-top: 7.5rem !important;
    padding-bottom: 2.5rem !important;

    background-color: #F7F7F7;
}
.home-description-container > div > div > p{
    font-weight: 400;
}

.home-description-button {  
    margin: 1rem 0 1rem 0rem;
    width: 100%;
}

.home-description-button p{
    font-weight: bold;
}

.our-history-button-desktop{
    display: none;
}

.home-description-container>.top{
    display: flex;
}

.home-description-title {
    font-size: 1.2rem;
    letter-spacing: 1.3px;
    line-height: 2rem;
}

.home-description-paragraph {
    font-size: 0.9rem;
    letter-spacing: 1px;
    margin-bottom: 1.5rem;
    margin-top: 0.5rem;
    text-align: justify;
}

.slider-banner-info{
    margin-top: -0.45rem !important;
    position: relative !important;
}

.car-slider-button {
    width: 100% !important;
    margin: 0 0 2rem 0 !important;
}

.car-slider-button > p{
    font-size: 0.85rem !important;
}

.home-description-container>.bottom{
    width: 100%;
    display: flex;
    background-color: #FFF;
}

.home-description-container>.bottom>.brand-card>.brand-logo{
    width: 100%;
    height: 100%;

    background-repeat: no-repeat;
    background-size: 90%;
    background-position: center;

    transition: 0.3s ease-out;
}

.container-brand-card{
    display: flex;
    justify-content: center;
}

.container-brand-card > .row {
    width: 100% !important;
}

.brand-card{
    padding: 0 !important;
    border: 1px solid rgb(229, 229, 229, 0.3);
    display: flex;
    justify-content: center;
    background-color: #fff;
    height: auto;
}

.brand-card a{
    padding: 1rem;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.brand-card div{
    width: 100%;
}

.brand-card img {
    width: 100%;
    height: 100%;
    padding: 0.5rem;
    object-fit: contain;
    object-position: center;
}

.home-description-container>.bottom>.brand-card>.brand-logo:hover{
    background-size: 100%;
}

.home-footer{
    padding: 5rem 0;
    margin-top: 5rem;
}

.home-footer-black {
    background-color: #000 !important;
    padding-bottom: 1.5rem !important;
    margin: 0 !important;
    width: 100vw;
}

.home-button-whatsapp{
    margin: 0.5rem 0 1rem 0 !important;
}

.home-button-whatsapp a{
    display: flex;
    text-decoration: none;
}

.home-button-whatsapp a:hover{
    color: #fff;
}

.home-footer-apps{
    margin-top: 1rem !important;
}

.home-footer-image-apps{
    width: 16rem;
    height: 100%;
    object-fit: contain;
    object-position: center;
    margin: 0.7rem 0;
}

.home-footer-credit > p {
    font-weight: 400;
    line-height: 1.8rem;
    font-size: 0.85rem;
    margin-bottom: 0;
    color: #454550;
}

.home-footer-credit > p > a {
    color: #454550;
}

.home-footer-credit-container {
    margin: 2rem 0 !important;
}

.home-footer-credit-policy {
    margin-top: 1.5rem !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.home-footer-credit-policy-img {
    margin: 0.1rem 0.2rem;
}

#iframehome{
    height: 18rem;
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
}

#modal-our-history-text h1{
    text-align: center;
    margin-bottom: 2.5rem;
}

.our-history-mobile-close-container{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5.5rem;
}

.our-history-mobile-close{
    -webkit-filter: invert(100%);
    filter: invert(100%);
    cursor: pointer;
}

#our-history-mobile{
    height: 125vh;
    background-color: #000;
    position: fixed;
    z-index: 11;
    top: 0;
    padding: 2rem 2rem !important;
    color: #fff;
    overflow-y: scroll;
}

.home-description-button{
    font-size: 0.9rem;
}

@media only screen and (min-width: 768px) {
    #our-history-mobile{
        width: 100vw;
    }

    #our-history-mobile{
        height: 120vh;
    }
}

@media only screen and (min-width: 992px) {

    #modal-our-history-text h1{
        text-align: left;
    }

    #modal-our-history{
        width: 80vw;
        max-width: 80vw;
        height: 80vh;
        max-height: 80vh;
    }

    #modal-our-history > .modal-content > .modal-body{
        padding: 0 !important;
    }

    #modal-our-history > .modal-content{
        border-radius: 0 !important;
    }

    #modal-our-history-text{
        padding: 0 6rem;
        background-color: #000;
        color: #fff;
    }

    #modal-our-history-text > div:nth-child(1){
        height: 40%;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        padding-bottom: 1rem;
        
    }

    #modal-our-history-img{
        padding: 0 !important;
    }

    #our-history-mobile{
        display: none;
    }

    .modal-our-history-img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    #modal-our-history-header{
        border-bottom: 0 !important;
    }

    #iframehome{
        height: 100%;
    }

    .slider-banner-scroll p{
        margin-bottom: 0 !important;
    }
    /* Home Description */
    .home-description-container{
        width: 100%;
        padding-top: 5rem !important;
        padding-bottom: 2.5rem !important;

        background-color: #F7F7F7;
    }
    .home-description-container > div > div > p{
        font-weight: 400;
    }

    .home-description-button {
        margin: 2.5rem 0 0rem 0rem !important;
        width: 15rem;
    }

    .our-history-button-desktop{
        display: block;
    }

    .our-history-button-mobile{
        display: none;
    }

    .home-description-container>.top{
        display: flex;
    }

    .home-description-title {
        font-size: 1.8rem;;
        letter-spacing: 1.3px;
        line-height: 2.5rem;
        padding-right: 1rem !important;
    }

    .home-description-paragraph {
        font-size: 0.85rem;
        letter-spacing: 1px;
        margin-bottom: 1.5rem;
        margin-top: 0.5rem;
    }
    
    .slider-banner-info{
        display: flex !important;
        position: absolute !important;

        right: -0.5rem;
        bottom: 0;

        width: 45rem;
        height: 7rem;
        transition: all 1s;
    }

    .slider-banner-info.open {
        right: 0;
    }

    .slider-banner-info.close{
        right: -42rem;
    }

    .car-slider-button {
        width: 12rem !important;
        margin: 0 !important;
    }

    .home-description-container>.bottom{
        width: 100%;
        display: flex;
        background-color: #FFF;
    }
    .home-description-container>.bottom>.brand-card{
        width: 14vw;
        height: 12.5vw;
        padding: 1rem;

        border: 1px solid rgb(229, 229, 229, 0.3);
    }

    .home-description-container>.bottom>.brand-card>.brand-logo{
        width: 100%;
        height: 100%;

        background-repeat: no-repeat;
        background-size: 90%;
        background-position: center;

        transition: 0.3s ease-out;
    }

    .home-description-container>.bottom>.brand-card>.brand-logo:hover{
        background-size: 100%;
    }

    .home-map, .home-description-detail-footer {
        padding: 0 !important;
    }

    .home-map{
        padding-left: 0.5rem !important;
    }

    .home-description-footer-middle {
        padding: 0 0 0 4rem !important;
    }

    .home-maps-col > *, .home-map > div, .home-map > div > img {
        height: 100%;
    }

    .home-footer-credit{
        display: flex;
    }

    .home-footer-image-apps {
        width: 9rem;
        height: 100%;
        object-fit: contain;
        object-position: center;
        margin: -0.3rem 0 2.5rem 2rem;
    }

    .home-footer-credit-policy {
        justify-content: flex-end;
        margin-top: 0 !important;
    }

    .home-footer-credit > p {
        font-size: 1rem;
        margin-right: 1rem;
    }

    .home-footer-credit-policy-img {
        width: 30px;
    }
}

@media only screen and (min-width: 1092px) {
    #modal-our-history-text {
        padding: 0 6rem;
        background-color: #000;
        color: #fff;
    }

    .home-description-title {
        font-size: 1.8rem;
    }

    .home-description-paragraph {
        font-size: 1rem;
    }

    .car-slider-button > p{
        font-size: 0.85rem !important;
    }

    .home-footer-image-apps {
        width: 10rem;
        margin: -0.3rem 0 2.5rem 3rem;
    }
    
    #modal-our-history-text h1{
        text-align: left;
    }
}


@media only screen and (min-width: 1214px) {
    #modal-our-history-text {
        padding: 0 8rem;
        background-color: #000;
        color: #fff;
    }
}

@media only screen and (max-height: 768px) {
    #modal-our-history-img-mobile .slick-slide {
        height: 9rem !important;
    }
    #modal-our-history-img-mobile .slick-slide * {
        height: 75vw !important;
    }
}

@media only screen and (max-height: 640px) {
    .our-history-mobile-close-container {
        margin-bottom: 1rem;
    }
}
.my-garage-car > .my-garage-car-card > div > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: bottom;
}

.icon-edit{
    cursor: pointer;
}

.my-garage-car{
    padding: 0 0.5rem !important;
}

.my-garage-edit-delete{
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
}

.my-garage-edit-delete > img{
    margin-left: 0.3rem;
    width: 1.4rem;
}

.my-garage-see-all{
    position: absolute;
    bottom: 1rem;
    right: 0.8rem;
    background-color: #fff;
}

.my-garage-see-all > p{
    font-size: 0.6rem;
    margin-bottom: 0 !important;
    padding: 0.4rem 1.2rem;
    font-weight: 600;
    color: #000;
}

.my-garage-car-image{
    height: 16rem;
    position: relative;
}

.my-garage-car > .my-garage-car-card > h2 {
    color: #454550;
    font-size: 1.2rem;
    line-height: 1.8rem;
    margin: 0.7rem 0 0.2rem 0;
    font-weight: 400;
    height: 1.9em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; 
}

.my-garage-car-detail > p {
    color: #454550;
    font-size: 0.85rem;
    line-height: 1.8rem;
    font-weight: 400;
    margin-bottom: 0rem !important;
}

.my-garage-car-detail {
    display: flex;
    justify-content: space-between;
}

.my-garage-car-detail > p:nth-child(1){
    font-weight: 600;
    color: #000;
}
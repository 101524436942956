*{
    margin: 0;
    padding: 0;
    border: 0;
    font-family: 'Titillium Web', sans-serif !important;
    letter-spacing: 1px;
}

.container{
    padding: 0 2rem !important;
}

.form-control{
    font-size: 0.8rem !important;
}

#root{
    width: 100vw;
}

button p{
    margin: 0;
}

.display-none{
    display: none !important;
}

.icon-arrow{
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    margin-right: 0.5rem;
}

.text-bold{
    font-weight: 600;
}

.home,
.services,
.products,
.contact-us,
.sign-up,
.marketing,
.consulting {
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
}

.font-weight-bold{
    color: #000 !important;
}

a{
    color:#000;
    text-decoration: none !important;
    cursor: pointer !important;
}

tbody, td, tfoot, th, thead, tr {
    border-color: inherit;
    border-style: none !important;
    border-width: 0;
}

.tda-navbar-trigger-mobile{
    display: block !important;
}

.tda-navbar-desktop{
    display: none !important;
}

.container-fluid{
    padding: 0 !important;
}

.btn-close:focus{
    box-shadow: none !important;
}

.button-rectangle{
    background: linear-gradient(#000 0 0) no-repeat calc(100% - 0%)
    100% / 100% 0.01em;
    background: linear-gradient(#000 0 0) no-repeat calc(100% - var(--p, 0%))
    100% / 100% var(--p, 0.01em);
    transition: 0.4s 0s,
    background-position 0.3s calc(0.3s - 0s);
    transition: 0.4s var(--t, 0s),
    background-position 0.3s calc(0.3s - var(--t, 0s));
    color: #000;
}

.button-rectangle-danger{
    background: linear-gradient(#f00 0 0) no-repeat calc(100% - 0%)
    100% / 100% 0.01em !important;
    background: linear-gradient(#f00 0 0) no-repeat calc(100% - var(--p, 0%))
    100% / 100% var(--p, 0.01em) !important;
}

.button-rectangle:hover p{
    color: #fff;
}

.button-rectangle:hover{
    --p: 100%;
    --t: 0.4s;
}

.error-message, .error-message-login{
    margin: 0 !important;
    font-size: 1rem;
    height: 1.8rem;
    font-weight: 600;
    color: red;
}

.error-message-login{
    text-align: justify;
    margin: -2rem 0 0.8rem 0 !important;
}

.text-center{
    text-align: center;
}

.text-opacity-zero{
    opacity: 0;
    margin: 0;
}

.text-italic{
    font-style: italic;
}

.blink_me_div{
    width: 100vw;
    height: 100vh;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 11;
    font-size: 2.5rem;

    background-color: #fff;
    display: none;
}

.blink_me {
    -webkit-animation: blinker 1s linear infinite;
            animation: blinker 1s linear infinite;
    text-shadow: 3px 2px 1px rgba(0,0,0,0.2);
}

#modal-zoom .modal-header{
    display: flex;
    justify-content: flex-end;
}

@-webkit-keyframes blinker {
    50% {
        opacity: 0;
    }
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

@media only screen and (min-width: 768px) {
    
}

@media only screen and (min-width: 992px) {


    .container{
        padding: 0 2rem !important;
        max-width: 90vw !important;
    }

    .tda-navbar-trigger-mobile{
        display: none !important;
    }
    .tda-navbar-desktop{
        display: block !important;
    }
}

@media only screen and (min-width: 1200px) {
}
.register-container {
    margin-top: 9rem !important;
}

.register-back a, .register-back a:hover {
    text-decoration: none;
    font-size: 0.85rem;
    color: #454550;
}

.register-back p {
    margin-bottom: 0;
}

.register-back *, .register-back {
    display: flex;
}

.register-form *{
    z-index: -2;
}

.register-form img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: 100%;
}

.register-title > h1{
    font-size: 1.3rem;
    line-height: 1.8rem;
    font-weight: 600;
    margin: 0.3rem 0 0.3rem 0;
}

.register-title > p{
    margin-bottom: 4rem;
}

.register-card > div > a > img{
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.register-form{
    padding: 0 1rem;
    padding: 0;
    margin-top: 7rem !important;
    position: relative;
}

.register {
    display: flex;
}

.register-text {
    display: flex;
}

.register-text > p {
    color: #454550;
    margin: -1rem 0 0 0;
    font-size: 1rem;
    text-decoration: underline;
}

.register-button {
    display: block;
    width: 100%;
    margin: 3rem 0 2rem 0;
}

.register-button p , .register-another p{
    font-size: 1rem;
    line-height: 1.8rem;
    font-weight: 600;
}

@media only screen and (min-width: 768px) {
    
}

@media only screen and (min-width: 992px) {
    .register-button {
        margin: 2.5rem 0 3rem 0;
    }

    .register-form {
        padding: 0 0.5rem !important;
        margin-top: 1rem !important;
    }

    .register-butto:hover {
        border: 1px solid rgba(0, 0, 0, .5);
    }

    .register-title > p{
        margin-bottom: 2rem;
    }
}

@media only screen and (min-width: 1200px) {
}
.form-field-title, .form-field{
    color: #454550 !important;
    border-radius: 0px !important;
    font-weight: 600;
    font-size: 0.8rem;
}

.tda-form-control{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.tda-form-control > label{
    height: 100%;
    display: flex;
    align-items: center;
}

.tda-form-control.date > .form-field-title{
    height: auto;
}

.form-field{
    padding: 1rem 0 1rem 1rem !important;
    margin: 0.2rem 0 1.7rem 0;
}

.form-field:focus{
    outline: none !important;
    box-shadow: none !important;
    border: 1px solid #454550 !important;
}

.form-field::-webkit-input-placeholder{
    color: #C1C1C1 !important;
}

.form-field:-ms-input-placeholder{
    color: #C1C1C1 !important;
}

.form-field::placeholder{
    color: #C1C1C1 !important;
}

@media only screen and (min-width: 768px) {
    
}

@media only screen and (min-width: 992px) {
    .form-field::-webkit-input-placeholder{
        font-size: 0.85rem !important;
    }
    .form-field:-ms-input-placeholder{
        font-size: 0.85rem !important;
    }
    .form-field::placeholder{
        font-size: 0.85rem !important;
    }
}

@media only screen and (min-width: 1200px) {
}
.login-title > h1{
    font-size: 1.3rem;
    line-height: 1.8rem;
    font-weight: 600;
    margin: 2rem 0 2rem 0;
}

.login-card > div > a > img{
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.login-form{
    padding: 0 1rem;
    padding: 0;
    margin-top: 4rem !important;
    background-color: #FFF;
    z-index: 0;
    position: relative;
}

.forgot-password {
    display: flex;
}

.login-text {
    display: flex;
    margin-top: 2rem !important;
}

.login-text > a > p, .login-text > p > a {
    color: #454550;
    margin: -1rem 0 0 0;
    font-size: 1rem;
    text-decoration: underline;
}

.login-text > a {
    margin-top: -2.3rem;
    text-decoration: none;
}

.login-button {
    display: block;
    width: 100%;
    margin: 3rem 0 2rem 0;
}

.login-button > p , .login-another > p{
    font-size: 1rem;
    line-height: 1.8rem;
    font-weight: 600;
}

.login-another-container, .login-another{
    padding: 0 !important;
}

.login-another-container{
    margin: 4rem 0 3.5rem 0;
}

.login-another-container-button{
    margin-bottom: 2rem;
}

.login-another > p {
    border-bottom: 1px solid #E5E5E5; 
    text-align: center;
    
    line-height: 0.1em;
    margin: 10px 0 20px; 
}

.login-another > p > span {
    padding:0 1.5rem; 
    background-color: #fff;
}

.login-another-button {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0.7rem 1.5rem 0.7rem 1.5rem !important;
    margin: 0 0 2rem 0;
}

.login-another-button > img {
    width: 2rem !important;
    margin: 0 !important;
}

.login-another-button > p {
    width: 100%;
    text-align: left;
    padding-left: 21.5%;
    align-self: center;
    color: #454550;
    font-weight: 400 !important;
}

.login-another-facebook{
    display: flex;
    justify-content: center;
}

@media only screen and (min-width: 768px) {
    
}

@media only screen and (min-width: 992px) {
    .login-another > p {
        margin: inherit;
    }

    .login-button {
        margin: 2rem 0 3rem 0;
        padding: 0.5rem 4.2rem 0.5rem 4.2rem !important;
    }

    .login-butto:hover {
        border: 1px solid rgba(0, 0, 0, .5);
    }

    .login-another-button {
        width: 98%;
    }
}

@media only screen and (min-width: 1200px) {
}
.forgot-password-container{
    margin-top: 9rem;
}

.forgot-password-back > p > a {
    text-decoration: none;
    font-size: 0.85rem;
    color: #454550;
}

.forgot-password-back > p >  a > i {
    padding-right: 0.5rem;
    margin-bottom: 0;
}

.forgot-password-back > p {
    margin-bottom: 0;
    display: flex;
}

.forgot-password-title > h1{
    font-size: 1.3rem;
    line-height: 1.8rem;
    font-weight: 600;
    margin: 0.3rem 0 0.3rem 0;
}

.forgot-password-title > p{
    margin-bottom: 4rem;
}

.forgot-password-form{
    padding: 0 1rem;
    padding: 0;
    margin-top: 1rem !important;
    background-color: #FFF;
    z-index: 10;
    position: relative;
}

.forgot-password {
    display: flex;
}

.forgot-password-text {
    display: flex;
}

.forgot-password-text > p {
    color: #454550;
    margin: -1rem 0 0 0;
    font-size: 1rem;
    text-decoration: underline;
}

.forgot-password-button {
    display: block;
    width: 100%;
    margin: 6rem 0 2rem 0;
}

.forgot-password-button > p , .forgot-password-another > p{
    font-size: 1rem;
    line-height: 1.8rem;
    font-weight: 600;
}

@media only screen and (min-width: 768px) {
    
}

@media only screen and (min-width: 992px) {
    .forgot-password-form{
        padding: 0;
    }

    .forgot-password-title > p {
        margin-bottom: 2rem;
    }

    .forgot-password-button {
        margin: 1rem 0 2rem 0;
    }
}

@media only screen and (min-width: 1200px) {
}
.my-profile-container{
    padding-bottom: 5rem !important;
    padding-top: 6rem !important;
}

.my-profile-back {
    margin-top: 7rem !important;
}

.my-profile-back > a {
    text-decoration: none;
    font-size: 0.85rem;
    color: #454550;
}

.my-profile-back > a > p > i {
    padding-right: 0.5rem;
    margin-bottom: 0;
}

.my-profile-back > a > p {
    margin-bottom: 0;
}

.my-profile-title > h1{
    font-size: 1.3rem;
    line-height: 1.8rem;
    font-weight: 600;
    margin: 0.2rem 0 1.5rem 0;
}

.my-profile-title > p{
    margin-bottom: 4rem;
}

.my-profile-card > div > a > img{
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.my-profile-form{
    padding: 0 1rem;
    padding: 0;
    background-color: #FFF;
    z-index: 0;
    position: relative;
}

.my-profile {
    display: flex;
}

.accordion-button::after {
    margin-top: -1rem !important;
}

.my-profile-text {
    display: flex;
}

.my-profile-text > p {
    color: #454550;
    margin: -1rem 0 0 0;
    font-size: 1rem;
    text-decoration: underline;
}

.my-profile-button {
    display: block;
    width: 100%;
    margin: 2rem 0 1rem 0;
}

.my-profile-button > p , .my-profile-another > p{
    font-size: 1rem;
    line-height: 1.8rem;
    font-weight: 600;
}

.accordion-button {
    flex-direction: column;
    align-items: start !important;
}

#accordion-my-profile{
    padding-bottom: 0.4rem !important;
}

.accordion-button > p:nth-child(2) {
    font-weight: 400;
}

.my-profile-divider{
    margin: 2.5rem 0 3.5rem 0rem !important;
    border-bottom: 1px solid #E5E5E5;
}

.circle {
    display: flex;
    width: 5rem;
    height: 5rem;
    background-color: #F7F7F7;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
}

.circle-image {
    position: absolute;
    bottom: 2.1rem;
    margin-left: 3.5rem;
}

.circle-image:hover {
    cursor: pointer;
}

.my-profile-photo{
    padding-bottom: 1.5rem;
}

.member-benefit-my-profile{
    font-size: 0.85rem;
    color: #454550;
}

.member-benefit-my-profile > li::marker{
    color: #000;
}

.member-card-my-profile{
    width: 100%;
    margin: 2rem 0 1.5rem 0;
}

.member-benefit-my-profile > li:last-child{
    padding-bottom: 1rem;
}

.member-benefit-my-profile > li:first-child{
    padding-top: 1rem;
}

.member-type-accordion{
    padding: 0.5rem 0 0 0;
    color: #454550;
}

.my-profile-my-garage{
    text-decoration: none;
}

.button-image-form-profile {
    border: 1px solid transparent !important;
    border-radius: 0 !important;
    background-color: transparent !important;
    width: 100%;
    height: 100%;
    padding: 0.05rem !important;
}

.button-image-form-profile {
    font-size: 1.2rem !important;
    line-height: 1.8rem !important;
    font-weight: 600 !important;
    color: #000 !important;
}

.button-image-form-profile:focus {
    box-shadow: none !important;
}

.button-image-form-profile * {
    border-radius: 50% !important;
}

@media only screen and (min-width: 768px) {
    
}

@media only screen and (min-width: 992px) {
    .my-profile-button {
        display: block;
        width: 100%;
        margin: 4rem 0 4rem 0;
    }

    .my-profile-title > .member-card{
        margin-bottom: 2rem;
    }

    .form-field-title{
        font-size: 0.8rem;
    }

    .my-profile-button {
        margin: 2.5rem 0 3rem 0;
        padding: 0.8rem 2.2rem 0.8rem 2.2rem !important;
    }

    .my-profile-form {
        padding: 0 0.5rem !important;
        margin-top: 1rem !important;
    }

    .my-profile-button:hover {
        border: 1px solid rgba(0, 0, 0, .5);
    }

    .my-profile-title > p{
        margin-bottom: 2rem;
    }

    .my-profile-photo{
        padding-left: 0 !important;
        margin-bottom: 0.5rem !important;
    }

    .my-profile-title > h1{
        margin-bottom: 2rem;
    }

    #accordion-my-profile > button {
        padding: 1.2rem 0 0 1.2rem !important;
    }

    #accordion-my-profile > button > p:nth-child(2) {
        margin-bottom: 0 !important;
    }

    .member-type-card{
        margin-top: 1.5rem !important;
    }
}

@media only screen and (min-width: 1200px) {
}
  .button-rectangle {
    outline: none;
    border: none;
    color: #000;
    cursor: pointer;
    border: 1px solid rgb(0, 0, 0, 0.6);
    background-color: transparent;

    letter-spacing: 2px;
    
    font-weight: 600;
    padding: 0.8rem 1rem 0.8rem 1rem;
  }
  
  .button-rectangle:hover {
    transition: all 0.3s ease-out;
    background-color: transparent;
    color: #000;
    border: 1.5px solid rgb(0, 0, 0, 0.8);
    letter-spacing: 2px;
    transition: all 0.3s ease-out;
  }
  
  .button-rectangle div{
    display: flex;
  }

  .button-rectangle img{
    width: 7.5%;
    align-self: center;
    margin: 0 0.8rem;
  }
.my-garage-container{
    padding-bottom: 5rem !important;
    padding-top: 9.5rem !important;
}

.my-garage-title {
    display: flex;
    justify-content: space-between;
    margin-top: 4rem;
}

.my-garage-title > h1{
    font-size: 1.3rem;
    line-height: 1.8rem;
    font-weight: 600;
    margin: 0 0 1.5rem 0;
}

.my-garage-title > img {
    border: 1.5px solid;
    padding: .3rem;
    width: 2.5rem;
    height: 2.5rem;
}

.my-garage-title > p {
    margin-bottom: 4rem;
}

.my-garage-car{
    margin-top: 2.5rem !important;
    margin-bottom: 2rem;
}

.my-garage-modal{
    border-bottom: 0 !important;
}


.my-garage-modal > button{
    margin: .5rem 1rem 0.5rem 0 !important;
}

.my-garage-modal{
    border-bottom: 0 !important;
    justify-content: right !important;
}

#my-garage-modal-title{
    justify-content: center !important;
    margin-bottom: 1.5rem !important;
}

.mygarage-form-container{
    margin-bottom: 2rem;
}

.my-garage-button{
    margin: 1rem 0 4rem 0;
    width: 100%;
}

.form-field-label{
    margin: 2rem 0 1.5rem 0 !important;
}

.my-garage-button-desktop{
    display: none;
}

.my-notification-clear-all{
    text-align: right;
    padding: 0;
}

.my-notification-clear-all > p{
    margin-right: -0.7rem !important;
    color: #454550;
}

#my-garage-modal-notification{
    padding: 0;
}

.my-modal-notification{
    padding-bottom: 0 !important;
    justify-content: space-between;
}

.my-notification-item{
    border-bottom: 1px solid rgb(69, 69, 80, 0.6);
    padding: 1.5rem 0 0.5rem 0 !important;
}

.my-notification-item > .date > p{
    color: #454550;
    font-size: 0.85rem;
    margin-bottom: .5rem;
}

.my-notification-item > .title > p{
    color: #454550;
    font-size: 1rem;
    margin-bottom: 0.2rem;
    font-weight: 600;
}

.my-notification-item > .description > p{
    color: #454550;
    font-size: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    
    line-height: 1.4rem;
    height: 2.9rem;

    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
}

.modal-fullscreen{
    width: 85vw !important;
    margin: auto !important;
    z-index: 3000 !important;
}

@media only screen and (min-width: 768px) {
    
}

@media only screen and (min-width: 992px) {
    .my-garage-button-desktop{
        display: block;
    }

    .my-garage-button-mobile{
        display: none;
    }

    .my-garage-modal > button{
        margin: 0 !important;
        margin-right: 2rem !important;
    }

    .my-garage-title{
        padding: 0 0.2rem !important;
        margin-bottom: 1.5rem;
    }

    .my-garage-car{
        margin-top: 1rem !important;
    }

    .my-garage-title > h1 {
        margin-bottom: 0 !important;
    }

    #my-garage-modal-title {
        justify-content: center !important;
        margin-bottom: 5.5rem !important;
    }

}

@media only screen and (min-width: 1200px) {
}
.form-field-image{
    display: none;
}

.button-image-form{
    border: 1px solid #E5E5E5 !important;
    border-radius: 0 !important;
    background-color: transparent !important;

    width: 100%;
    height: 20vw;
    padding: 0.05rem !important;
}

.button-image-form:active:focus, .button-image-form:focus{
    box-shadow: none !important;
}

.image-preview-container{
    border-radius: 0 !important;
    background-color: transparent !important;

    width: 100%;
    height: 100%;
}

.image-preview-container img, .image-preview-container > div{
    width: 100%;
    height: 100%;
}

.image-preview-container img{
    object-fit: cover;
    object-position: center;
}

@media only screen and (min-width: 768px) {
    
}

@media only screen and (min-width: 992px) {
    .button-image-form {
        height: 10vw !important;
    }
}

@media only screen and (min-width: 1200px) {
}
.my-garage-car > .my-garage-car-card > div > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: bottom;
}

.icon-edit{
    cursor: pointer;
}

.my-garage-car{
    padding: 0 0.5rem !important;
}

.my-garage-edit-delete{
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
}

.my-garage-edit-delete > img{
    margin-left: 0.3rem;
    width: 1.4rem;
}

.my-garage-see-all{
    position: absolute;
    bottom: 1rem;
    right: 0.8rem;
    background-color: #fff;
}

.my-garage-see-all > p{
    font-size: 0.6rem;
    margin-bottom: 0 !important;
    padding: 0.4rem 1.2rem;
    font-weight: 600;
    color: #000;
}

.my-garage-car-image{
    height: 16rem;
    position: relative;
}

.my-garage-car > .my-garage-car-card > h2 {
    color: #454550;
    font-size: 1.2rem;
    line-height: 1.8rem;
    margin: 0.7rem 0 0.2rem 0;
    font-weight: 400;
    height: 1.9em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; 
}

.my-garage-car-detail > p {
    color: #454550;
    font-size: 0.85rem;
    line-height: 1.8rem;
    font-weight: 400;
    margin-bottom: 0rem !important;
}

.my-garage-car-detail {
    display: flex;
    justify-content: space-between;
}

.my-garage-car-detail > p:nth-child(1){
    font-weight: 600;
    color: #000;
}

/* Home Description */
.container-fluid{
    padding: 0 !important;
}
.home-description-detail-footer {
    background-color: #000;
}

.font-weight-bold{
    font-weight: 1000;
}

.container-fluid-brand-card{
    background-color: #F7F7F7;
    padding-bottom: 2.5rem !important;
}

.home-description-container{
    width: 100%;
    padding-top: 7.5rem !important;
    padding-bottom: 2.5rem !important;

    background-color: #F7F7F7;
}
.home-description-container > div > div > p{
    font-weight: 400;
}

.home-description-button {  
    margin: 1rem 0 1rem 0rem;
    width: 100%;
}

.home-description-button p{
    font-weight: bold;
}

.our-history-button-desktop{
    display: none;
}

.home-description-container>.top{
    display: flex;
}

.home-description-title {
    font-size: 1.2rem;
    letter-spacing: 1.3px;
    line-height: 2rem;
}

.home-description-paragraph {
    font-size: 0.9rem;
    letter-spacing: 1px;
    margin-bottom: 1.5rem;
    margin-top: 0.5rem;
    text-align: justify;
}

.slider-banner-info{
    margin-top: -0.45rem !important;
    position: relative !important;
}

.car-slider-button {
    width: 100% !important;
    margin: 0 0 2rem 0 !important;
}

.car-slider-button > p{
    font-size: 0.85rem !important;
}

.home-description-container>.bottom{
    width: 100%;
    display: flex;
    background-color: #FFF;
}

.home-description-container>.bottom>.brand-card>.brand-logo{
    width: 100%;
    height: 100%;

    background-repeat: no-repeat;
    background-size: 90%;
    background-position: center;

    transition: 0.3s ease-out;
}

.container-brand-card{
    display: flex;
    justify-content: center;
}

.container-brand-card > .row {
    width: 100% !important;
}

.brand-card{
    padding: 0 !important;
    border: 1px solid rgb(229, 229, 229, 0.3);
    display: flex;
    justify-content: center;
    background-color: #fff;
    height: auto;
}

.brand-card a{
    padding: 1rem;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.brand-card div{
    width: 100%;
}

.brand-card img {
    width: 100%;
    height: 100%;
    padding: 0.5rem;
    object-fit: contain;
    object-position: center;
}

.home-description-container>.bottom>.brand-card>.brand-logo:hover{
    background-size: 100%;
}

.home-footer{
    padding: 5rem 0;
    margin-top: 5rem;
}

.home-footer-black {
    background-color: #000 !important;
    padding-bottom: 1.5rem !important;
    margin: 0 !important;
    width: 100vw;
}

.home-button-whatsapp{
    margin: 0.5rem 0 1rem 0 !important;
}

.home-button-whatsapp a{
    display: flex;
    text-decoration: none;
}

.home-button-whatsapp a:hover{
    color: #fff;
}

.home-footer-apps{
    margin-top: 1rem !important;
}

.home-footer-image-apps{
    width: 16rem;
    height: 100%;
    object-fit: contain;
    object-position: center;
    margin: 0.7rem 0;
}

.home-footer-credit > p {
    font-weight: 400;
    line-height: 1.8rem;
    font-size: 0.85rem;
    margin-bottom: 0;
    color: #454550;
}

.home-footer-credit > p > a {
    color: #454550;
}

.home-footer-credit-container {
    margin: 2rem 0 !important;
}

.home-footer-credit-policy {
    margin-top: 1.5rem !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.home-footer-credit-policy-img {
    margin: 0.1rem 0.2rem;
}

#iframehome{
    height: 18rem;
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
}

#modal-our-history-text h1{
    text-align: center;
    margin-bottom: 2.5rem;
}

.our-history-mobile-close-container{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5.5rem;
}

.our-history-mobile-close{
    -webkit-filter: invert(100%);
    filter: invert(100%);
    cursor: pointer;
}

#our-history-mobile{
    height: 125vh;
    background-color: #000;
    position: fixed;
    z-index: 11;
    top: 0;
    padding: 2rem 2rem !important;
    color: #fff;
    overflow-y: scroll;
}

.home-description-button{
    font-size: 0.9rem;
}

@media only screen and (min-width: 768px) {
    #our-history-mobile{
        width: 100vw;
    }

    #our-history-mobile{
        height: 120vh;
    }
}

@media only screen and (min-width: 992px) {

    #modal-our-history-text h1{
        text-align: left;
    }

    #modal-our-history{
        width: 80vw;
        max-width: 80vw;
        height: 80vh;
        max-height: 80vh;
    }

    #modal-our-history > .modal-content > .modal-body{
        padding: 0 !important;
    }

    #modal-our-history > .modal-content{
        border-radius: 0 !important;
    }

    #modal-our-history-text{
        padding: 0 6rem;
        background-color: #000;
        color: #fff;
    }

    #modal-our-history-text > div:nth-child(1){
        height: 40%;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        padding-bottom: 1rem;
        
    }

    #modal-our-history-img{
        padding: 0 !important;
    }

    #our-history-mobile{
        display: none;
    }

    .modal-our-history-img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    #modal-our-history-header{
        border-bottom: 0 !important;
    }

    #iframehome{
        height: 100%;
    }

    .slider-banner-scroll p{
        margin-bottom: 0 !important;
    }
    /* Home Description */
    .home-description-container{
        width: 100%;
        padding-top: 5rem !important;
        padding-bottom: 2.5rem !important;

        background-color: #F7F7F7;
    }
    .home-description-container > div > div > p{
        font-weight: 400;
    }

    .home-description-button {
        margin: 2.5rem 0 0rem 0rem !important;
        width: 15rem;
    }

    .our-history-button-desktop{
        display: block;
    }

    .our-history-button-mobile{
        display: none;
    }

    .home-description-container>.top{
        display: flex;
    }

    .home-description-title {
        font-size: 1.8rem;;
        letter-spacing: 1.3px;
        line-height: 2.5rem;
        padding-right: 1rem !important;
    }

    .home-description-paragraph {
        font-size: 0.85rem;
        letter-spacing: 1px;
        margin-bottom: 1.5rem;
        margin-top: 0.5rem;
    }
    
    .slider-banner-info{
        display: flex !important;
        position: absolute !important;

        right: -0.5rem;
        bottom: 0;

        width: 45rem;
        height: 7rem;
        transition: all 1s;
    }

    .slider-banner-info.open {
        right: 0;
    }

    .slider-banner-info.close{
        right: -42rem;
    }

    .car-slider-button {
        width: 12rem !important;
        margin: 0 !important;
    }

    .home-description-container>.bottom{
        width: 100%;
        display: flex;
        background-color: #FFF;
    }
    .home-description-container>.bottom>.brand-card{
        width: 14vw;
        height: 12.5vw;
        padding: 1rem;

        border: 1px solid rgb(229, 229, 229, 0.3);
    }

    .home-description-container>.bottom>.brand-card>.brand-logo{
        width: 100%;
        height: 100%;

        background-repeat: no-repeat;
        background-size: 90%;
        background-position: center;

        transition: 0.3s ease-out;
    }

    .home-description-container>.bottom>.brand-card>.brand-logo:hover{
        background-size: 100%;
    }

    .home-map, .home-description-detail-footer {
        padding: 0 !important;
    }

    .home-map{
        padding-left: 0.5rem !important;
    }

    .home-description-footer-middle {
        padding: 0 0 0 4rem !important;
    }

    .home-maps-col > *, .home-map > div, .home-map > div > img {
        height: 100%;
    }

    .home-footer-credit{
        display: flex;
    }

    .home-footer-image-apps {
        width: 9rem;
        height: 100%;
        object-fit: contain;
        object-position: center;
        margin: -0.3rem 0 2.5rem 2rem;
    }

    .home-footer-credit-policy {
        justify-content: flex-end;
        margin-top: 0 !important;
    }

    .home-footer-credit > p {
        font-size: 1rem;
        margin-right: 1rem;
    }

    .home-footer-credit-policy-img {
        width: 30px;
    }
}

@media only screen and (min-width: 1092px) {
    #modal-our-history-text {
        padding: 0 6rem;
        background-color: #000;
        color: #fff;
    }

    .home-description-title {
        font-size: 1.8rem;
    }

    .home-description-paragraph {
        font-size: 1rem;
    }

    .car-slider-button > p{
        font-size: 0.85rem !important;
    }

    .home-footer-image-apps {
        width: 10rem;
        margin: -0.3rem 0 2.5rem 3rem;
    }
    
    #modal-our-history-text h1{
        text-align: left;
    }
}


@media only screen and (min-width: 1214px) {
    #modal-our-history-text {
        padding: 0 8rem;
        background-color: #000;
        color: #fff;
    }
}

@media only screen and (max-height: 768px) {
    #modal-our-history-img-mobile .slick-slide {
        height: 9rem !important;
    }
    #modal-our-history-img-mobile .slick-slide * {
        height: 75vw !important;
    }
}

@media only screen and (max-height: 640px) {
    .our-history-mobile-close-container {
        margin-bottom: 1rem;
    }
}
.slider-banner-container{
    padding: 0 !important;
}

.expand-arrow{
    display: none;
}

.slider-banner{
    background-color: #F7F7F7;
    position: relative;
}

.slider-banner-container{
    width: 100%;
    height: 30vh;

    position: relative;
}

.slider-banner-image{
    width: 100%;
    height: 100%;

    background-size: cover;
    background-position: top;
}

.slider-banner-detail{
        position: absolute;
        top: 0;
        margin: 6rem 2rem 0 2rem;
        width: 100%;
}

.slider-banner-detail>.title{
    font-size: 0.85rem;
    letter-spacing: 4px;
    line-height: 1rem;
}

.slider-banner-detail>.description{
    font-size: 0.75rem;
    margin: 1rem 0 1.8rem 0;
    letter-spacing: 1px;
}

.slider-banner-detail>.title, .slider-banner-detail>.description, .slider-banner-scroll>p, 
.slider-banner-info>div>p{
    font-weight: 400;
    color: white;
}



.slider-banner-scroll{
    display: flex;
    position: absolute;

    bottom: 0;
    margin: 0 0 3% 7%;
    display: none;
}

.slider-banner-scroll>img{
    width: 15%;
    margin-right: 1rem;
}

.slider-banner-scroll>p{
    display: flex;
    align-items: center;

    font-size: 0.85rem;
}

.slider-banner-info>.show-room{
    background-color:#E5E5E5;
    width: 42%;

    display: flex;
    flex-flow: column wrap;
    justify-content: center;

    padding: 0 0 0 1.5rem;
}

.slider-banner-info img{
    width: 30px;
}

.need-help a, .show-room a{
    text-decoration: none;
    color: #000;
}

.slider-banner-info .need-help{
    background-color: #F7F7F7;
    width: 40%;

    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    
    padding: 0.5rem 0 0.5rem 1rem;
}

.slider-banner-info .need-help>img{
    width: 1.5rem;
    height: auto;
    object-fit: cover;
}

.slider-banner-info p, .slider-banner-info .media-social p{
    color: black;
    
    margin-bottom: 0;
    padding-top: 0.3rem;
    letter-spacing: 1px;
    font-size: 0.9rem;
    line-height: 1rem;
}
.slider-banner-info .media-social p{
    padding-left: 12px;
    display: none;
}

.slider-banner-info>.media-social{
    background-color: #FFFFFF;
    width: 15%;
}

.slider-banner-info>.media-social>.icon{
    padding-top: 0.2rem;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
}

.slider-banner-info>.media-social>.icon>img{
    width: 1.5rem;
    margin: 0rem 0 0.5rem;
}


.slider-banner-container .slick-dots {
    bottom: 20vh;
    margin: 0;
    list-style: none;
    text-align: center;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 6rem;
    height: 20vh;
    right: -1.1rem;
    overflow: hidden;
    top: 4rem;
}

.slider-banner-container ul {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.slider-dot-banner{
    background-color: #C1C1C1;
    width: 0.5rem;
    height: 0.5rem;
    display: flex;
    justify-content: flex-end;
}

.slick-active > .slider-dot-banner{
    background-color: #fff !important;
}

.slider-dot-banner-ul > li {
    position: relative;
    display: inline-block;
    width: 0.5rem !important;
    height: 0.5rem !important;
    margin: 1rem 0 !important;
    padding: 0;
    cursor: pointer;
}

@media only screen and (min-width: 768px) {
    .slider-banner-container .slick-dots {
        top: 6rem;
    }

    .slider-banner-info>.media-social {
        width: 18%;
        padding-left: 10px;
    }
    .slider-banner-info>.media-social>.icon {
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        padding: 0.4rem 0 0 10px;
    }
    .slider-banner-info .media-social p{
        display: block;
    }
}

@media only screen and (min-width: 992px) {
    .slider-banner-container .slick-dots {
        height: 55vh;
        right: 0;
    }

    .slider-banner-container{
        width: 100%;
        height: 100vh;
    
        position: relative;
    }

    .slider-banner-container{
        padding: 0 !important;
    }
    
    .slider-banner{
        background-color: #F7F7F7;
        position: relative;
    }
    
    .slider-banner-container{
        width: 100%;
        height: 100vh;
    
        position: relative;
    }
    
    .slider-banner-image{
        width: 100%;
        height: 100%;
    
        background-size: cover;
        background-position: center;
    }
    
    .slider-banner-detail{
        width: 70vw;
        position: absolute;
        top: 0;
        margin: 13rem 4rem 0 4rem;
    }
    
    .slider-banner-detail>.title{
        font-size: 2.2rem;
        letter-spacing: 4px;
        line-height: 3rem;
    }
    
    .slider-banner-detail>.description{
        font-size: 1rem;
        margin: 1rem 0 1.8rem 0;
        letter-spacing: 1px;
    }
    
    .slider-banner-detail>.title, .slider-banner-detail>.description, .slider-banner-scroll>p, 
    .slider-banner-info>div>p{
        font-weight: 400;
        color: white;
    }
    
    .slider-banner-scroll{
        display: flex;
        position: absolute;
    
        bottom: 0;
        margin: 0 0 3% 7%;
    }
    
    .slider-banner-scroll>img{
        width: 15%;
        margin-right: 1rem;
    }
    
    .slider-banner-scroll>p{
        display: flex;
        align-items: center;
    
        font-size: 0.85rem;
    }
    
    .slider-banner-info>.show-room{
        background-color:#E5E5E5;
        width: 40%;
    
        display: flex;
        flex-flow: row wrap;
        justify-content: left;
        align-items: center;
        
        /* padding: 0.4rem 0 0 0.5rem; */
        padding: 0 0 0 0;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }
    
    .slider-banner-info>.show-room>img{
        width: 1.5rem;
    }

    .slider-banner-info>.show-room>.expand-arrow{
        height: 100%;
        display: flex;
        width: 2rem;
        justify-content: center;
        align-items: center;
        margin-right: 1.5rem;
    }

    .slider-banner-info>.show-room>.expand-arrow>img{
        width: 0.5rem;
        height: 1rem;
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        transition: all 1s;
    }

    .slider-banner-info>.show-room>.expand-arrow.open>img{
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }

    .slider-banner-info>.show-room>.expand-arrow.close>img{
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
    }
    
    .slider-banner-info .need-help{
        background-color: #F7F7F7;
        width: 30%;
    
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        
        padding: 0.4rem 0 0 1rem;
    }
    
    .slider-banner-info .need-help>img{
        width: 1.5rem;
        height: auto;
        object-fit: cover;
    }
    
    .slider-banner-info>div>p{
        color: black;
        
        padding-top: 0.3rem;
        letter-spacing: 1px;
        font-size: 0.85rem;
        line-height: 1rem;
    }
    
    .slider-banner-info>.media-social{
        background-color: #FFFFFF;
        width: 30%;
    }
    
    .slider-banner-info>.media-social>.icon>img{
        width: 1.5rem;
        margin: 0rem 0 0.5rem;
    }
}


@media only screen and (min-width: 1092px) {
    .slider-banner-info>div>p{
        font-size: 0.6rem;
    }
    
    .slider-banner-scroll>img{
        width: 13%;
    }
    
    .slider-banner-scroll>p {
        font-size: 0.85rem;
    }
}

@media only screen and (min-width: 1200px) {

}


.car-slider-container {
    width: 100%;   
    margin-top: 5rem;
}

.car-slider-container > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.car-slider-container > .header > div > h2 {
    font-weight: 600;
    font-size: 1.3rem;;
    margin-bottom: 0.5rem;
    letter-spacing: 1px;
    line-height: 2.3rem;
}

.slider-car-container {
    width: 14rem !important;
    height: 12rem;
}

.slider-car-container > a > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.slider-car-container > a > h3 {
    font-weight: 400;
    font-size: 1rem;
    margin-top: 1rem;
    color: #454550;
    text-overflow: ellipsis; 
    white-space: nowrap; 
    overflow: hidden;
}

.slider-car-container > a {
    text-decoration: none;
}

.slider-car>.slick-list>.slick-track>.slick-slide{
    margin: 1rem 0.5rem 1rem 0.5rem;
}

.slider-car>.slick-list>.slick-track>.slick-slide:first-child{
    margin: 1rem 0.5rem 1rem 2rem;
}

.slider-car-next {
    top: -2.8rem;
    right: 0.8rem;
    color: #454550;
}

.slider-car-prev {
    top: -2.8rem;
    right: 3.5rem;
    color: #E5E5E5;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.slider-car-arrow {
    width: 2rem !important;
    height: 2rem;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    
    border: 1px solid rgb(229, 229, 229);
    border-radius: 50%;
    cursor: pointer;

    font-size: 0.85rem;
}

.slider-car-arrow > img {
    width: 0.7rem;
}

@media only screen and (min-width: 768px) {
    
}

@media only screen and (min-width: 992px) {
    .slider-car-container {
        width: 16rem !important;
        height: 12rem;
    }
    
    .slider-car>.slick-list>.slick-track>.slick-slide{
        margin: 1rem 0.8rem 1rem 0.8rem;
    }
    
    .slider-car>.slick-list>.slick-track>.slick-slide:first-child{
        margin: 1rem 0.8rem 1rem 2rem;
    }
}

@media only screen and (min-width: 1092px) {
    .slider-car-container {
        width: 19rem !important;
        height: 16rem;
    }
}
#modal-our-history-text .slick-list{
    width: 100%;
    overflow-y: scroll;
    max-height: 45vw;
    margin-bottom: 2rem;
}

.slider-our-history-container p{
    font-weight: 200;
}

#modal-our-history-row{
    position: relative;
}

#modal-our-history > .modal-content{
    background-color: transparent;
}

#modal-our-history-img .slick-slide * {
    width: 100%;
    height: 100%;
}

#modal-our-history-year{
    position: absolute;
    bottom: 0.8rem;
    padding: 0 10rem;
}

#modal-our-history-year .slick-slide > div > .modal-our-history-year-item > .dot{
    opacity: 0;
}

#modal-our-history-year .slick-current > div > .modal-our-history-year-item > .dot{
    opacity: 1;
}

#modal-our-history-year .slick-slide > div > .modal-our-history-year-item > .text-2{
    color: rgba(255, 255, 255, 0.9);
}

#modal-our-history-year .slick-current > div > .modal-our-history-year-item > .text-2{
    color: #fff;
}

#modal-our-history-line{
    position: absolute;
    bottom: 4rem;
}

#modal-our-history-line > img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.modal-our-history-year-item {
    display: flex !important;
    flex-direction: column;
    align-items: center;
}

.modal-our-history-year-item > .dot {
    height: 0.5rem;
    width: 0.5rem;
    background-color: #fff;
    margin-bottom: 1rem;
}

.service-center-image{
    padding: 2rem 0 0 0 !important;
}

.service-center-image > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.slider-our-history-arrow {
    width: 2.5rem !important;
    height: 2.5rem;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid rgb(229, 229, 229);
    border-radius: 50%;
    cursor: pointer;
    font-size: 0.85rem;
}

.slider-our-history-prev {
    top: 2.5rem;
    left: -6rem;
    color: #E5E5E5;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.slider-our-history-next {
    top: 2.5rem;
    right: -4.2rem;
    color: #454550;
}

.slider-our-history-arrow > img {
    width: 0.7rem;
}

.service-center-image .slick-dots {
    top: -0.3rem;
    margin: 0;
    list-style: none;
    text-align: center;
    width: 73% !important;
    display: flex;
    justify-content: flex-end;
}

.slider-dot-ul {
    width: 100%;
}

.service-center-image .slider-dot{
    background-color: #C1C1C1;
    width: 0.5rem;
    height: 0.5rem;
    display: flex;
    justify-content: flex-end;
}

.service-center-image ul{
    padding-left: 0 !important;
    padding-top: 0.6rem;
    display: flex;
    justify-content: flex-start;
    overflow: hidden;
}

.slick-active > .slider-dot{
    background-color: #000000 !important;
}

.slider-dot-ul > li{
    position: relative;
    display: inline-block;
    width: 0.5rem !important;
    height: 0.5rem !important;
    margin: 0 1rem !important;
    padding: 0;
    cursor: pointer;
}

.slider-dot-ul > li:first-child{
    margin: 0 1rem 0 0!important;
}

@media only screen and (min-width: 992px) {
    .slider-our-history-container, .slider-our-history-container > img{
        height: 12rem;
    }

    .slider-our-history-container, .slider-our-history-container > img{
        width: 100%;
        height: 25rem;
    }

    #modal-our-history-row{
        position: relative;
        display: flex;
    }

    #modal-our-history-img   * {
        width: 100%;
        height: 100%;
    }

    .service-center-image ul{
        justify-content: flex-end;
    }

    .slider-our-history-prev {
        top: 1.2rem;
        left: -5.2rem;
    }
    
    .slider-our-history-next {
        top: 1.2rem;
        right: -5.2rem;
    }

    .service-center-image .slick-dots {
        top: 0.7rem !important;
    }
}
.slider-our-history-mobile-container {
    width: 100%;
    height: 8rem;
}

.modal-our-history-img-mobile  {
    width: 100% !important;
    height: 15rem !important;
}

.modal-our-history-text .slick-list{
    max-height: 45vw;
    margin-bottom: 2rem;
}

#modal-our-history-row{
    position: relative;
}

#modal-our-history > .modal-content{
    background-color: transparent;
}

#modal-our-history-img-mobile .slick-slide {
    height: 20rem !important;
}

#modal-our-history-img-mobile .slick-slide * {
    width: auto !important;
    height: 80vw;
}

#modal-our-history-img-mobile .slick-slide>div {
    display: flex;
    justify-content: center;
}

#modal-our-history-year-mobile{
    position: absolute;
    bottom: -10rem;
    padding: 0;
}

#modal-our-history-year-mobile .slick-current{
    opacity: 1 !important;
}

#modal-our-history-year-mobile .slick-slide{
    opacity: 0;
}

#modal-our-history-year-mobile .slick-slide > div > .modal-our-history-year-mobile-item > .dot{
    opacity: 0;
}

#modal-our-history-year-mobile .slick-current > div > .modal-our-history-year-mobile-item > .dot{
    opacity: 1;
}

#modal-our-history-year-mobile .slick-slide > div > .modal-our-history-year-mobile-item > .text-2{
    color: rgba(255, 255, 255, 0.3);
}

#modal-our-history-year-mobile .slick-current > div > .modal-our-history-year-mobile-item > .text-2{
    color: #fff;
}

#modal-our-history-line{
    position: absolute;
    bottom: -6.9rem;
}

#modal-our-history-line > img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.modal-our-history-year-mobile-item {
    display: flex !important;
    flex-direction: column;
    align-items: center;
}

.modal-our-history-year-mobile-item > .dot {
    height: 0.5rem;
    width: 0.5rem;
    background-color: #fff;
    margin-bottom: 1rem;
}

.service-center-image{
    padding: 2rem 0 0 0 !important;
}

.service-center-image > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.slider-our-history-mobile-arrow {
    width: 2.5rem !important;
    height: 2.5rem;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(229, 229, 229);
    border-radius: 50%;
    cursor: pointer;
    font-size: 0.85rem;
}

.slider-our-history-mobile-prev {
    top: 141vw;
    left: 6.5rem;
    color: #E5E5E5;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    z-index: 15;
}

.slider-our-history-mobile-next {
    top: 141vw;
    right: 6.5rem;
    color: #454550;
    z-index: 15;
}

.slider-our-history-mobile-arrow > img {
    width: 0.7rem;
}

.service-center-image .slick-dots {
    top: -0.3rem;
    margin: 0;
    list-style: none;
    text-align: center;
    width: 73% !important;
    display: flex;
    justify-content: flex-end;
}

.slider-dot-ul {
    width: 100%;
}

.service-center-image .slider-dot{
    background-color: #C1C1C1;
    width: 0.5rem;
    height: 0.5rem;
    display: flex;
    justify-content: flex-end;
}

.service-center-image ul{
    padding-left: 0 !important;
    padding-top: 0.6rem;
    display: flex;
    justify-content: flex-start;
    overflow: hidden;
}

.slick-active > .slider-dot{
    background-color: #000000 !important;
}

.slider-dot-ul > li{
    position: relative;
    display: inline-block;
    width: 0.5rem !important;
    height: 0.5rem !important;
    margin: 0 1rem !important;
    padding: 0;
    cursor: pointer;
}

.slider-dot-ul > li:first-child{
    margin: 0 1rem 0 0!important;
}

@media only screen and (min-width: 768px) {
    #modal-our-history-img-mobile .slick-slide {
        height: -webkit-fit-content !important;
        height: -moz-fit-content !important;
        height: fit-content !important;
    }
    #modal-our-history-img-mobile .slick-slide * {
        height: 55vw !important;
    }
    #modal-our-history-text .slick-list {
        max-height: 35vw;
    }
    #modal-our-history-line {
        position: absolute;
        bottom: -9.9rem;
    }
    #modal-our-history-year-mobile {
        bottom: -13rem;
    }
    .slider-our-history-mobile-next {
        top: 85vw;
    }
    .slider-our-history-mobile-prev {
        top: 85vw;
    }
}

@media only screen and (min-width: 992px) {
    #modal-our-history-text .slick-list {
        overflow-y: hidden;
    }
    #modal-our-history-img-mobile .slick-slide {
        height: 31rem !important;
    }

    .slider-our-history-mobile-prev {
        top: 2.5rem;
        left: -6rem;
        color: #E5E5E5;
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
    }
    
    .slider-our-history-mobile-next {
        top: 2.5rem;
        right: -6rem;
        color: #454550;
    }

    #modal-our-history-year-mobile{
        position: absolute;
        bottom: 0.8rem;
        padding: 0 10rem;
    }

    #modal-our-history-line{
        position: absolute;
        bottom: 4rem;
    }

    .slider-our-history-mobile-container, .slider-our-history-mobile-container > img{
        width: 100%;
        height: 25rem;
    }

    .service-center-image ul{
        justify-content: flex-end;
    }

    .slider-our-history-mobile-prev {
        top: 1.2rem;
        right: 4.5rem;
    }
    
    .slider-our-history-mobile-next {
        top: 1.2rem;
        right: 0.8rem;
    }
}

@media only screen and (max-height: 768px) {
    .our-history-mobile-close-container {
        margin-bottom: 1rem !important;
    }
    #modal-our-history-img-mobile .slick-slide {
        height: -webkit-fit-content !important;
        height: -moz-fit-content !important;
        height: fit-content !important;
    }
    #modal-our-history-img-mobile .slick-slide * {
        height: 75vw !important;
    }
    #modal-our-history-text .slick-list {
        max-height: 35vw !important;
    }
    #modal-our-history-line {
        position: absolute !important;
        bottom: -5.9rem !important;
    }
    #modal-our-history-year-mobile {
        bottom: -9.5rem !important;
    }
    .slider-our-history-mobile-next {
        top: 122vw !important;
    }
    .slider-our-history-mobile-prev {
        top: 122vw !important;
    }
}
.membership-container{
    padding-bottom: 2rem !important;
}

.membership>h1{
    font-size: 1.3rem;
    line-height: 1.8rem;
    font-weight: 600;
    margin: 9.5rem 0 2.5rem 0;
}

.membership-level > h2{
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 1.2rem;
}

.member-card{
    width: 100%;
}

.accordion{
    border-width: 0 !important;

    --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem transparent !important;
}

.accordion-body{
    padding: 0 1rem 0 0.5rem !important;
}

.accordion-button:not(.collapsed){
    background-color: transparent !important;
    color: #000 !important;
}

.accordion-button:not(.collapsed)::after{
    background-image: var(--bs-accordion-btn-icon) !important;
}

.accordion-button:focus{
    border: 0;
}

.accordion-button:after{
    margin-right: 1rem;
}

/* .accordion-button,  */
.accordion-button.collapsed{
    /* display: block !important;
    cursor: context-menu !important; */
}

.accordion-button > p {
    font-size: 0.85rem;
    font-weight: 600;
    text-align: left;
    padding-left: 1.2rem;
    margin: 0;
}

.member-benefit{
    margin: 0;
    padding: 0 0 0.1rem 1.8rem;
}

.member-benefit>li {
    font-size: 0.85rem;
    font-weight: 400;
    margin-bottom: 1.2rem;

    content:"\B7";
    vertical-align:middle;
}

.member-benefit>li::marker{
    font-size: 1.2rem;
}

.member-benefit>li>p{
    margin-bottom: -0.3rem;
}

.accordion-item, .accordion-button{
    background-color: #E5E5E5 !important;
    border: 0px !important;
}

.accordion-button{
    padding: 1.3rem 0 1rem 0 !important;
}

.accordion-item{
    /* border: 1px solid #E5E5E5 !important; */
    margin: 2rem 0;
}

.membership-faq > h1, .membership-faq-title > button > p, .membership-faq-description{
    font-size: 0.9rem;
}

.membership-faq > h1, .membership-faq-title > button > p{
    font-size: 0.9rem;
    line-height: 1.8rem;
    font-weight: 600;
}

.membership-faq-title > button{
    background-color: transparent !important;
}

.membership-faq > h1{
    margin: 2rem 0 2rem 0;
    text-align: center;
}

.membership-faq-title-container{
    margin: 1rem 0;
    /* border-bottom: 1px solid #E5E5E5 !important; */
    background-color: #F7F7F7 !important;
}

.membership-faq-title-flush{
    border: 0px !important;
}

.membership-faq-title > button > p, .membership-faq-description{
    color:#454550;
}

.membership-faq-title > button > p{
    padding-left: 0.5rem;
}

.membership-faq-container {
    background-color: #F7F7F7 !important;
    padding: 0 1rem 7rem 1rem !important;
}

@media only screen and (min-width: 768px) {
    
}

@media only screen and (min-width: 992px) {
    .accordion-button:after{
        margin-right: 1rem;
    }
    
    .accordion-item{
        margin-top: 0rem;
    }

    .accordion-button > p {
        text-align: center;
        padding-left: 0;
    }

    .member-type > button::after {
        display: none;
    }

    .member-type > button {
        justify-content: center;
        cursor: context-menu !important;
    }

    .member-type-card > .collapse:not(.show) {
        display: block !important;
    }
    
    .member-card-row{
        margin-bottom: 4rem;
    }

    .member-card-accordion, .accordion-item, #member-type-card{
        margin: 0 !important;
    }

    .member-card-accordion > *, .member-card-accordion {
        height: 100%;
    }

    .member-type-title{
        width: 100%;
    }
}

@media only screen and (min-width: 1200px) {
}
.new-car-banner > div > div{
    padding: 0;
}

.new-car-banner > div > div > img{
    width: 100%;
}

.new-car-banner > div > div > .desktop{
    display: none;
}

.new-car-title > h1{
    font-size: 1.3rem;
    line-height: 1.8rem;
    font-weight: 600;
    margin: 2rem 0 2.5rem 0;
}

.new-car-card a{
    text-decoration: none;
}

.new-car-card img{
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.new-car-card h2{
    color: #454550;
    font-size: 0.85rem;
    line-height: 1.8rem;
    font-weight: 400;
    margin: 1rem 0 2rem 0;
    
    height: 1.9em;
    overflow: hidden; 
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

@media only screen and (min-width: 768px) {
    
}

@media only screen and (min-width: 992px) {
    .new-car-banner > div > div > img.desktop{
        display: block;
    }
    .new-car-banner > div > div > img.mobile{
        display: none;
    }
}

@media only screen and (min-width: 1200px) {
}
  .btn {
    padding: 8px 20px;
    border-radius: 4px;
    outline: none;
    border: none;
    font-size: 18px;
    color: #fff;
    cursor: pointer;
    background-color: var(--primary);
  }
  
  .btn:hover {
    padding: 6px 18px;
    transition: all 0.3s ease-out;
    background-color: transparent;
    color: #fff;
    border-radius: 4px;
    border: 2px solid var(--primary);
    transition: all 0.3s ease-out;
  }

  .car-card-skeleton{
    margin-bottom: 4rem !important;
  }

  .car-card-skeleton-img{
    height: 16.5vw;
  }

  .car-card-skeleton-title{
    margin-top: 1rem !important;
  }
  
  .car-card-img{
    padding: 0;
    margin: 0;
    width: 100%;
    height: 14rem;
  }

  .car-card-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  @media only screen and (min-width: 600px) {
    .car-card-img{
      padding: 0;
      margin: 0;
      width: 100%;
      height: 30rem;
    }
  }

  @media only screen and (min-width: 992px) {
    .car-card-img{
      padding: 0;
      margin: 0;
      width: 100%;
      height: 22.5vw;
    }
  }
.used-car-banner > div > div{
    padding: 0;
}

.used-car-banner > div > div > img{
    width: 100%;
}

.used-car-banner > div > div > .desktop{
    display: none;
}

.used-car-title > h1{
    font-size: 1.3rem;
    line-height: 1.8rem;
    font-weight: 600;
    margin: 2rem 0 2.5rem 0;
}

.used-car-card > div > a{
    text-decoration: none;
}

.used-car-card > div > a > img{
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.used-car-card > div > a > div > h2{
    color: #454550;
    font-size: 0.85rem;
    line-height: 1.8rem;
    font-weight: 400;
    margin: 1rem 0 2rem 0;
    
    height: 1.9em;
    overflow: hidden; 
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

@media only screen and (min-width: 768px) {
    
}

@media only screen and (min-width: 992px) {
    .used-car-banner > div > div > img.desktop{
        display: block;
    }
    .used-car-banner > div > div > img.mobile{
        display: none;
    }
}

@media only screen and (min-width: 1200px) {
}
.car-detail-desktop{
    display: none;
}
.car-detail-back {
    margin-top: 7rem !important;
    display: flex;
}

.carousel-control-next-icon, .carousel-control-prev-icon{
    color: #fff;
}

.car-detail-back > p {
    margin-bottom: 0.2rem;
}

.car-detail-back > p > a {
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: 0.85rem;
    color: #454550;
    margin: 0.2rem 0 0 0;
}

.car-detail-back > p > a > img {
    width: 0.35rem;
    margin-right: 0.6rem;
}

.car-detail-title {
    font-size: 1.3rem;;
    font-weight: 600;
    margin: 0.5rem 0 0rem 0;
}

.tda-see-all-images{
    cursor: pointer;
}

#car-detail-carousel{
    width: 32rem;
}

.car-detail-image-container{
    padding: 0 !important;
}

.car-detail-image-row{
    margin-top: 0.25rem !important;
}

.car-detail-image img{
    width: 100%;
    height: 19rem;
    object-fit: cover;
    object-position: center;
}

.car-detail-image img:hover{
    cursor: pointer;
}

.car-detail-price-row{
    margin: 2rem 0 1.1rem 0 !important;
}

.car-detail-price > p, .car-detail-description *, .car-detail-description > div, .car-detail-description {
    color: #454550;
    margin: 0;
    font-size: 0.85rem;
}

.car-detail-price > p:nth-child(2) {
    font-size: 1.1rem;
    font-weight: 600;
}

.car-detail-form{
    background-color: #E5E5E5;
    padding: 0 0.8rem;
}

.car-detail-title > h1, .car-detail-title > h2{
    font-size: 1.3rem;;
    line-height: 1.8rem;
    font-weight: 600;
    margin: 2rem 0 2.5rem 0;

    text-align: center;
}

.car-detail-specification-row{
    margin-top: 4rem !important;
    border: 1px solid #E5E5E5;
    padding: 0 1.2rem;
    margin-bottom: 5rem;
}

.car-detail-specification{
    display: flex;
    justify-content: space-between;
    padding: 1rem 0 1rem 0 !important;
    
    border-bottom: 1px solid #E5E5E5;
}

.car-detail-title > div{
    margin: 0;
    font-size: 0.9rem;
    color: #454550;
}

.car-detail-descriptions-row{
    margin-top: 4rem !important;
    border: 1px solid #E5E5E5;
    padding: 0 1.2rem 2rem 1.2rem;
    margin-bottom: 5rem;
}

.car-detail-descriptions{
    padding: 0 !important;
}

.car-detail-media-second{
    width: 100%;
}

.car-detail-media-social{
    display: none;
    padding-left: 0.2rem;
}

.car-detail-media-social > p{
    color: #454550;
    font-size: 0.85rem;
    font-weight: 400;
    padding: 0.3rem 0.1rem 0 0;
}

.car-detail-media-social > img{
    width: 1.5rem;
    height: 1.5rem;
    margin: 0.1rem 0 0 0.5rem;
}

.car-detail-image img {
    width: 100%;
    height: 18rem;
    margin-bottom: 1rem;

    object-fit: cover;
    object-position: center;
}


.car-detail-specification > p:nth-child(2) {
    margin-top: -0.2rem;
}

.car-detail-share {
    text-align: right;
    padding: 0 !important;
}

.car-detail-share > img{
    width: 2.5rem;
    padding: 0.6rem;
    background-color: #000;
}

.car-detail-description{
    padding: 2rem 0 3.5rem 0;
}

.car-detail-card-container{
    background-color:#F7F7F7;
    padding: 4.5rem 0 2.5rem 0;
}

.car-detail-accordion-col img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.car-detail-image-collection img,
.leasing-image-container img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.car-detail-image-container{
    display: none !important;
}

.slider-detail-car img, .slider-detail-car{
    height: 80vh;
}

.slider-car-detail-navigation img{
    height: 4rem;
}

#modal-see-all-image .slick-dots{
    bottom: -10vh;
}

#modal-see-all-image .modal-content{
    height: 120vw;
}

#modal-see-all-image{
    background-color: transparent;
    display: flex;
    align-items: center;
}

.slider-detail-car img, .slider-detail-car{
    width: 100%;
    height: auto;
}

.slick-dots li{
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
    opacity: 0.5;
}

.slick-dots ul{
    height: 80vh;
    overflow: hidden;
    justify-content: center;
}

.slick-dots li.slick-active{
    opacity: 1;
}

.slider-car-detail-page-arrow {
    width: 2.5rem !important;
    height: 2.5rem;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(229, 229, 229);
    border-radius: 50%;
    cursor: pointer;
    font-size: 0.85rem;
}

.slider-car-detail-page-prev {
    top: 85vw;
    left: 0;
    color: #E5E5E5;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.slider-car-detail-page-next {
    top: 85vw;
    right: 0;
    color: #454550;
}

.slider-car-detail-page-arrow > img {
    width: 0.7rem;
}

.container-slider-arrow-right, .container-slider-arrow-left{
    height: 100%;
}

.container-slider-arrow-left{
    left: 10%;
    z-index: 99999;
}

.container-slider-arrow-right{
    right: 10%;
    z-index: 99999;
}

.car-detail-share-select img{
    width: 9vw;
    margin: 0 0.5rem;
}

#modal-share .modal-content{
    height: 20vh;
}

#modal-share{
    display: flex;
    align-items: center;
}

@media only screen and (min-width: 768px) {
    .slider-car-detail-page-prev {
        top: 20vh;
    }
    
    .slider-car-detail-page-next {
        top: 20vh;
    }

    #modal-see-all-image .modal-content{
        height: 85vw;
    }
}

@media only screen and (min-width: 992px) {
    .slick-dots ul{
        height: 80vh;
    }

    .slider-car-detail-page-prev {
        top: 35vh;
        left: 0;
        color: #E5E5E5;
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
    }
    
    .slider-car-detail-page-next {
        top: 35vh;
        right: 0;
        color: #454550;
    }

    #modal-see-all-image .modal-content{
        height: 100vh;
    }

    .slider-detail-car img, .slider-detail-car{
        height: 80vh;
        width: auto;
    }

    #modal-see-all-image{
        height: 100%;
    }

    .car-detail-image-container{
        display: flex !important;
    }

    .leasing-image-container{
        width: 100%;
        height: 15rem;
        margin-bottom: 1rem;
    }

    .car-detail-mobile{
        display: none;
    }

    .car-detail-desktop{
        display: block;
    }

    .car-detail-media-social{
        padding-top: 0.2rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .car-detail-media-social-icon-collection{
        height: 100%;
        padding-top: 0.2rem;
        display: flex;
    }

    .car-detail-media-social > p{
        color: #454550;
        font-size: 0.75rem;
        font-weight: 400;
        padding: 0rem 0rem 0 0;
    }

    .car-detail-media-social img {
        width: 1.5rem;
        height: 1.5rem;
        margin: 0 0 0 0.5rem;
    }

    .car-detail-button-whatsapp{
        display: flex;
        border: 1px solid;
        margin: 0 1.5rem 1rem 0.5rem;
        justify-content: center;
        align-items: center;
    }

    .car-detail-button-whatsapp a{
        padding: 0.3rem 1rem;
        display: flex;
    }

    .car-detail-button-whatsapp a, .car-detail-button-whatsapp a:hover{
        text-decoration: none;
        color: #000;
        width: 100%;
    }

    .car-detail-button-whatsapp p{
        font-size: 0.75rem;
        margin-bottom: 0 !important;
        margin-left: 0.5rem;
        margin-top: 0.2rem;
    }

    .car-detail-button-whatsapp img{
        width: 1.5rem;
        height: 1.5rem;
    }

    .car-detail-share {
        display: none;
    }

    .car-detail-specification {
        padding: 2rem 0 0 0;
    }

    .car-detail-field > label {
        font-size: 0.85rem;
        color: #454550;
        font-weight: 600;
    }

    .car-detail-accordion-col{
        padding: 0 !important;
        margin: 1.5rem 0;
    }

    .car-detail-accordion-col .accordion-button{
        padding: 1rem 0 0.7rem 0 !important;
    }

    .car-detail-accordion-col *{
        background-color: transparent !important;
    }

    .car-detail-accordion-col{
        border: 1px solid;
    }

    .car-detail-accordion-card > h2 > .accordion-button.collapsed {
        box-shadow: none !important;
    }

    .car-detail-accordion-card > h2 > .accordion-button > p {
        text-align: left !important;
        padding-left: 2rem;
        font-size: 0.85rem;
        font-weight: 600;
    }

    .car-detail-accordion-card-p{
        font-size: 0.85rem;
        font-weight: 400;
        color: #454550;
        text-align: center;
    }

    .car-detail-accordion-card-table *{
        font-size: 0.85rem;
        font-weight: 400;
        color: #454550;
    }

    .car-detail-accordion-body {
        padding: 0 1.5rem !important;
    }

    .car-detail-accordion-card-table-background-grey{
        background-color: #F7F7F7 !important;
        margin: 0.2rem 0;
        padding: 0.5rem 0;
    }

    .car-detail-accordion-card-table td {
        padding: 0 !important;
    }

    .car-detail-accordion-card-table-td-padding{
        padding: 0.5rem !important;
    }

    .car-detail-desktop-right{
        padding-left: 2rem !important;
    }
    
    .car-detail-image img {
        width: 100%;
        height: 37vw;
        margin-bottom: 0.5rem;

        object-fit: cover;
        object-position: center;
    }

    .car-detail-back{
        padding: 0 !important;
    }

    .car-detail-title {
        font-size: 1.5rem;
        color: #454550;
    }

    .car-detail-title{
        padding: 0 !important;
    }

    .car-detail-image-collection{
        height: 8.6vw;
    }

    .car-detail-row > div{
        padding: 0 .4rem !important;
    }
    
    .car-detail-youtube{
        padding: 0 !important;
    }

    .car-detail-form-title > h2{
        font-size: 1.1rem;
        line-height: 1.8rem;
        font-weight: 600;
        margin: 2rem 0 2rem 0;
        text-align: center;
    }

    .car-detail-price{
        padding: 0 !important;
        margin-bottom: 1.5rem !important;
    }

    .car-detail-price > p{
        margin: 0 !important;
    }

    #iframe-detail-car{
        width: 100%;
        margin-top: 0.8rem;
        height: 15rem;
    }

    .car-detail-specification-row{
        margin: 2rem -5px 0 -5px !important;
    }

    .car-detail-see-all {
        padding: 0 !important;
        font-weight: 600;
        color: #000;
        position: absolute;
        width: 91%;
        height: 100%;
        top: 0;
        margin: 0 !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .car-detail-see-all:hover{
        cursor: pointer;
    }

    .car-detail-see-all p{
        margin-bottom: 0 !important;
        background-color: #fff;
        padding: 0.2rem 0.3rem;
        font-weight: 600;
        font-size: 0.5rem;
    }

    .car-detail-image-collection-last{
        position: relative;
    }
}
@media only screen and (max-height: 444px) {
    #modal-see-all-image{
        width: 100vw !important;
    }

    #modal-see-all-image .modal-content{
        height: 100vh !important;
    }

    .slider-detail-car img{
        width: 38vw !important;
    }

    .slider-car-detail-navigation img{
        height: 15vh !important;
    }

    .slider-car-detail-page-prev {
        top: 10vw;
    }
    
    .slider-car-detail-page-next {
        top: 10vw;
    }
}
.form-text-area-title, .form-text-area{
    border-radius: 0px !important;
    font-size: 0.65rem;
    font-weight: 600;
    font-size: 0.8rem;
}

.form-text-area{
    padding: 1rem 0 1rem 1rem !important;
    margin: 0.2rem 0 2rem 0;
    color: #454550 !important;
}

.form-text-area:focus{
    outline: none !important;
    box-shadow: none !important;
    border: 1px solid #454550 !important;
}

.form-text-area::-webkit-input-placeholder{
    color: #C1C1C1 !important;
}

.form-text-area:-ms-input-placeholder{
    color: #C1C1C1 !important;
}

.form-text-area::placeholder{
    color: #C1C1C1 !important;
}

@media only screen and (min-width: 768px) {
    
}

@media only screen and (min-width: 992px) {
    .form-text-area{
        font-size: 0.85rem !important;
    }
    
}

@media only screen and (min-width: 1200px) {
}
.special-request-banner > div > div{
    padding: 0;
}

.special-request-banner > div > div > img{
    width: 100%;
}

.special-request-banner > div > div > .desktop{
    display: none;
}

.special-request-title > h1, .special-request-title > h2{
    font-size: 1.2rem;
    line-height: 1.8rem;
    font-weight: 600;
    margin: 2rem 0 0 0;

    text-align: center;
}

.special-request-card > div > a > img{
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.special-request-form{
    padding: 0 1rem;
    border: 1px solid #E5E5E5;
    margin-top: -4rem !important;
    background-color: #FFF;
    z-index: 0;
    position: relative;
    margin-bottom: 6rem;
}

.special-request-button {
    display: block;
    width: 60%;
    margin: 7rem 0 4rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.special-request-button > p {
    font-size: 0.85rem;
    font-weight: 600;
}

@media only screen and (min-width: 768px) {
    
}

@media only screen and (min-width: 992px) {
    .special-request-banner > div > div > img.desktop{
        display: block;
    }
    .special-request-banner > div > div > img.mobile{
        display: none;
    }

    .special-request-form{
        padding: 0 2rem;
    }

    .special-request-button{
        margin: 1rem 0 2.5rem 0;
    }
}

@media only screen and (min-width: 1200px) {
}
.special-request-banner > div > div{
    padding: 0;
}

.special-request-banner > div > div > img{
    width: 100%;
}

.special-request-banner > div > div > .desktop{
    display: none;
}

.special-request-title > h1{
    font-size: 1.5rem;
    line-height: 1.8rem;
    font-weight: 600;
    margin: 2rem 0 0.5rem 0;

    text-align: center;
}

.special-request-title > p{
    margin-bottom: 2rem;
}

.special-request-card > div > a > img{
    width: 100%;
    object-fit: cover;
    object-position: center;
}


.sell-images-collection .button-image-form{
    margin-bottom: 2rem;
}

.sell-image-item{
    margin-bottom: 2rem;
}

#modal-close-info .modal-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

#modal-close-info .modal-title > p{
    margin: 0;
}

@media only screen and (min-width: 768px) {
    
}

@media only screen and (min-width: 992px) {
    .special-request-banner > div > div > img.desktop{
        display: block;
    }
    .special-request-banner > div > div > img.mobile{
        display: none;
    }

    .special-request-form{
        padding: 0 2rem;
    }

    .sell-images-collection .button-image-form{
        height: 4.5rem !important;
        max-width: 4.5rem;
        margin-bottom: 0rem;
    }
}

@media only screen and (min-width: 1200px) {
}
.news-container{
    margin-top: 7rem;
}

.news-title > h1{
    font-size: 1.3rem;
    line-height: 1.8rem;
    font-weight: 600;
    margin: 0 0 2rem 0;
}

.news-card{
    margin-bottom: 2rem;
}

.news-card > a > img {
    width: 100%;
    height: 50%;
    object-fit: cover;
    object-position: center;
}
    
.news-card > a > div {
    padding: 1.4rem 1.2rem;
    border: 1px solid #E5E5E5;

    background-color: #FFF;
}

.news-card > a > div >  p {
    color: #C1C1C1;
    font-size: 0.85rem;
    line-height: 1rem;
    font-weight: 400;
    margin: 0;
}

.news-card > a > div > h2 {
    color: #454550;
    font-size: 0.85em;
    line-height: 1.1rem;
    height: 3rem;
    overflow: hidden; 
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-weight: 400;
    padding: 0.8rem 0 2rem 0;
}

.news-card > a{
    text-decoration: none;
    margin-bottom: 2.5rem;
    background: #FFF;
}
.btn {
  padding: 8px 20px;
  border-radius: 4px;
  outline: none;
  border: none;
  font-size: 18px;
  color: #fff;
  cursor: pointer;
  background-color: var(--primary);
}

.btn:hover {
  padding: 6px 18px;
  transition: all 0.3s ease-out;
  background-color: transparent;
  color: #fff;
  border-radius: 4px;
  border: 2px solid var(--primary);
  transition: all 0.3s ease-out;
}

.card-discover-img{
    width: 100%;
    height: 9rem;
    padding: 0 !important;
}

.card-discover-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.news-back {
    margin-top: 7rem !important;
}

.news-back a, .news-back a:hover {
    text-decoration: none;
    font-size: 0.85rem;
    color: #454550;
}

.news-back p {
    margin-bottom: 0;
}

.news-back *, .news-back{
    display: flex;
    align-items: center;
}

.news-detail-title p {
    font-size: 1.3rem;
    line-height: 2.2rem;
    font-weight: 600;
    margin: 0.3rem 0 1.5rem 0;
}

.events-detail-share{
    display: block;
}

.news-detail-media-social{
    display: none;
    padding-left: 0.2rem;
}

.news-detail-media-social p{
    color: #454550;
    font-size: 0.85rem;
    font-weight: 400;
    padding: 0.3rem 0.1rem 0 0;
}

.news-detail-media-social img{
    width: 1.5rem;
    height: 1.5rem;
    margin: 0.1rem 0 0 0.5rem;
}

.news-detail-image {
    width: 100%;
    height: 18rem;
}

.news-detail-image img {
    width: 100%;
    height: 100%;
    margin-bottom: 1rem;

    object-fit: cover;
    object-position: center;
}

.news-detail-image .carousel-indicators, .news-detail-image .carousel-control-next, 
.news-detail-image .carousel-control-prev{
    display: none;
}

.news-detail-specification {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 1.5rem !important;
}

.news-detail-specification, .news-detail-share {
    margin-top: 0rem !important;
}

.news-detail-specification > p {
    display: none;
}

.news-detail-specification > p, .news-detail-description {
    margin: 0;
    font-size: 0.85rem;
    color: #454550;
}

.news-detail-description *,.news-detail-description{
    text-align: justify;
}

.news-detail-specification > p:nth-child(2) {
    margin-top: -0.2rem;
}

.news-detail-share {
    text-align: right;
}

.news-detail-share img{
    width: 2.5rem;
    padding: 0.6rem;
    background-color: #000;
}

.news-detail-description{
    padding: 2rem 0 3.5rem 0;
}

.news-detail-card-container{
    background-color:#F7F7F7;
    padding: 4.5rem 0 2.5rem 0;
}

.car-detail-news-collection{
    background-color: #F7F7F7;
    padding-top: 4rem !important;
}

@media only screen and (min-width: 768px) {
    
}

@media only screen and (min-width: 992px) {
    .events-detail-description{
        padding: 0 1rem 1.5rem 6.5rem !important;
    }

    .events-detail-share{
        display: none;
    }

    .news-detail-media-social{
        padding-top: 0.2rem;
        display: flex;
        justify-content: flex-end;
    }

    .news-detail-share {
        display: none;
    }

    .news-detail-specification {
        justify-content: start;
        padding: 2rem 0 0 0;
    }

    .news-detail-image {
        height: 23rem;
    }
}

@media only screen and (min-width: 1200px) {
}
.events-title > h1{
    font-size: 1.3rem;
    line-height: 1.8rem;
    font-weight: 600;
    margin: 7rem 0 2.5rem 0;
}

.events-card > a > div > img {
    width: 100%;
    object-fit: cover;
    object-position: center;
}
    
.events-card > a > div > div {
    padding: 1.4rem 1.2rem;
    border: 1px solid #E5E5E5;
}

.events-card > a > div > div >  p {
    color: #C1C1C1;
    font-size: 1rem;
    line-height: 1rem;
    font-weight: 400;
    margin: 0;
}

.events-card > a > div > div > h2 {
    color: #454550;
    font-size: 0.85em;
    line-height: 1.8rem;
    height: 3.8em;
    overflow: hidden; 
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-weight: 400;
    padding: 0.8rem 0 2rem 0;
}

.events-card > a{
    text-decoration: none;
    margin-bottom: 2.5rem;
}
.titilium-container *{
    font-family: 'Titillium Web', sans-serif !important;
}

.events-detail-back {
    margin-top: 7rem !important;
    display: flex;
}

.events-detail-back a, .events-detail-back a:hover {
    text-decoration: none;
    font-size: 0.85rem;
    color: #454550;
}

.events-detail-back  p {
    margin-bottom: 0;
}

.events-detail-back  *, .events-detail-back {
    display: flex;
    align-items: center;
}

.events-detail-title > p {
    font-size: 2.2rem;
    line-height: 2.2rem;
    font-weight: 600;
    margin: 0 0 2rem 0;
}

.events-detail-image {
    width: 100%;
    height: 18rem;
    margin-bottom: 2rem;
}

.events-detail-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    margin-bottom: 2rem;
}

.events-detail-image .carousel-indicators, .events-detail-image .carousel-control-next, 
.events-detail-image .carousel-control-prev{
    display: none;
}

.events-detail-specification {
    display: flex;
    color: #454550;
}

.events-detail-specification > img {
    width: 1.5rem;
    height: 1.5rem;
}

.icon-casual {
    padding: 0 0.4rem 0 0.3rem;
    margin-bottom: 1rem;
}

.events-detail-specification > p, .events-detail-description > p, .events-detail-specification-maps{
    margin: 0;
    font-size: 0.85rem;
    color: #454550;
}

.events-detail-specification > p:nth-child(2) {
    margin-top: -0.2rem;
}

.events-detail-specification-maps > p:nth-child(1){
    font-weight: 600;
}

.events-detail-specification-maps > p:nth-child(2){
    font-weight: 400;
    margin-top: -0.8rem;
}

.events-detail-share {
    text-align: right;
}

.events-detail-share img{
    width: 2.5rem;
    padding: 0.6rem;
    background-color: #000;
}

.events-detail-description{
    padding: 0 0 1.5rem 0;
}

.events-detail-description *{
    text-align: justify;
}

.events-detail-description > p > img {
    margin: 1.5rem 0;
}

.events-detail-description > p > img {
    width: 100%;
}

.events-detail-card-container{
    background-color:#F7F7F7;
    padding: 4.5rem 0 2.5rem 0;
}

.events-detail-maps > div{
    margin-bottom: 2rem;
    margin-top: 1.5rem;
}

.events-detail-maps > div > img{
    width: 100%;
}

.events-detail-content{
    margin-bottom: 2rem;
}

#iframeId{
    height: 20rem;
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
}

@media only screen and (min-width: 768px) {
    
}

@media only screen and (min-width: 992px) {
    
    .events-detail-description{
        padding: 0 1rem 1.5rem 6.5rem !important;
    }

    #iframeId {
        height: 15rem;
    }

    .events-detail-share img{
        display: none;
    }

    .icon-casual {
        padding: 0 0.6rem 0 0.3rem;
        margin-bottom: 1rem;
    }

    .events-detail-specification-casual{
        padding-top: 0.8rem;
    }

    .icon-maps{
        padding: 0 0.35rem 0.35rem 0.1rem;
    }

    .events-detail-specification-maps > p, .events-detail-specification > p {
        padding-left: 0.4rem;
    }

    .events-detail-image {
        height: 75vh;
    }
}

@media only screen and (min-width: 1200px) {
}
.magazine-title h1{
    font-size: 1.3rem;
    line-height: 1.8rem;
    font-weight: 600;
    margin: 7rem 0 2.5rem 0;
}

.magazine-card{
    margin-bottom: 2rem;
}

.magazine-card-img{
    width: 100%;
    height: 20rem;
}

.magazine-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
    
.magazine-card > a > div > div {
    padding: 1rem 0;
}

.magazine-card h2 {
    color: #C1C1C1;
    font-size: 0.85em;
    line-height: 1.8rem;
    height: 3.8em;
    overflow: hidden; 
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-weight: 400;
}

.magazine-card a{
    text-decoration: none;
    margin-bottom: 2.5rem;
}
.btn {
  padding: 8px 20px;
  border-radius: 4px;
  outline: none;
  border: none;
  font-size: 18px;
  color: #fff;
  cursor: pointer;
  background-color: var(--primary);
}

.btn:hover {
  padding: 6px 18px;
  transition: all 0.3s ease-out;
  background-color: transparent;
  color: #fff;
  border-radius: 4px;
  border: 2px solid var(--primary);
  transition: all 0.3s ease-out;
}
.service-center-banner > div > div{
    padding: 0;
}

.service-center-banner > div > div > img{
    width: 100%;
}

.service-center-banner > div > div > .desktop{
    display: none;
}

.service-center-background-bottom {
    padding: 0 !important;
}

.service-center-background-bottom{
    background-color: #F7F7F7;
}

.service-center-background-content{
    padding: 0 0 2rem 0;
}

.service-center-title > h1, .service-center-maps > h2,
.service-center-address > h2{
    font-size: 1.5rem;
    line-height: 1.8rem;
    font-weight: 600;
    margin: 2rem 0 0.5rem 0;
}

.service-center-address > h2{
    color: #454550;
}


.service-center-title > h1{
    text-align: center;
    font-size: 1.3rem;
    margin-bottom: 2.5rem;
}

.service-center-card > div > a > img{
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.service-center-form{
    padding: 0 1rem 1.5rem 1rem;
    border: 1px solid #E5E5E5;
    margin-top: -4rem !important;
    margin-bottom: 2.5rem;
    background-color: #FFF;
    z-index: 0;
    position: relative;
}

.service-center-button {
    display: block;
    width: 100%;
    margin: 7rem 0 0.5rem 0;
}

.service-center-button p {
    font-size: 0.85rem;
    line-height: 1.8rem;
    font-weight: 600;
}

.service-center-contact-us {
    text-align: center;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
}

.service-center-address h3, .service-center-description p,
.service-center-address p, .home-telp{
    color: #454550;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 0;
}

.home-telp-header{
    font-weight: 600;
}

.service-center-address p {
    font-size: 1rem;
    text-align: justify;
}

.service-center-address > h3{
    margin-top: 1.2rem;
    letter-spacing: 2px;
    font-weight: 600;
}

.service-center-description > p{
    padding: 2.5rem 0 1.5rem 0;
    text-align: justify;
}

.service-center-image{
    padding: 2rem 1.8rem;
}

.service-center-image > img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.service-center-maps > div > img {
    width: 100%;
}

.service-center-button-whatsapp{
    display: flex;
    width: 16rem;
    border: 1px solid;
    margin: 0.5rem 0 7rem 0;
}

.service-center-button-whatsapp a{
    display: flex;
    padding: 0.5rem;
}

.service-center-button-whatsapp a, .service-center-button-whatsapp a:hover {
    text-decoration: none;
    color: #000;
    width: 100%;
}

.service-center-button-whatsapp img {
    width: 25px;
    height: 25px;
}

.service-center-button-whatsapp p {
    color: #454550;
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: 2px;
    padding: 0.2rem 0 0 1rem;
    margin: 0 0 0.8rem 0;
}

#iframe-service-center{
    height: 18.5rem;
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
}

@media only screen and (min-width: 768px) {
    
}

@media only screen and (min-width: 992px) {
    .service-center-banner > div > div > img.desktop{
        display: block;
    }
    .service-center-banner > div > div > img.mobile{
        display: none;
    }

    .service-center-background-content{
        background-color: #F7F7F7;
        padding-bottom: 3rem !important;
    }

    .service-center-background-bottom{
        background-color: #FFF;
    }

    .service-center-form{
        padding: 0 2rem 1.5rem 2rem;
    }

    .service-center-button{
        margin: 2rem 0 0.5rem 0;
    }

    .service-center-description > p {
        font-size: 1.1rem;
        text-align: justify;
    }
    
    .service-center-maps > h2 {
        margin-bottom: 3rem;
    }

    .service-center-address{
        padding-top: 5rem;
    }

    #iframe-service-center{
        height: 22.5rem;
    }

    .service-center-address > h2{
        padding-top: 2rem;
    }
}

@media only screen and (min-width: 1092px) {
    .service-center-button-whatsapp p {
        font-size: 0.85rem;
        margin-bottom: 1rem;
    }
}
.slider-service-center-container{
    width: 100%;
    height: 23rem;
}

.slider-service-center-container > img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}


.service-center-image{
    padding: 2rem 0 0 0 !important;
}

.service-center-image > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.slider-service-center-arrow {
    width: 2.5rem !important;
    height: 2.5rem;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(229, 229, 229);
    border-radius: 50%;
    cursor: pointer;
    font-size: 0.85rem;
}

.slider-service-center-prev {
    top: -0.8rem;
    right: 3.5rem;
    color: #E5E5E5;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.slider-service-center-next {
    top: -0.8rem;
    right: 0rem;
    color: #454550;
}

.slider-service-center-arrow > img {
    width: 0.7rem;
}

.service-center-image .slick-dots {
    top: -0.3rem;
    margin: 0;
    list-style: none;
    text-align: center;
    width: 68% !important;
    display: flex;
    justify-content: flex-end;
}

.slider-dot-ul {
    width: 100%;
}

.service-center-image .slider-dot{
    background-color: #C1C1C1;
    width: 0.5rem;
    height: 0.5rem;
    display: flex;
    justify-content: flex-end;
}

.service-center-image ul{
    padding-left: 0 !important;
    padding-top: 0.6rem;
    display: flex;
    justify-content: flex-start;
    overflow: hidden;
}

.slick-active > .slider-dot{
    background-color: #000000 !important;
}

.slider-dot-ul > li{
    position: relative;
    display: inline-block;
    width: 0.5rem !important;
    height: 0.5rem !important;
    margin: 0 1rem !important;
    padding: 0;
    cursor: pointer;
}

.slider-dot-ul > li:first-child{
    margin: 0 1rem 0 0!important;
}

@media only screen and (min-width: 992px) {
    .service-center-image ul{
        justify-content: flex-end;
    }

    .slider-service-center-prev {
        top: 0.2rem;
        right: 4.5rem;
    }
    
    .slider-service-center-next {
        top: 0.2rem;
        right: 0.8rem;
    }

    .service-center-image .slick-dots {
        top: 1.6rem;
    }
    
    .slider-service-center-container{
        width: 100%;
        height: 23rem;
    }

    .slider-service-center-container > img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}


@media only screen and (min-width: 1092px) {
    .slider-service-center-prev {
        top: 0.2rem;
        right: 4.5rem;
    }
    
    .slider-service-center-next {
        top: 0.2rem;
        right: 0.8rem;
    }
}

@media only screen and (min-width: 1214px) {
    .slider-service-center-prev {
        top: 0.2rem;
        right: 4.5rem;
    }
    
    .slider-service-center-next {
        top: 0.2rem;
        right: 0.8rem;
    }

    .service-center-image .slick-dots {
        top: 0.6rem;
    }
}
.wheels-container{
    padding-top: 6rem !important;
    width: 100%;
    height: 100%;
}

.wheels-description {
    margin-top: 4rem !important;
    text-align: center;
    line-height: 1.8rem;
}

.wheels-images {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}

.wheels-images img {
    display: flex;
    width: 9rem;
    padding: 0 0.5rem;
}
.tda-navbar, .tda-navbar-sub{
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    z-index: 10;
    background-color: #000 !important;
    padding-bottom: 3rem !important;
    justify-content: flex-start !important;
}

.tda-navbar-trigger-home img{
    width: 4rem;
}

.tda-navbar-mobile-is-login > div{
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
}

.tda-navbar-mobile-login-complete-photo{
    border-radius: 50%;
    background-color: #F7F7F7;
    padding: 0.8rem 1.2rem 0.8rem 1.2rem;
    margin: 0 1rem 0 0;
}

.tda-navbar-mobile-is-login p{
    margin-bottom: 0 !important;
    text-transform: capitalize;
}

.icon-black{
    -webkit-filter: invert(100%);
    filter: invert(100%);
}

.icon-white {
    -webkit-filter: brightness(0) invert(1);
            filter: brightness(0) invert(1);
}

.tda-navbar, .tda-navbar-collapse{
    overflow-y: scroll;
    overflow-x: hidden;

    padding-bottom: 3rem !important;
    height: 100vh;
    min-width: 100vw;
}

.navbar-toggler{
    padding: 0 !important;
}

.tda-navbar-close{
    color: #fff;
    font-size: 2rem;
    margin-right: -1rem;
}

.icon-notification{
    margin: 0.1rem 0rem 0 2rem;
    width: 1.3rem;
    height: 1.4rem;
    margin-top: 0.34rem;
}

.tda-navbar-trigger-item-search{
    margin-right: 2rem;
}

.tda-navbar-trigger-item-search, .tda-navbar-trigger-item-list{
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}

.tda-navbar-collapse{
    padding: 0 16vw 3rem 0 !important;
    width: 100%;
}

.tda-navbar-item, .tda-navbar-collapse-item-detail, .tda-navbar-collapse-item-detail-back{
    color: #fff !important;
    margin: 0;
    padding: 0 !important;
    display: flex;
}

.tda-navbar-collapse-item p{
    margin-bottom: 0 !important;
}

.tda-display-none{
    display: none !important;
}

.tda-navbar-item-with-arrow, .tda-navbar-item-with-arrow-back{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.2rem !important;
}

.tda-navbar-item-with-arrow-back{
    justify-content: flex-start;
}

.tda-navbar-item-with-arrow-back>p{
    margin: 0 0 0 1rem;
}

.tda-navbar-item-with-arrow-back>img{
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.tda-navbar-item-with-arrow p{
    margin: 0 !important;
    font-size: 1.2rem;
    color: #fff;
}

.tda-navbar-collapse-item, .tda-navbar-collapse-item-detail{
    width: 100%;
}

.tda-navbar-collapse-item-detail-back{
    margin: 8.2rem 0 1rem 0;
}

.tda-navbar-collapse-item-first{
    margin: 9rem 1rem 0 0;
    border-bottom: 1px solid rgba(255,255,255, 0.3);
    padding-bottom: .9rem;
}

.tda-navbar-collapse-item-first-login{
    margin: 7rem 1rem 0 0;
    border-bottom: 1px solid rgba(255,255,255, 0.3);
    padding-bottom: .9rem;
}

.tda-navbar-collapse-item-first>p{
    margin: 0 0 1.5rem 0;
}

.tda-navbar-collapse-item:nth-child(3){
    margin-top: 1.2rem;
}

.tda-navbar-collapse-item>p, .tda-navbar-collapse-item-detail>p{
    margin-top: 1rem !important;
    font-size: 1.1rem;
    color: #fff;
}

.tda-navbar-collapse-grid{
    margin: 0 !important;
}

.tda-navbar-collapse-grid-card{
    padding: 0 !important;
    background-color: #fff;
}

.tda-navbar-collapse-grid-card img{
    width: 100%;
    height: 27vw;
    border: 1px solid #E5E5E5;
    object-fit: cover;
    object-position: center;
    padding: 4vw;
}

.tda-navbar-header{
    width: 100%;
    min-width: 100vw;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 0;
    padding: 1rem 1rem 0 1.5rem !important;
    z-index: 100;
    background-color: #000;
}

.navbar{
    position: relative;
}

.tda-navbar-home{
    margin: 0 !important;
    color: #fff !important;
}

.tda-navbar-trigger-mobile{
    position: fixed;
    top: 0;
    height: 4.5rem;
    padding-top: 5.5vw !important;
    max-width: 100vw !important;
    background-color: rgba(54, 54, 54, 0.615);
    z-index: 1;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

.tda-navbar-trigger-item{
    display: flex;
}

.tda-navbar-trigger-item-left{
    padding-left: 0.5rem !important;
}

.tda-navbar-trigger-text-search{
    padding-top: 0;
    height: 8vh;
    position: fixed;
    background-color: #fff;

    display: flex;
    align-items: center;
}

.tda-modal-search-text-header{
    display: flex;
    align-items: center;
    border-radius: 0 !important;
}

.tda-modal-search-text .modal-content{
    border-radius: 0 !important;
}

.tda-modal-search-text {
    margin: 0 !important;
    display: flex;
}

.tda-modal-search-text .modal-title{
    display: flex;
    align-items: center;
}

#tda-modal-search-text .btn-close{
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 1rem;
}

.tda-modal-search-text-header .modal-title{
    width: 100%;
}

.tda-modal-search-text .tda-mobile-search{
    min-height: 100vh;
}

.tda-modal-search-text a{
    padding-top: 2rem;
    text-decoration: none;
    color: #000;
}

.tda-modal-search-text .tda-mobile-search img{
    width: 100%;
}

.tda-modal-search-text .tda-mobile-search h2{
    padding-top: 1rem;
}

.car-detail-mobile .special-request-button{
    margin: 1rem 0 4rem 0 !important;
}

#tda-navbar-scroll {
    transition: 0.5s;
    background-color: rgba(255,255,255, 1);
}

.tda-navbar-home img{
    width: 3.5rem;
}

@media only screen and (min-width: 768px) {
    .tda-navbar-trigger-mobile{
        height: 5.5rem;
        padding-top: 3rem !important;
    }
}
.tda-navbar-desktop{
    position: fixed !important;
    top: 0 !important;
    width: 100vw;
    z-index: 10;
    background-color: rgba(54, 54, 54, 0.615);
    transition: 0.5s;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

.bullet-notification{
    width: 0.3rem;
    height: 0.3rem;
    background-color: red;
    right: 0;
    top: 0.35rem;
}

.my-notification-clear-all a{
    cursor: pointer;
}

.search-icon, .icon-notification{
    cursor: pointer;
}

.tda-navbar-desktop-login-complete{
    display: flex;
    align-items: center;
}

.tda-navbar-desktop-login-complete img {
    margin: 0.1rem 0 0 1.2rem;
    width: 0.9rem;
    height: 1rem;
}

.tda-navbar-desktop-login-complete img.icon-white {
    -webkit-filter: brightness(0) invert(1);
            filter: brightness(0) invert(1);
}

.tda-navbar-desktop-login-complete img.icon-black {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%) brightness(0%);
}

.tda-navbar-desktop-login-complete-photo{
    border-radius: 50%;
    background-color: #f7f7f7;
    padding: 0.4rem 0.6rem;
    margin: 0 1rem;
    font-size: 0.55rem;
}

.tda-navbar-desktop-login-complete .dropdown-menu{
    top: 2.5rem !important;
    left: unset !important;
    right: -1rem !important;
    border-radius: 0 !important;
}

.tda-navbar-desktop-container{
    padding: 0 5rem 0 3rem !important;
}

.tda-navbar-desktop-flex{
    width: 100%;
    justify-content: center !important;
    align-items: center !important;
    padding: 0 8% 0 10% !important;
}

.tda-navbar-desktop-item-collection{
    justify-content: space-between !important;
    align-items: center !important;
}

.tda-navbar-desktop-item, .tda-navbar-desktop-item > a, .tda-navbar-desktop-login-register > a > p, .tda-navbar-desktop-flex > a{
    font-size: .9rem !important;
    color: #fff;
    margin: 0;
}

.tda-navbar-desktop-logo{
    width: 8rem;
}

.tda-navbar-desktop-login-register{
    display: flex;
    align-items: center;
}

.tda-navbar-desktop-login-register > img{
    margin-right: 1rem;
    padding-top: 0.1rem;
    width: 0.79rem;
}

#vertical-line-search{
    border-right: 1px solid #C1C1C1;
    height: 0.9rem;
}

#modal-search{
    width: 100vw !important;
}

#modal-search .modal-content{
    background-color: transparent;
}

#modal-search .modal-header{
    background-color: #fff;
    padding: 1.1rem 5rem 1.1rem 2rem;
    width: 100%;
}

#modal-search .modal-title, #modal-notification{
    display: flex;
    align-items: center;
}

.tda-search-close{
    width: 1rem;
    height: 1rem;
    margin-right: 1.5rem;
    cursor: pointer;
}

.tda-search, #modal-search .modal-title, #modal-search .modal-title{
    width: 100%;
}

#modal-search .modal-body{
    width: 100%;
    height: 100%;
    padding: 0 !important;
}

.modal-body{
    width: 100%;
    height: 100%;
}

.search-body-result{
    width: 100%;
    height: 100%;
    padding: 4rem 0 !important;
    background-color: #fff;
}

#search-not-yet-body{
    width: 100%;
    height: 100%;
}

#modal-notification .modal-body{
    padding-bottom: 6rem !important;
}

.tda-search > label {
    display: none;
}

input[name="query"]{
    margin: 0 !important;
    width: 100%;
}

.tda-navbar-desktop-login-register > a > p{
    padding-left: 1rem;
}

.tda-navbar-desktop-login-register > a{
    text-decoration: none;
}

.icon-black{
    -webkit-filter: invert(100%);
    filter: invert(100%);
}

.text-black a, .text-black p, .text-black{
    color: #000 !important;
}

.hover-underline-animation {
    position: relative;
    color: #fff;
}

.hover-underline-animation:after {
    content: '';
    position: absolute;
    width: 100%;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #fff;
    -webkit-transform-origin: bottom right;
            transform-origin: bottom right;
    transition: -webkit-transform 0.25s ease-out;
    transition: transform 0.25s ease-out;
    transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: bottom left;
            transform-origin: bottom left;
}

.hover-underline-animation-black:after {
    content: '';
    position: absolute;
    width: 100%;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #000;
    -webkit-transform-origin: bottom right;
            transform-origin: bottom right;
    transition: -webkit-transform 0.25s ease-out;
    transition: transform 0.25s ease-out;
    transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}

.hover-underline-animation-black:hover:after {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: bottom left;
            transform-origin: bottom left;
}

.hover-underline-animation-black {
    position: relative;
    color: #000;
}

.hover-underline-animation-black:hover{
    color: #000 !important;
}

.hover-underline-animation:hover{
    color: #fff !important;
}

.hover-underline-animation-black:hover{
    color: #000 !important;
}

.hover-underline-animation:hover{
    color: #fff !important;
}

.navbar-nav .show .hover-underline-animation-black >  a, .hover-underline-animation-black:hover,
.hover-underline-animation-black:focus, .hover-underline-animation-black > a:hover,
.hover-underline-animation-black > a:focus, .hover-underline-animation-black > a{
    color: #000 !important;
}

.navbar-nav .show .hover-underline-animation >  a, .hover-underline-animation:hover,
.hover-underline-animation:focus, .hover-underline-animation > a:hover,
.hover-underline-animation > a:focus, .hover-underline-animation > a{
    color: #fff !important;
}

.tda-navbar-cars-dropdown > .dropdown-menu{
    border-radius: 0;
    left: -7.5rem !important;
    top: 1.9rem !important;
    height: 14.9rem;
}

.dropdown-item:hover button {
    background-color: transparent !important;
}

.dropdown-menu button{
    text-decoration: none;
}

.dropdown-item, .dropdown-menu > .dropend > button{
    padding: .5rem 1rem !important;
    font-size: 0.65rem;
}

.dropdown-menu{
    padding: 1rem 1rem 2rem 1rem !important;
}

.dropend > *, .dropend{
    width: 100%;
}

.dropdown-menu > .dropend > button {
    text-align: left;
    padding: .5rem 0 .5rem 1rem !important;
}

.dropdown-menu > .dropend > button:focus {
    box-shadow: none;
}

.dropdown-menu > .dropend > button {
    background-color: transparent;
    border: 0 !important;
    color: #000 !important;
    padding: .5rem 0 .5rem 0 !important;
    margin-left: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.dropdown-menu > .dropend > button::after {
    color: transparent !important;
    background: url("data:image/svg+xml;utf8,<svg style='transform: rotate(-90deg)' viewBox='0 0 140 140' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='white'/></g></svg>") no-repeat;
    width: 0.9rem;
    background-repeat: no-repeat;
    height: 100%;
    -webkit-filter: brightness(0);
            filter: brightness(0);
}

.dropdown-menu > .dropend > button {
    display: flex;
    justify-content: space-between;
}

.dropend > div {
    margin-left: 0.95rem !important;
    margin-top: -1.05rem !important;
    border-radius: 0 !important;
}

.dropend .dropdown-toggle::after {
    margin-left: 0.8rem !important;
    display: none;
}

.dropdown-toggle.nav-link::after{
    background: url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='white'/></g></svg>") no-repeat !important;
}

.dropdown-brand-collection{
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 50%;
    padding: 0 0 0 2rem;
    margin-bottom: 1rem;
}

.dropdown-brand-item a, .dropdown-brand-item a:hover{
    text-decoration: none;
    color: #000;
}

.dropdown-brand-item{
    height: 4rem;
    width: 5rem !important;
    min-height: 4rem !important;
    max-height: 4rem !important;
    padding: 0 !important;
    margin: 0 0.5rem;
}


.overflow-y-scroll .dropdown-menu{
    overflow-y: scroll;
}

.dropdown-brand-item img, .dropdown-merchandise-brand-item img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

.dropdown-brand-item p, .dropdown-merchandise-brand-item p {
    font-size: 0.85rem;
    margin-bottom: 0;
    text-align: center;
}

div[aria-labelledby="dropdown-button-drop-end tda-navbar-dropdown-end dropdown-cars-brand0"], div[aria-labelledby="dropdown-button-drop-end tda-navbar-dropdown-end dropdown-cars-brand1"],
div[aria-labelledby="dropdown-button-drop-end tda-navbar-dropdown-end dropdown-cars-brand2"], div[aria-labelledby="dropdown-button-drop-end tda-navbar-dropdown-end dropdown-cars-brand3"],
div[aria-labelledby="dropdown-button-drop-end tda-navbar-dropdown-end dropdown-cars-brand4"]  {
    width: 18rem;
    height: 14.85rem !important;
    margin-top: -1.05rem !important;
}

div[aria-labelledby="dropdown-button-drop-end tda-navbar-dropdown-end dropdown-cars-brand5"], div[aria-labelledby="dropdown-button-drop-end tda-navbar-dropdown-end dropdown-cars-brand6"] {
    width: 24rem;
    height: 14.85rem !important;
    margin-top: -1.05rem !important;
}
div[aria-labelledby="dropdown-button-drop-end tda-navbar-dropdown-end dropdown-cars-brand7"], div[aria-labelledby="dropdown-button-drop-end tda-navbar-dropdown-end dropdown-cars-brand8"] {
    width: 29rem;
    height: 14.85rem !important;
    margin-top: -1.05rem !important;
}
div[aria-labelledby="dropdown-button-drop-end tda-navbar-dropdown-end dropdown-cars-brand9"], div[aria-labelledby="dropdown-button-drop-end tda-navbar-dropdown-end dropdown-cars-brand10"] {
    width: 35rem;
    height: 14.85rem !important;
    margin-top: -1.05rem !important;
}
div[aria-labelledby="dropdown-button-drop-end tda-navbar-dropdown-end dropdown-cars-brand11"], div[aria-labelledby="dropdown-button-drop-end tda-navbar-dropdown-end dropdown-cars-brand12"] {
    width: 41rem;
    height: 14.85rem !important;
    margin-top: -1.05rem !important;
}
div[aria-labelledby="dropdown-button-drop-end tda-navbar-dropdown-end dropdown-cars-brand13"], div[aria-labelledby="dropdown-button-drop-end tda-navbar-dropdown-end dropdown-cars-brand14"] {
    width: 47rem;
    height: 14.85rem !important;
    margin-top: -1.05rem !important;
}
div[aria-labelledby="dropdown-button-drop-end tda-navbar-dropdown-end dropdown-cars-brand15"], div[aria-labelledby="dropdown-button-drop-end tda-navbar-dropdown-end dropdown-cars-brand16"] {
    width: 53rem;
    height: 14.85rem !important;
    margin-top: -1.05rem !important;
}

.dropdown-merchandise-brand-collection{
    margin-top: -5.75rem !important;
    padding-top: 5.75rem !important;
}

.dropdown-menu > .dropend > #dropdown-windows-film-brand::after{
    margin-left: 8rem !important;
}

#tda-navbar-scroll {
    transition: 0.5s;
    background-color: rgba(255,255,255, 1);
    z-index: 11;
    padding-bottom: 0;
}

.tda-navbar-desktop-item > div[aria-labelledby="dropdown-merchandise-brand"] {
    width: 19.5rem !important;
    height: 18.15rem;
    margin-left: -3rem;
    border-radius: 0;
}

div[aria-labelledby="dropdown-button-drop-end tda-navbar-dropdown-end dropdown-products-0"]{
    width: 25rem;
    height: 18.15rem;
}
div[aria-labelledby="dropdown-button-drop-end tda-navbar-dropdown-end dropdown-products-1"]{
    width: 25rem;
    height: 18.15rem;
    top: -1.95rem !important;
}
div[aria-labelledby="dropdown-button-drop-end tda-navbar-dropdown-end dropdown-products-2"]{
    width: 25rem;
    height: 18.15rem;
    top: -3.95rem !important;
}
div[aria-labelledby="dropdown-button-drop-end tda-navbar-dropdown-end dropdown-products-3"]{
    width: 25rem;
    height: 18.15rem;
    top: -5.9rem !important;
}
div[aria-labelledby="dropdown-button-drop-end tda-navbar-dropdown-end dropdown-products-4"]{
    width: 25rem;
    height: 18.15rem;
    top: -7.9rem !important;
}
div[aria-labelledby="dropdown-button-drop-end tda-navbar-dropdown-end dropdown-products-5"]{
    width: 25rem;
    height: 18.15rem;
    top: -9.85rem !important;
}
div[aria-labelledby="dropdown-button-drop-end tda-navbar-dropdown-end dropdown-products-6"]{
    width: 25rem;
    height: 18.15rem;
    top: -11.85rem !important;
}
div[aria-labelledby="dropdown-button-drop-end tda-navbar-dropdown-end dropdown-products-7"]{
    width: 25rem;
    height: 18.15rem;
    top: -13.8rem !important;
}
div[aria-labelledby="dropdown-button-drop-end tda-navbar-dropdown-end dropdown-products-8"]{
    width: 25rem;
    height: 18.15rem;
    top: -15.8rem !important;
}

@media only screen and (min-width: 992px) {
    .tda-navbar-desktop-item .dropdown-menu{
        border-radius: 0 !important;
    }

    .tda-navbar-desktop-item, .tda-navbar-desktop-item > a, .tda-navbar-desktop-login-register > a > p, .tda-navbar-desktop-flex > a{
        font-size: 0.55rem !important;
        color: #fff;
        margin: 0;
    }

    .tda-navbar-desktop-logo {
        padding-top: 0.5rem;
        width: 4.5rem;
    }

    .tda-navbar-desktop-flex{
        padding: 0 8% 0 10% !important
    }

    .tda-navbar-desktop{
        margin-top: -0.8rem !important;
    }
}

@media only screen and (min-width: 1092px) {

    .tda-navbar-desktop-item, .tda-navbar-desktop-item > a, .tda-navbar-desktop-login-register > a > p, .tda-navbar-desktop-flex > a{
        font-size: .96vw !important;
        color: #fff;
        margin: 0;
    }

    .tda-navbar-desktop-flex>a, .tda-navbar-desktop-item{
        margin: 0 0.4rem;
    }

    .tda-navbar-desktop-logo {
        padding-top: 0.5rem;
        width: 5rem;
    }
}


@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes spin {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.brand-banner{
    width: 100vw;
    height: 30vh;
}

.brand-banner > img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.slider-banner-dot-container {
    display: none !important;
}

.button-brand-page{
    width: 100%;
    margin-top: 4rem;
}

.button-brand-page-footer-1{
    width: 100%;
    margin-top: 4rem;
}

.button-brand-page-footer-2{
    width: 100%;
}

.text-1{
    font-size: 0.9rem;
    text-align: justify;
    overflow: hidden;
}

.text-2{
    font-size: 1rem;
    overflow: hidden;
}

.text-head-1{
    font-weight: 600;
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    overflow: hidden;
}

.section{
    margin: 5rem 0 3rem 0;
}

.section2{
    margin: 0 0 6rem 0;
}

.section-slider-brand{
    margin: 0 0 2rem 0;
}

.slider-brand-page{
    margin: 0 0 2rem 0;
}

.slider-brand-page-container{
    height: 16rem;
}

.slider-brand-page-container > img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

hr{
    border-color: rgba(229, 229, 229,0.3);
    margin: 3.5rem 0 4.5rem 0 !important;
}

.slider-banner-page-arrow {
    width: 2.5rem !important;
    height: 2.5rem;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(229, 229, 229);
    border-radius: 50%;
    cursor: pointer;
    font-size: 0.85rem;
}

.slider-banner-page-prev {
    bottom: -4rem;
    left: 7rem;
    color: #E5E5E5;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.slider-banner-page-next {
    bottom: -4rem;
    right: 7rem;
    color: #454550;
}

.slider-banner-page-arrow > img {
    width: 0.7rem;
}

.button-rectangle-1{
    margin-bottom: 2rem;
}

.button-rectangle-2{
    padding: 0.8rem 3rem 0.8rem 3rem;
}

.brand-page-main{
    padding-bottom: 5rem !important;
}

@media only screen and (min-width: 768px) {
    
}

@media only screen and (min-width: 992px) {
    .brand-banner{
        width: 100vw;
        height: 55vh;
    }

    .brand-banner > img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    .button-brand-page, .button-brand-page-footer{
        width: auto;
        margin-top: 4rem;
    }

    .text-1{
        font-size: 0.9rem;
    }

    .text-2{
        font-size: 1rem;
    }

    .text-head-1{
        font-weight: 600;
        font-size: 1.5rem;
        margin-bottom: 1.5rem;
    }

    .section{
        margin: 5rem 0 3rem 0;
    }

    .section2{
        margin: 0 0 6rem 0;
    }

    .section-slider-brand{
        margin: 0 0 2rem 0;
    }

    .slider-brand-page{
        margin: 0 0 2rem 0;
    }

    .slider-brand-page-container{
        width: 18rem;
        height: 28rem;
    }

    .slider-brand-page-container > img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    hr{
        border-color: rgba(229, 229, 229,0.3);
        margin: 3.5rem 0 4.5rem 0 !important;
    }

    .slider-banner-page-arrow {
        width: 2.5rem !important;
        height: 2.5rem;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid rgb(229, 229, 229);
        border-radius: 50%;
        cursor: pointer;
        font-size: 0.85rem;
    }

    .slider-banner-page-prev {
        top: 45%;
        left: -5rem;
        color: #E5E5E5;
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
    }
    
    .slider-banner-page-next {
        top: 45%;
        right: -5rem;
        color: #454550;
    }
    
    .slider-banner-page-arrow > img {
        width: 0.7rem;
    }

    .button-rectangle-1{
        margin-right: 1.2rem;
    }

    .button-rectangle-2{
        padding: 0.8rem 3rem 0.8rem 3rem;
        margin-left: 1.2rem;
    }

    .slider-banner-dot > img{
        width: 3rem;
        height: 2.5rem;
        object-fit: cover;
        object-position: center;
    }

    .slider-banner-dot-container {
        margin-top: 2rem;
        display: block !important;
        position: absolute;
        bottom: -2rem;
    }

    .slider-banner-dot-container > li {
        width: 3rem;
        margin: 0 !important;
        opacity: 0.5;
    }

    .slider-banner-dot-container > li.slick-active {
        opacity: 1;
    }

    .button-brand-page-footer-1{
        width: 30%;
    }
    
    .button-brand-page-footer-2{
        width: 30%;
    }
}

@media only screen and (min-width: 1200px) {
}
#car-pdf{
    margin-top: 6rem;
    font-size: 1.3rem;
}
ol.decimal-list li {
  font-size: 18px;
  font-weight: bold;
}

ol.decimal-char li {
  font-size: 16px;
  font-weight: bold;
}

p {
  font-size: 14px;
  margin-bottom: 0 !important;
  font-weight: normal !important;
}

ul {
  font-size: 10px !important;
}

.special-request-banner > div > div{
    padding: 0;
}

.special-request-banner > div > div > img{
    width: 100%;
}

.special-request-banner > div > div > .desktop{
    display: none;
}

.special-request-title > h1{
    font-size: 1.5rem;
    line-height: 1.8rem;
    font-weight: 600;
    margin: 2rem 0 0.5rem 0;

    text-align: center;
}

.special-request-title > p{
    margin-bottom: 2rem;
}

.special-request-card > div > a > img{
    width: 100%;
    object-fit: cover;
    object-position: center;
}


.sell-images-collection .button-image-form{
    margin-bottom: 2rem;
}

.sell-image-item{
    margin-bottom: 2rem;
}

#modal-close-info .modal-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

#modal-close-info .modal-title > p{
    margin: 0;
}

@media only screen and (min-width: 768px) {
    
}

@media only screen and (min-width: 992px) {
    .special-request-banner > div > div > img.desktop{
        display: block;
    }
    .special-request-banner > div > div > img.mobile{
        display: none;
    }

    .special-request-form{
        padding: 0 2rem;
    }

    .sell-images-collection .button-image-form{
        height: 4.5rem !important;
        max-width: 4.5rem;
        margin-bottom: 0rem;
    }
}

@media only screen and (min-width: 1200px) {
}
.my-garage-container{
    padding-bottom: 5rem !important;
    padding-top: 9.5rem !important;
}

.my-garage-title {
    display: flex;
    justify-content: space-between;
    margin-top: 4rem;
}

.my-garage-title > h1{
    font-size: 1.3rem;
    line-height: 1.8rem;
    font-weight: 600;
    margin: 0 0 1.5rem 0;
}

.my-garage-title > img {
    border: 1.5px solid;
    padding: .3rem;
    width: 2.5rem;
    height: 2.5rem;
}

.my-garage-title > p {
    margin-bottom: 4rem;
}

.my-garage-car{
    margin-top: 2.5rem !important;
    margin-bottom: 2rem;
}

.my-garage-modal{
    border-bottom: 0 !important;
}


.my-garage-modal > button{
    margin: .5rem 1rem 0.5rem 0 !important;
}

.my-garage-modal{
    border-bottom: 0 !important;
    justify-content: right !important;
}

#my-garage-modal-title{
    justify-content: center !important;
    margin-bottom: 1.5rem !important;
}

.mygarage-form-container{
    margin-bottom: 2rem;
}

.my-garage-button{
    margin: 1rem 0 4rem 0;
    width: 100%;
}

.form-field-label{
    margin: 2rem 0 1.5rem 0 !important;
}

.my-garage-button-desktop{
    display: none;
}

.my-notification-clear-all{
    text-align: right;
    padding: 0;
}

.my-notification-clear-all > p{
    margin-right: -0.7rem !important;
    color: #454550;
}

#my-garage-modal-notification{
    padding: 0;
}

.my-modal-notification{
    padding-bottom: 0 !important;
    justify-content: space-between;
}

.my-notification-item{
    border-bottom: 1px solid rgb(69, 69, 80, 0.6);
    padding: 1.5rem 0 0.5rem 0 !important;
}

.my-notification-item > .date > p{
    color: #454550;
    font-size: 0.85rem;
    margin-bottom: .5rem;
}

.my-notification-item > .title > p{
    color: #454550;
    font-size: 1rem;
    margin-bottom: 0.2rem;
    font-weight: 600;
}

.my-notification-item > .description > p{
    color: #454550;
    font-size: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    
    line-height: 1.4rem;
    height: 2.9rem;

    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
}

.modal-fullscreen{
    width: 85vw !important;
    margin: auto !important;
    z-index: 3000 !important;
}

@media only screen and (min-width: 768px) {
    
}

@media only screen and (min-width: 992px) {
    .my-garage-button-desktop{
        display: block;
    }

    .my-garage-button-mobile{
        display: none;
    }

    .my-garage-modal > button{
        margin: 0 !important;
        margin-right: 2rem !important;
    }

    .my-garage-title{
        padding: 0 0.2rem !important;
        margin-bottom: 1.5rem;
    }

    .my-garage-car{
        margin-top: 1rem !important;
    }

    .my-garage-title > h1 {
        margin-bottom: 0 !important;
    }

    #my-garage-modal-title {
        justify-content: center !important;
        margin-bottom: 5.5rem !important;
    }

}

@media only screen and (min-width: 1200px) {
}
.slider-banner-container{
    padding: 0 !important;
}

.expand-arrow{
    display: none;
}

.slider-banner{
    background-color: #F7F7F7;
    position: relative;
}

.slider-banner-container{
    width: 100%;
    height: 30vh;

    position: relative;
}

.slider-banner-image{
    width: 100%;
    height: 100%;

    background-size: cover;
    background-position: top;
}

.slider-banner-detail{
        position: absolute;
        top: 0;
        margin: 6rem 2rem 0 2rem;
        width: 100%;
}

.slider-banner-detail>.title{
    font-size: 0.85rem;
    letter-spacing: 4px;
    line-height: 1rem;
}

.slider-banner-detail>.description{
    font-size: 0.75rem;
    margin: 1rem 0 1.8rem 0;
    letter-spacing: 1px;
}

.slider-banner-detail>.title, .slider-banner-detail>.description, .slider-banner-scroll>p, 
.slider-banner-info>div>p{
    font-weight: 400;
    color: white;
}



.slider-banner-scroll{
    display: flex;
    position: absolute;

    bottom: 0;
    margin: 0 0 3% 7%;
    display: none;
}

.slider-banner-scroll>img{
    width: 15%;
    margin-right: 1rem;
}

.slider-banner-scroll>p{
    display: flex;
    align-items: center;

    font-size: 0.85rem;
}

.slider-banner-info>.show-room{
    background-color:#E5E5E5;
    width: 42%;

    display: flex;
    flex-flow: column wrap;
    justify-content: center;

    padding: 0 0 0 1.5rem;
}

.slider-banner-info img{
    width: 30px;
}

.need-help a, .show-room a{
    text-decoration: none;
    color: #000;
}

.slider-banner-info .need-help{
    background-color: #F7F7F7;
    width: 40%;

    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    
    padding: 0.5rem 0 0.5rem 1rem;
}

.slider-banner-info .need-help>img{
    width: 1.5rem;
    height: auto;
    object-fit: cover;
}

.slider-banner-info p, .slider-banner-info .media-social p{
    color: black;
    
    margin-bottom: 0;
    padding-top: 0.3rem;
    letter-spacing: 1px;
    font-size: 0.9rem;
    line-height: 1rem;
}
.slider-banner-info .media-social p{
    padding-left: 12px;
    display: none;
}

.slider-banner-info>.media-social{
    background-color: #FFFFFF;
    width: 15%;
}

.slider-banner-info>.media-social>.icon{
    padding-top: 0.2rem;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
}

.slider-banner-info>.media-social>.icon>img{
    width: 1.5rem;
    margin: 0rem 0 0.5rem;
}


.slider-banner-container .slick-dots {
    bottom: 20vh;
    margin: 0;
    list-style: none;
    text-align: center;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 6rem;
    height: 20vh;
    right: -1.1rem;
    overflow: hidden;
    top: 4rem;
}

.slider-banner-container ul {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.slider-dot-banner{
    background-color: #C1C1C1;
    width: 0.5rem;
    height: 0.5rem;
    display: flex;
    justify-content: flex-end;
}

.slick-active > .slider-dot-banner{
    background-color: #fff !important;
}

.slider-dot-banner-ul > li {
    position: relative;
    display: inline-block;
    width: 0.5rem !important;
    height: 0.5rem !important;
    margin: 1rem 0 !important;
    padding: 0;
    cursor: pointer;
}

@media only screen and (min-width: 768px) {
    .slider-banner-container .slick-dots {
        top: 6rem;
    }

    .slider-banner-info>.media-social {
        width: 18%;
        padding-left: 10px;
    }
    .slider-banner-info>.media-social>.icon {
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        padding: 0.4rem 0 0 10px;
    }
    .slider-banner-info .media-social p{
        display: block;
    }
}

@media only screen and (min-width: 992px) {
    .slider-banner-container .slick-dots {
        height: 55vh;
        right: 0;
    }

    .slider-banner-container{
        width: 100%;
        height: 100vh;
    
        position: relative;
    }

    .slider-banner-container{
        padding: 0 !important;
    }
    
    .slider-banner{
        background-color: #F7F7F7;
        position: relative;
    }
    
    .slider-banner-container{
        width: 100%;
        height: 100vh;
    
        position: relative;
    }
    
    .slider-banner-image{
        width: 100%;
        height: 100%;
    
        background-size: cover;
        background-position: center;
    }
    
    .slider-banner-detail{
        width: 70vw;
        position: absolute;
        top: 0;
        margin: 13rem 4rem 0 4rem;
    }
    
    .slider-banner-detail>.title{
        font-size: 2.2rem;
        letter-spacing: 4px;
        line-height: 3rem;
    }
    
    .slider-banner-detail>.description{
        font-size: 1rem;
        margin: 1rem 0 1.8rem 0;
        letter-spacing: 1px;
    }
    
    .slider-banner-detail>.title, .slider-banner-detail>.description, .slider-banner-scroll>p, 
    .slider-banner-info>div>p{
        font-weight: 400;
        color: white;
    }
    
    .slider-banner-scroll{
        display: flex;
        position: absolute;
    
        bottom: 0;
        margin: 0 0 3% 7%;
    }
    
    .slider-banner-scroll>img{
        width: 15%;
        margin-right: 1rem;
    }
    
    .slider-banner-scroll>p{
        display: flex;
        align-items: center;
    
        font-size: 0.85rem;
    }
    
    .slider-banner-info>.show-room{
        background-color:#E5E5E5;
        width: 40%;
    
        display: flex;
        flex-flow: row wrap;
        justify-content: left;
        align-items: center;
        
        /* padding: 0.4rem 0 0 0.5rem; */
        padding: 0 0 0 0;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }
    
    .slider-banner-info>.show-room>img{
        width: 1.5rem;
    }

    .slider-banner-info>.show-room>.expand-arrow{
        height: 100%;
        display: flex;
        width: 2rem;
        justify-content: center;
        align-items: center;
        margin-right: 1.5rem;
    }

    .slider-banner-info>.show-room>.expand-arrow>img{
        width: 0.5rem;
        height: 1rem;
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        transition: all 1s;
    }

    .slider-banner-info>.show-room>.expand-arrow.open>img{
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }

    .slider-banner-info>.show-room>.expand-arrow.close>img{
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
    }
    
    .slider-banner-info .need-help{
        background-color: #F7F7F7;
        width: 30%;
    
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        
        padding: 0.4rem 0 0 1rem;
    }
    
    .slider-banner-info .need-help>img{
        width: 1.5rem;
        height: auto;
        object-fit: cover;
    }
    
    .slider-banner-info>div>p{
        color: black;
        
        padding-top: 0.3rem;
        letter-spacing: 1px;
        font-size: 0.85rem;
        line-height: 1rem;
    }
    
    .slider-banner-info>.media-social{
        background-color: #FFFFFF;
        width: 30%;
    }
    
    .slider-banner-info>.media-social>.icon>img{
        width: 1.5rem;
        margin: 0rem 0 0.5rem;
    }
}


@media only screen and (min-width: 1092px) {
    .slider-banner-info>div>p{
        font-size: 0.6rem;
    }
    
    .slider-banner-scroll>img{
        width: 13%;
    }
    
    .slider-banner-scroll>p {
        font-size: 0.85rem;
    }
}

@media only screen and (min-width: 1200px) {

}


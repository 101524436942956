.form-field-image{
    display: none;
}

.button-image-form{
    border: 1px solid #E5E5E5 !important;
    border-radius: 0 !important;
    background-color: transparent !important;

    width: 100%;
    height: 20vw;
    padding: 0.05rem !important;
}

.button-image-form:active:focus, .button-image-form:focus{
    box-shadow: none !important;
}

.image-preview-container{
    border-radius: 0 !important;
    background-color: transparent !important;

    width: 100%;
    height: 100%;
}

.image-preview-container img, .image-preview-container > div{
    width: 100%;
    height: 100%;
}

.image-preview-container img{
    object-fit: cover;
    object-position: center;
}

@media only screen and (min-width: 768px) {
    
}

@media only screen and (min-width: 992px) {
    .button-image-form {
        height: 10vw !important;
    }
}

@media only screen and (min-width: 1200px) {
}
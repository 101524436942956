.titilium-container *{
    font-family: 'Titillium Web', sans-serif !important;
}

.events-detail-back {
    margin-top: 7rem !important;
    display: flex;
}

.events-detail-back a, .events-detail-back a:hover {
    text-decoration: none;
    font-size: 0.85rem;
    color: #454550;
}

.events-detail-back  p {
    margin-bottom: 0;
}

.events-detail-back  *, .events-detail-back {
    display: flex;
    align-items: center;
}

.events-detail-title > p {
    font-size: 2.2rem;
    line-height: 2.2rem;
    font-weight: 600;
    margin: 0 0 2rem 0;
}

.events-detail-image {
    width: 100%;
    height: 18rem;
    margin-bottom: 2rem;
}

.events-detail-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    margin-bottom: 2rem;
}

.events-detail-image .carousel-indicators, .events-detail-image .carousel-control-next, 
.events-detail-image .carousel-control-prev{
    display: none;
}

.events-detail-specification {
    display: flex;
    color: #454550;
}

.events-detail-specification > img {
    width: 1.5rem;
    height: 1.5rem;
}

.icon-casual {
    padding: 0 0.4rem 0 0.3rem;
    margin-bottom: 1rem;
}

.events-detail-specification > p, .events-detail-description > p, .events-detail-specification-maps{
    margin: 0;
    font-size: 0.85rem;
    color: #454550;
}

.events-detail-specification > p:nth-child(2) {
    margin-top: -0.2rem;
}

.events-detail-specification-maps > p:nth-child(1){
    font-weight: 600;
}

.events-detail-specification-maps > p:nth-child(2){
    font-weight: 400;
    margin-top: -0.8rem;
}

.events-detail-share {
    text-align: right;
}

.events-detail-share img{
    width: 2.5rem;
    padding: 0.6rem;
    background-color: #000;
}

.events-detail-description{
    padding: 0 0 1.5rem 0;
}

.events-detail-description *{
    text-align: justify;
}

.events-detail-description > p > img {
    margin: 1.5rem 0;
}

.events-detail-description > p > img {
    width: 100%;
}

.events-detail-card-container{
    background-color:#F7F7F7;
    padding: 4.5rem 0 2.5rem 0;
}

.events-detail-maps > div{
    margin-bottom: 2rem;
    margin-top: 1.5rem;
}

.events-detail-maps > div > img{
    width: 100%;
}

.events-detail-content{
    margin-bottom: 2rem;
}

#iframeId{
    height: 20rem;
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
}

@media only screen and (min-width: 768px) {
    
}

@media only screen and (min-width: 992px) {
    
    .events-detail-description{
        padding: 0 1rem 1.5rem 6.5rem !important;
    }

    #iframeId {
        height: 15rem;
    }

    .events-detail-share img{
        display: none;
    }

    .icon-casual {
        padding: 0 0.6rem 0 0.3rem;
        margin-bottom: 1rem;
    }

    .events-detail-specification-casual{
        padding-top: 0.8rem;
    }

    .icon-maps{
        padding: 0 0.35rem 0.35rem 0.1rem;
    }

    .events-detail-specification-maps > p, .events-detail-specification > p {
        padding-left: 0.4rem;
    }

    .events-detail-image {
        height: 75vh;
    }
}

@media only screen and (min-width: 1200px) {
}
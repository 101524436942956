.slider-our-history-mobile-container {
    width: 100%;
    height: 8rem;
}

.modal-our-history-img-mobile  {
    width: 100% !important;
    height: 15rem !important;
}

.modal-our-history-text .slick-list{
    max-height: 45vw;
    margin-bottom: 2rem;
}

#modal-our-history-row{
    position: relative;
}

#modal-our-history > .modal-content{
    background-color: transparent;
}

#modal-our-history-img-mobile .slick-slide {
    height: 20rem !important;
}

#modal-our-history-img-mobile .slick-slide * {
    width: auto !important;
    height: 80vw;
}

#modal-our-history-img-mobile .slick-slide>div {
    display: flex;
    justify-content: center;
}

#modal-our-history-year-mobile{
    position: absolute;
    bottom: -10rem;
    padding: 0;
}

#modal-our-history-year-mobile .slick-current{
    opacity: 1 !important;
}

#modal-our-history-year-mobile .slick-slide{
    opacity: 0;
}

#modal-our-history-year-mobile .slick-slide > div > .modal-our-history-year-mobile-item > .dot{
    opacity: 0;
}

#modal-our-history-year-mobile .slick-current > div > .modal-our-history-year-mobile-item > .dot{
    opacity: 1;
}

#modal-our-history-year-mobile .slick-slide > div > .modal-our-history-year-mobile-item > .text-2{
    color: rgba(255, 255, 255, 0.3);
}

#modal-our-history-year-mobile .slick-current > div > .modal-our-history-year-mobile-item > .text-2{
    color: #fff;
}

#modal-our-history-line{
    position: absolute;
    bottom: -6.9rem;
}

#modal-our-history-line > img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.modal-our-history-year-mobile-item {
    display: flex !important;
    flex-direction: column;
    align-items: center;
}

.modal-our-history-year-mobile-item > .dot {
    height: 0.5rem;
    width: 0.5rem;
    background-color: #fff;
    margin-bottom: 1rem;
}

.service-center-image{
    padding: 2rem 0 0 0 !important;
}

.service-center-image > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.slider-our-history-mobile-arrow {
    width: 2.5rem !important;
    height: 2.5rem;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(229, 229, 229);
    border-radius: 50%;
    cursor: pointer;
    font-size: 0.85rem;
}

.slider-our-history-mobile-prev {
    top: 141vw;
    left: 6.5rem;
    color: #E5E5E5;
    transform: rotate(180deg);
    z-index: 15;
}

.slider-our-history-mobile-next {
    top: 141vw;
    right: 6.5rem;
    color: #454550;
    z-index: 15;
}

.slider-our-history-mobile-arrow > img {
    width: 0.7rem;
}

.service-center-image .slick-dots {
    top: -0.3rem;
    margin: 0;
    list-style: none;
    text-align: center;
    width: 73% !important;
    display: flex;
    justify-content: flex-end;
}

.slider-dot-ul {
    width: 100%;
}

.service-center-image .slider-dot{
    background-color: #C1C1C1;
    width: 0.5rem;
    height: 0.5rem;
    display: flex;
    justify-content: flex-end;
}

.service-center-image ul{
    padding-left: 0 !important;
    padding-top: 0.6rem;
    display: flex;
    justify-content: flex-start;
    overflow: hidden;
}

.slick-active > .slider-dot{
    background-color: #000000 !important;
}

.slider-dot-ul > li{
    position: relative;
    display: inline-block;
    width: 0.5rem !important;
    height: 0.5rem !important;
    margin: 0 1rem !important;
    padding: 0;
    cursor: pointer;
}

.slider-dot-ul > li:first-child{
    margin: 0 1rem 0 0!important;
}

@media only screen and (min-width: 768px) {
    #modal-our-history-img-mobile .slick-slide {
        height: fit-content !important;
    }
    #modal-our-history-img-mobile .slick-slide * {
        height: 55vw !important;
    }
    #modal-our-history-text .slick-list {
        max-height: 35vw;
    }
    #modal-our-history-line {
        position: absolute;
        bottom: -9.9rem;
    }
    #modal-our-history-year-mobile {
        bottom: -13rem;
    }
    .slider-our-history-mobile-next {
        top: 85vw;
    }
    .slider-our-history-mobile-prev {
        top: 85vw;
    }
}

@media only screen and (min-width: 992px) {
    #modal-our-history-text .slick-list {
        overflow-y: hidden;
    }
    #modal-our-history-img-mobile .slick-slide {
        height: 31rem !important;
    }

    .slider-our-history-mobile-prev {
        top: 2.5rem;
        left: -6rem;
        color: #E5E5E5;
        transform: rotate(180deg);
    }
    
    .slider-our-history-mobile-next {
        top: 2.5rem;
        right: -6rem;
        color: #454550;
    }

    #modal-our-history-year-mobile{
        position: absolute;
        bottom: 0.8rem;
        padding: 0 10rem;
    }

    #modal-our-history-line{
        position: absolute;
        bottom: 4rem;
    }

    .slider-our-history-mobile-container, .slider-our-history-mobile-container > img{
        width: 100%;
        height: 25rem;
    }

    .service-center-image ul{
        justify-content: flex-end;
    }

    .slider-our-history-mobile-prev {
        top: 1.2rem;
        right: 4.5rem;
    }
    
    .slider-our-history-mobile-next {
        top: 1.2rem;
        right: 0.8rem;
    }
}

@media only screen and (max-height: 768px) {
    .our-history-mobile-close-container {
        margin-bottom: 1rem !important;
    }
    #modal-our-history-img-mobile .slick-slide {
        height: fit-content !important;
    }
    #modal-our-history-img-mobile .slick-slide * {
        height: 75vw !important;
    }
    #modal-our-history-text .slick-list {
        max-height: 35vw !important;
    }
    #modal-our-history-line {
        position: absolute !important;
        bottom: -5.9rem !important;
    }
    #modal-our-history-year-mobile {
        bottom: -9.5rem !important;
    }
    .slider-our-history-mobile-next {
        top: 122vw !important;
    }
    .slider-our-history-mobile-prev {
        top: 122vw !important;
    }
}
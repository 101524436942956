.tda-navbar, .tda-navbar-sub{
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    z-index: 10;
    background-color: #000 !important;
    padding-bottom: 3rem !important;
    justify-content: flex-start !important;
}

.tda-navbar-trigger-home img{
    width: 4rem;
}

.tda-navbar-mobile-is-login > div{
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
}

.tda-navbar-mobile-login-complete-photo{
    border-radius: 50%;
    background-color: #F7F7F7;
    padding: 0.8rem 1.2rem 0.8rem 1.2rem;
    margin: 0 1rem 0 0;
}

.tda-navbar-mobile-is-login p{
    margin-bottom: 0 !important;
    text-transform: capitalize;
}

.icon-black{
    -webkit-filter: invert(100%);
    filter: invert(100%);
}

.icon-white {
    filter: brightness(0) invert(1);
}

.tda-navbar, .tda-navbar-collapse{
    overflow-y: scroll;
    overflow-x: hidden;

    padding-bottom: 3rem !important;
    height: 100vh;
    min-width: 100vw;
}

.navbar-toggler{
    padding: 0 !important;
}

.tda-navbar-close{
    color: #fff;
    font-size: 2rem;
    margin-right: -1rem;
}

.icon-notification{
    margin: 0.1rem 0rem 0 2rem;
    width: 1.3rem;
    height: 1.4rem;
    margin-top: 0.34rem;
}

.tda-navbar-trigger-item-search{
    margin-right: 2rem;
}

.tda-navbar-trigger-item-search, .tda-navbar-trigger-item-list{
    transform: scale(1.05);
}

.tda-navbar-collapse{
    padding: 0 16vw 3rem 0 !important;
    width: 100%;
}

.tda-navbar-item, .tda-navbar-collapse-item-detail, .tda-navbar-collapse-item-detail-back{
    color: #fff !important;
    margin: 0;
    padding: 0 !important;
    display: flex;
}

.tda-navbar-collapse-item p{
    margin-bottom: 0 !important;
}

.tda-display-none{
    display: none !important;
}

.tda-navbar-item-with-arrow, .tda-navbar-item-with-arrow-back{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.2rem !important;
}

.tda-navbar-item-with-arrow-back{
    justify-content: flex-start;
}

.tda-navbar-item-with-arrow-back>p{
    margin: 0 0 0 1rem;
}

.tda-navbar-item-with-arrow-back>img{
    transform: rotate(180deg);
}

.tda-navbar-item-with-arrow p{
    margin: 0 !important;
    font-size: 1.2rem;
    color: #fff;
}

.tda-navbar-collapse-item, .tda-navbar-collapse-item-detail{
    width: 100%;
}

.tda-navbar-collapse-item-detail-back{
    margin: 8.2rem 0 1rem 0;
}

.tda-navbar-collapse-item-first{
    margin: 9rem 1rem 0 0;
    border-bottom: 1px solid rgba(255,255,255, 0.3);
    padding-bottom: .9rem;
}

.tda-navbar-collapse-item-first-login{
    margin: 7rem 1rem 0 0;
    border-bottom: 1px solid rgba(255,255,255, 0.3);
    padding-bottom: .9rem;
}

.tda-navbar-collapse-item-first>p{
    margin: 0 0 1.5rem 0;
}

.tda-navbar-collapse-item:nth-child(3){
    margin-top: 1.2rem;
}

.tda-navbar-collapse-item>p, .tda-navbar-collapse-item-detail>p{
    margin-top: 1rem !important;
    font-size: 1.1rem;
    color: #fff;
}

.tda-navbar-collapse-grid{
    margin: 0 !important;
}

.tda-navbar-collapse-grid-card{
    padding: 0 !important;
    background-color: #fff;
}

.tda-navbar-collapse-grid-card img{
    width: 100%;
    height: 27vw;
    border: 1px solid #E5E5E5;
    object-fit: cover;
    object-position: center;
    padding: 4vw;
}

.tda-navbar-header{
    width: 100%;
    min-width: 100vw;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 0;
    padding: 1rem 1rem 0 1.5rem !important;
    z-index: 100;
    background-color: #000;
}

.navbar{
    position: relative;
}

.tda-navbar-home{
    margin: 0 !important;
    color: #fff !important;
}

.tda-navbar-trigger-mobile{
    position: fixed;
    top: 0;
    height: 4.5rem;
    padding-top: 5.5vw !important;
    max-width: 100vw !important;
    background-color: rgba(54, 54, 54, 0.615);
    z-index: 1;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

.tda-navbar-trigger-item{
    display: flex;
}

.tda-navbar-trigger-item-left{
    padding-left: 0.5rem !important;
}

.tda-navbar-trigger-text-search{
    padding-top: 0;
    height: 8vh;
    position: fixed;
    background-color: #fff;

    display: flex;
    align-items: center;
}

.tda-modal-search-text-header{
    display: flex;
    align-items: center;
    border-radius: 0 !important;
}

.tda-modal-search-text .modal-content{
    border-radius: 0 !important;
}

.tda-modal-search-text {
    margin: 0 !important;
    display: flex;
}

.tda-modal-search-text .modal-title{
    display: flex;
    align-items: center;
}

#tda-modal-search-text .btn-close{
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 1rem;
}

.tda-modal-search-text-header .modal-title{
    width: 100%;
}

.tda-modal-search-text .tda-mobile-search{
    min-height: 100vh;
}

.tda-modal-search-text a{
    padding-top: 2rem;
    text-decoration: none;
    color: #000;
}

.tda-modal-search-text .tda-mobile-search img{
    width: 100%;
}

.tda-modal-search-text .tda-mobile-search h2{
    padding-top: 1rem;
}

.car-detail-mobile .special-request-button{
    margin: 1rem 0 4rem 0 !important;
}

#tda-navbar-scroll {
    transition: 0.5s;
    background-color: rgba(255,255,255, 1);
}

.tda-navbar-home img{
    width: 3.5rem;
}

@media only screen and (min-width: 768px) {
    .tda-navbar-trigger-mobile{
        height: 5.5rem;
        padding-top: 3rem !important;
    }
}
*{
    margin: 0;
    padding: 0;
    border: 0;
    font-family: 'Titillium Web', sans-serif !important;
    letter-spacing: 1px;
}

.container{
    padding: 0 2rem !important;
}

.form-control{
    font-size: 0.8rem !important;
}

#root{
    width: 100vw;
}

button p{
    margin: 0;
}

.display-none{
    display: none !important;
}

.icon-arrow{
    transform: rotate(180deg);
    margin-right: 0.5rem;
}

.text-bold{
    font-weight: 600;
}

.home,
.services,
.products,
.contact-us,
.sign-up,
.marketing,
.consulting {
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
}

.font-weight-bold{
    color: #000 !important;
}

a{
    color:#000;
    text-decoration: none !important;
    cursor: pointer !important;
}

tbody, td, tfoot, th, thead, tr {
    border-color: inherit;
    border-style: none !important;
    border-width: 0;
}

.tda-navbar-trigger-mobile{
    display: block !important;
}

.tda-navbar-desktop{
    display: none !important;
}

.container-fluid{
    padding: 0 !important;
}

.btn-close:focus{
    box-shadow: none !important;
}

.button-rectangle{
    background: linear-gradient(#000 0 0) no-repeat calc(100% - var(--p, 0%))
    100% / 100% var(--p, 0.01em);
    transition: 0.4s var(--t, 0s),
    background-position 0.3s calc(0.3s - var(--t, 0s));
    color: #000;
}

.button-rectangle-danger{
    background: linear-gradient(#f00 0 0) no-repeat calc(100% - var(--p, 0%))
    100% / 100% var(--p, 0.01em) !important;
}

.button-rectangle:hover p{
    color: #fff;
}

.button-rectangle:hover{
    --p: 100%;
    --t: 0.4s;
}

.error-message, .error-message-login{
    margin: 0 !important;
    font-size: 1rem;
    height: 1.8rem;
    font-weight: 600;
    color: red;
}

.error-message-login{
    text-align: justify;
    margin: -2rem 0 0.8rem 0 !important;
}

.text-center{
    text-align: center;
}

.text-opacity-zero{
    opacity: 0;
    margin: 0;
}

.text-italic{
    font-style: italic;
}

.blink_me_div{
    width: 100vw;
    height: 100vh;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 11;
    font-size: 2.5rem;

    background-color: #fff;
    display: none;
}

.blink_me {
    animation: blinker 1s linear infinite;
    text-shadow: 3px 2px 1px rgba(0,0,0,0.2);
}

#modal-zoom .modal-header{
    display: flex;
    justify-content: flex-end;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

@media only screen and (min-width: 768px) {
    
}

@media only screen and (min-width: 992px) {


    .container{
        padding: 0 2rem !important;
        max-width: 90vw !important;
    }

    .tda-navbar-trigger-mobile{
        display: none !important;
    }
    .tda-navbar-desktop{
        display: block !important;
    }
}

@media only screen and (min-width: 1200px) {
}